import {
  ADD_CLUB,
  DELETE_CLUB,
  EDIT_CLUB,
  GET_CLUBS,
  GET_CLUBS_INFOS,
  SET_CLUB_DETAILS,
  ADD_PUBLICATION_CLUB,
  GET_PUBLICATIONS,
  ADD_COMMENT,
  GET_COUNT,
  GET_COMMENTS,
  DELETE_CLUB_PUB,
  GET_COMMENTS_OF_POST,
  EDIT_CLUB_POST,
  LIKE_POST,
  DISLIKE_POST,
  LIKE_COMMENT,
  DISLIKE_COMMENT,
  LIKE_COMMENT_FROM_MODAL,
  DISLIKE_COMMENT_FROM_MODAL,
} from "../../@jumbo/constants/ActionTypes";

import _ from "lodash";

const INIT_STATE = {
  clubs: [],
  currentClub: null,
  clubsInfo: {},
  clubsPub: [],
  comments: [],
  metaComments: [],
  allCommentsInPost: [],
};

const Clubs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLUBS: {
      return {
        ...state,
        clubs: action.payload,
      };
    }

    case SET_CLUB_DETAILS: {
      return {
        ...state,
        currentClub: action.payload,
      };
    }

    case ADD_CLUB: {
      return {
        ...state,
        clubs: [action.payload, ...state.clubs],
      };
    }

    case ADD_PUBLICATION_CLUB: {
      return {
        ...state,
        clubsPub: [{ ...action.payload, liked: false }, ...state?.clubsPub],

        count: { ...state.count, itemCount: state.count.itemCount + 1 },
      };
    }


    case GET_PUBLICATIONS: {
      if (action.fromDelete) {
        return {
          ...state,
          clubsPub: [...action.payload],
        };
      }
      return {
        ...state,
        clubsPub: _.uniqBy([...state?.clubsPub, ...action.payload], "id"),
      };
    }

    case DELETE_CLUB_PUB: {
    
      return {
        ...state,
        clubsPub: state.clubsPub.filter((club) => club.id !== action.payload),
        comments: state.comments.filter(
          ({ postId }) => postId !== action.payload
        ),

        metaComments: state.metaComments.filter(
          ({ postId }) => postId !== action.payload
        ),
      };
    }

    case GET_COUNT: {
      return {
        ...state,
        count: action.payload,
      };
    }

    case EDIT_CLUB: {
      return {
        ...state,
        clubs: state.clubs.map((CLUB) =>
          CLUB.id === action.payload.id ? action.payload : CLUB
        ),
      };
    }

    case DELETE_CLUB: {
      return {
        ...state,
        clubs: state.clubs.filter((CLUB) => CLUB.id !== action.payload),
      };
    }

    case GET_CLUBS_INFOS: {
      return {
        ...state,
        clubsInfo: action.payload,
      };
    }

 case ADD_COMMENT: {
      const { comment, postId, ...rest } = action.payload;
      const foundComment = state.comments.find(
          (element) => element?.id === comment.id
          );
          if (foundComment) {
                return state;
             }
      const newObject = { postId, ...comment, ...rest };
      const foundMetaComment = state.metaComments?.find(
        (element) => element?.postId === postId
      );
      if (foundMetaComment && !action.fromModal) {
        const filtredMeta = state.metaComments?.filter(
          (element) => element?.postId !== postId
        );
        const newMetaComment = {
          ...foundMetaComment,
          itemCount: foundMetaComment?.itemCount + 1,
        };
        return {
          ...state,
          comments: _.uniqBy([...state.comments, newObject], "id"),
          metaComments: _.uniqBy([...filtredMeta, newMetaComment], "postId"),
        };
      }
      if (action.fromModal) {
        return {
          ...state,
          allCommentsInPost: [...state.allCommentsInPost, newObject],
        };
      }

      return state;
    }
    case GET_COMMENTS_OF_POST: {
      return {
        ...state,
        allCommentsInPost: action.payload.reverse(),
      };
    }
    case GET_COMMENTS: {
      const { data, meta, postId, fetchMore } = action.payload;

      if (fetchMore) {
        const filtredComments = state.comments?.filter(
          (element) => element.postId !== postId
        );

        const filtredMeta = state.metaComments?.filter(
          (element) => element.postId !== postId
        );

        return {
          ...state,
          comments: _.uniqBy([...filtredComments, ...data], "id"),
          metaComments: [...filtredMeta, { postId, ...meta }],
        };
      }

      return {
        ...state,
        comments: _.uniqBy([...state.comments, ...data], "id"),
        metaComments: _.uniqBy(
          [...state.metaComments, { postId, ...meta }],
          "postId"
        ),
      };
    }
    case EDIT_CLUB_POST: {
      return {
        ...state,
        clubsPub: state.clubsPub.map((PUBLICATION) =>
          PUBLICATION.id === action.payload.id ? action.payload : PUBLICATION
        ),
      };
    }
    case LIKE_POST: {
      const { postId } = action.payload;

      return {
        ...state,
        clubsPub: state.clubsPub.map(({ id, ...rest }) => {
          let returnedObject = {};

          if (id === postId) {
            returnedObject = {
              ...rest,
              nb_like: rest.nb_like + 1,
              liked: true,
              id,
            };
          } else {
            returnedObject = { id, ...rest };
          }
          return returnedObject;
        }),
      };
    }

    case DISLIKE_POST: {
      const { postId } = action.payload;

      return {
        ...state,
        clubsPub: state.clubsPub.map(({ id, ...rest }) => {
          let returnedObject = {};

          if (id === postId) {
            returnedObject = {
              ...rest,
              nb_like: rest.nb_like - 1,
              liked: false,
              id,
            };
          } else {
            returnedObject = { id, ...rest };
          }

          return returnedObject;
        }),
      };
    }

    case LIKE_COMMENT: {
      const { commentId } = action.payload;

      return {
        ...state,
        comments: state.comments.map(({ id, ...rest }) => {
          let returnedObject = {};

          if (id === commentId) {
            returnedObject = {
              ...rest,
              nb_like: rest.nb_like + 1,
              liked: true,
              id,
            };
          } else {
            returnedObject = { id, ...rest };
          }

          return returnedObject;
        }),
      };
    }

    case DISLIKE_COMMENT: {
      const { commentId } = action.payload;

      return {
        ...state,
        comments: state.comments.map(({ id, ...rest }) => {
          let returnedObject = {};

          if (id === commentId) {
            returnedObject = {
              ...rest,
              nb_like: rest.nb_like - 1,
              liked: false,
              id,
            };
          } else {
            returnedObject = { id, ...rest };
          }

          return returnedObject;
        }),
      };
    }

    case LIKE_COMMENT_FROM_MODAL: {
      const { commentId } = action.payload;
      return {
        ...state,
        allCommentsInPost: state.allCommentsInPost.map(({ id, ...rest }) => {
          let returnedObject = {};

          if (id === commentId) {
            returnedObject = {
              ...rest,
              nb_like: rest.nb_like + 1,
              liked: true,
              id,
            };
          } else {
            returnedObject = { id, ...rest };
          }

          return returnedObject;
        }),
      };
    }

    case DISLIKE_COMMENT_FROM_MODAL: {
      const { commentId } = action.payload;

      return {
        ...state,
        allCommentsInPost: state.allCommentsInPost.map(({ id, ...rest }) => {
          let returnedObject = {};

          if (id === commentId) {
            returnedObject = {
              ...rest,
              nb_like: rest.nb_like - 1,
              liked: false,
              id,
            };
          } else {
            returnedObject = { id, ...rest };
          }

          return returnedObject;
        }),
      };
    }
    default:
      return state;
  }
};
export default Clubs;
