import {
  GET_PRESENCE,
  SET_PRESENCE_DETAILS,
  EDIT_PRESENCE,
  DELETE_PRESENCE,
} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  Attendance: [],

  currentAttendance: null,
  currentStudent: null,
};

const Attendance = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRESENCE: {
      return {
        ...state,
        Attendance: action.payload,
      };
    }
    case SET_PRESENCE_DETAILS: {
      return {
        ...state,
        currentAttendance: action.payload?.assiduity,
        currentStudent: action.payload?.student,
      };
    }
    case DELETE_PRESENCE: {
      return {
        ...state,
        Attendance: state.Attendance.filter(
          (attendance) => attendance.id !== action.payload
        ),
      };
    }
    case EDIT_PRESENCE: {
      return {
        ...state,
        Attendance: action.payload,
      };
    }
    default:
      return state;
  }
};
export default Attendance;
