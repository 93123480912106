import {alpha, makeStyles} from "@material-ui/core/styles";
import {Fonts} from "../../../@jumbo/constants/ThemeOptions";

const TableToolbarStyle = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: alpha(theme.palette.secondary.light, 0.15)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%",
    fontSize: 16,
    fontWeight: 700,
    fontFamily: Fonts.SECTIONTITLE,
    letterSpacing: 1.5,
    textTransform: "capitalize",
    color: "#666666"
  },
  spaceTitleToolbar: {
    marginLeft: "20px",
    fontSize: 15,
    fontWeight: 300,
    textTransform: "uppercase",
    color: "#5B13B4",
    fontFamily: Fonts.MODLETITLE
  },
  chipsRoot: {
    display: "flex",
    justifyContent: "center",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    "& > *": {
      margin: theme.spacing(0.5)
    }
  }
}));

export default TableToolbarStyle;
