import {
  GET_SCHOOLSUPPLIES,
  ADD_SCHOOLSUPPLIES,
  SET_SCHOOLSUPPLIES,
  EDIT_SCHOOLSUPPLIES,
  DELETE_SCHOOLSUPPLIES,
  Details_SCHOOL_SUPPLIES,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  SchoolSupplies: [],
  currentSchoolSupplies: null,
  detailSchoolSupplies: {},
};

const SchoolSupplies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCHOOLSUPPLIES: {
      return {
        ...state,
        SchoolSupplies: action.payload,
      };
    }
    case SET_SCHOOLSUPPLIES: {
      return {
        ...state,
        currentSchoolSupplies: action.payload,
      };
    }
    case Details_SCHOOL_SUPPLIES: {
      return {
        ...state,
        detailSchoolSupplies: action.payload,
      };
    }
    case ADD_SCHOOLSUPPLIES: {
      return {
        ...state,
        SchoolSupplies: [action.payload, ...state.SchoolSupplies],
      };
    }
    case EDIT_SCHOOLSUPPLIES: {
      return {
        ...state,
        SchoolSupplies: state.SchoolSupplies.map((supplies) =>
          supplies.id === action.payload.id ? action.payload : supplies
        ),
      };
    }
    case DELETE_SCHOOLSUPPLIES: {
      return {
        ...state,
        SchoolSupplies: state.SchoolSupplies.filter(
          (SchoolSupplie) => SchoolSupplie.id !== action.payload
        ),
      };
    }

    default:
      return state;
  }
};
export default SchoolSupplies 