import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";

import {
  ADD_LEVEL,
  EDIT_LEVEL,
  GET_LEVELS,
  SET_LEVEL_DETAILS,
} from "@jumbo/constants/ActionTypes";
import { isOrderByAscLevel } from "@jumbo/utils/commonHelper";
import IntlMessages from "@jumbo/utils/IntlMessages";

export const getLevels = (
  subEstablishmentId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`levels?subEstablishmentId=${subEstablishmentId}`, {
        params: { filterOptions, searchTerm },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_LEVELS, payload: isOrderByAscLevel(data.data) });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
      });
  };
};

export const setCurrentLevel = (license) => {
  return (dispatch) => {
    dispatch({ type: SET_LEVEL_DETAILS, payload: license });
  };
};

export const addNewLevel = (license, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("levels", license)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess(<IntlMessages id = "app.module.level.add.fetchSuccess"/>));
          dispatch({ type: ADD_LEVEL, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
      });
  };
};

export const updateLevel = (level, levelId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp.put(`levels/${levelId}`, level).then((data) => {
      if (data.status === 200) {
        dispatch(fetchSuccess(<IntlMessages id ="appModule.edit.msg.success"/>));
        dispatch({ type: EDIT_LEVEL, payload: level });
        if (callbackFun) callbackFun(data.data);
      } else {
        dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
      }
    });
  };
};

export const updateLevelStatus = (data, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put("levels/update-status", data)
      .then((response) => {

        if (response.status === 200) {
          dispatch(fetchSuccess(<IntlMessages id = "app.module.harassement.update.fetchSuccess"/>));
          dispatch({ type: EDIT_LEVEL, payload: response.data });
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
      });
  };
};
