import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";
import {
  ADD_COMPLAINT,
  DELETE_COMPLAINT,
  EDIT_COMPLAINT,
  EDIT_REPLY_COMPLAINT,
  GET_COMPLAINTS,
  SET_COMPLAINT_DETAILS,
} from "../../@jumbo/constants/ActionTypes";
import IntlMessages from "@jumbo/utils/IntlMessages";

export const getComplaints = (
  subEstablishmentId,
  schoolYearId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
     crudApp

      .get(
        `complaints?schoolYearId=${schoolYearId}&subEstablishmentId=${subEstablishmentId}`,
        { params: { filterOptions, searchTerm } }
      )
      .then((data) => {
        if (data.status === 200) {
          let sorted=data.data.sort((a,b)=>{ return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime() } )
          dispatch({ type: GET_COMPLAINTS, payload: sorted });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.error"/>));
      });
  };
};

export const setCurrentComplaint = (complaint) => {
  return (dispatch) => {
    dispatch({ type: SET_COMPLAINT_DETAILS, payload: complaint });
  };
};

export const addNewComplaint = (complaint, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("complaints", complaint)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(<IntlMessages id = "app.module.complaint.add.fetchSuccess"/>)
          );
          dispatch({ type: ADD_COMPLAINT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.add.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.add.fetchError"/>));
      });
  };
};

export const replyComplaint = (
  complaint,
  complaintId,
  currentComplaint,
  callbackFun
) => {
   return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`complaints/replayComplaint/${complaintId}`, complaint)
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(
              <IntlMessages id = "app.module.complaint.update.fetchSuccess"/>
            )
          );
          dispatch({ type: EDIT_REPLY_COMPLAINT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id = "app.module.update.fetchError"/>)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.update.fetchError"/>));
      });
  };
};

export const updateComplaint = (complaint, complaintId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`complaints/${complaintId}`, complaint)
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(
              <IntlMessages id = "app.module.complaint.update.fetchSuccess"/>
            )
          );
          dispatch({ type: EDIT_COMPLAINT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id = "app.module.update.fetchError"/>)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.update.fetchError"/>));
      });
  };
};

export const deleteComplaint = (complaintId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .remove(`appointment/${complaintId}`)
      .then((data) => {
         if (data.status === 200) {
          dispatch(
            fetchSuccess(<IntlMessages id = "app.module.complaint.delete.fetchSuccess"/>)
          );
          dispatch({ type: DELETE_COMPLAINT, payload: complaintId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
      });
  };
};
