import React from "react";
import axios from 'axios';
import {fetchError, fetchStart, fetchSuccess} from './Common';

import {crudApp} from '../../services/crudApp';
import {
  ADD_PROFESSOR,
  DELETE_PROFESSOR,
  EDIT_PROFESSOR,
  GET_PROFESSORS,
  SET_PROFESSOR_DETAILS,
} from '@jumbo/constants/ActionTypes';
import IntlMessages from "@jumbo/utils/IntlMessages";
export const getProfessors = (
  subEstablishmentId,
  filterOptions = [],
  searchTerm = '',
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`professors?subEstablishmentId=${+subEstablishmentId}`, {
        params: {filterOptions, searchTerm},
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_PROFESSORS, payload: data.data});
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
      });
  };
};

export const setCurrentOperator = (professor) => {
  return (dispatch) => {
    dispatch({type: SET_PROFESSOR_DETAILS, payload: professor});
  };
};

export const addNewProfessor = (professor, photo, userProfile, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post('professors', professor)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          if (photo !== undefined && photo !== null) {
            let professorId = data.data?.id;
            let object = {};
            object.file = photo;
            object.fileName = photo.name;
            const newFile = new File([object.file], object.fileName, {
              type: object.file.type,
            });
            let establishementname = userProfile?.establishmentName;
            let subEstablishementName = userProfile?.currentSubEstablishmentName;
            let subEstablishmentId = userProfile?.currentSubEstablishmentId;
            let formadata = new FormData();
            formadata.append('file', newFile);
            formadata.append('userId', professorId);
            formadata.append('key', `${establishementname}${subEstablishementName}/Divers`);
            formadata.append('fileName', object.fileName);
            formadata.append('subEstablishmentId', subEstablishmentId);
            crudApp
              .put(`users/photo`, formadata)
              .then((dataPhoto) => {
                if (dataPhoto.status === 200 || dataPhoto.status === 201) {
                  dispatch(fetchSuccess(<IntlMessages id = "appModule.edit.msg.success"/>));

                  dispatch({type: ADD_PROFESSOR, payload: {...data.data, ...dataPhoto.data}});
                  if (callbackFun) callbackFun(data.data);
                }
              })
              .catch((error) => {
                dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
              });
          } else {
            dispatch(fetchSuccess(<IntlMessages id="appModule.professor.add.msg.succes"/>));
            dispatch({type: ADD_PROFESSOR, payload: data.data});
            if (callbackFun) callbackFun(data.data);
          }
        } else if (data?.response?.status === 409) {
          dispatch(fetchError(<IntlMessages id="appModule.exist.email.msg.error"/>));
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
      });
  };
};

export const updateProfessor = (user, currentUser, photo, userProfile, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`professors/${currentUser.id}`, user)
      .then((data) => {
        if (data.status === 200) {
          if (photo !== undefined && photo !== null) {
            let professorId = currentUser.id;
            let object = {};
            object.file = photo;
            object.fileName = photo.name;
            const newFile = new File([object.file], object.fileName, {
              type: object.file.type,
            });
            let establishementname = userProfile?.establishmentName;
            let subEstablishementName = userProfile?.currentSubEstablishmentName;
            let subEstablishmentId = userProfile?.currentSubEstablishmentId;
            let formadata = new FormData();
            formadata.append('file', newFile);
            formadata.append('userId', professorId);
            formadata.append('key', `${establishementname}${subEstablishementName}/Divers`);
            formadata.append('fileName', object.fileName);
            formadata.append('subEstablishmentId', subEstablishmentId);
            crudApp
              .put(`users/photo`, formadata)
              .then((dataPhoto) => {
                if (dataPhoto.status === 200 || dataPhoto.status === 201) {
                  dispatch(fetchSuccess(<IntlMessages id= "appModule.edit.msg.success"/>));

                  dispatch({
                    type: EDIT_PROFESSOR,
                    payload: {...currentUser, ...user, ...dataPhoto.data},
                  });
                  if (callbackFun) callbackFun({...currentUser, ...user, ...dataPhoto.data});
                }
              })
              .catch((error) => {
                dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
              });
          } else {
            dispatch(fetchSuccess(<IntlMessages id= "appModule.edit.msg.success"/>));
            dispatch({
              type: EDIT_PROFESSOR,
              payload: {...currentUser, ...user},
            });
          }

          if (callbackFun) callbackFun({...currentUser, ...user});
        } else if (data?.response?.status === 409) {
         
          dispatch(fetchError(<IntlMessages id="appModule.exist.email.msg.error"/>));
        }
         else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
      });
  };
};

export const deleteOperator = (professorId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .delete('/professors', {params: {id: professorId}})
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess(<IntlMessages id = "app.module.professor.delete.fetchSucces"/>));
          dispatch({type: DELETE_PROFESSOR, payload: professorId});
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
      });
  };
};

export const importProfessor = (students, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post('professors/import', students)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess(<IntlMessages id = "app.module.professor.import.fetchsuccess"/>));
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
      });
  };
};

export const exportProfessor = (subjectId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`professors/export${subjectId}`)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess(<IntlMessages id = "app.module.professor.export.fetchSuccess"/>));
          if (callbackFun) callbackFun(data.data?.filePath);
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
      });
  };
};
