import { GET_SKILLS } from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  skills: [
    {
      id: 1,
      student: "Nicolas Pasteur",
      lecture: "Satisfaisant",
      expression_ecrite: "Débutant",
      ecrire_texte: "Satisfaisant",
      orthographe: "Très Satisfaisant",
      grammaire: " Satisfaisant",
      competence: " Satisfaisant",
      color: "red",
    },
    {
      id: 2,
      student: "Clement simon",
      lecture: "Excellent",
      expression_ecrite: "Débutant",
      ecrire_texte: "Satisfaisant",
      orthographe: "Très Satisfaisant",
      grammaire: " Débutant",
      competence: " Excellent",
      color: "blue",
    },
    {
      id: 3,
      student: "Nicolas Pasteur",
      lecture: "Satisfaisant",
      expression_ecrite: "Débutant",
      ecrire_texte: "Satisfaisant",
      orthographe: "Très Satisfaisant",
      grammaire: " Satisfaisant",
      competence: " Satisfaisant",
      color: "blue",
    },
    {
      id: 4,
      student: "Nicolas Pasteur",
      lecture: "Satisfaisant",
      expression_ecrite: "Débutant",
      ecrire_texte: "Satisfaisant",
      orthographe: "Très Satisfaisant",
      grammaire: " Satisfaisant",
      competence: " Satisfaisant",
      color: "green",
    },
    {
      id: 5,
      student: "Nicolas Pasteur",
      lecture: "Satisfaisant",
      expression_ecrite: "Débutant",
      ecrire_texte: "Satisfaisant",
      orthographe: "Très Satisfaisant",
      grammaire: " Satisfaisant",
      competence: " Satisfaisant",
      color: "yellow",
    },
  ],
  currentItineraire: null,
};

const Skills = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SKILLS: {
      return {
        ...state,
        itineraires: action.payload,
      };
    }

    default:
      return state;
  }
};
export default Skills;
