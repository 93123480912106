import React from 'react';
import {useDropzone} from 'react-dropzone';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  dialogText: {
    color: '#6c5ce7',
    fontSize: 11,
  },
}));
const FilePicker = ({onAddAttachments, buttonTitle, detail, disabled}) => {
  const classes = useStyles();

  const {getRootProps, getInputProps} = useDropzone({
    accept: ' .pdf, ',

    onDrop: (acceptedFiles) => {
      const files = acceptedFiles.map((file) => {
        return {
          id: Math.floor(Math.random() * 10000),
          file,
        };
      });
      onAddAttachments(files);
    },
  });

  return (
    <Box
      display="inline"
      alignItems="center"
      mt={{xs: 6, md: 12}}
      mb={{xs: 6, md: 12}}
      className="pointer"
      {...getRootProps({})}
    >
      <input aria-label=""  {...getInputProps()} disabled={disabled} />

      <Button component="span" startIcon={<AddIcon />} className={classes.dialogText} size="small">
        {buttonTitle}
      </Button>
    </Box>
  );
};

export default FilePicker;

FilePicker.prototype = {
  onAddAttachments: PropTypes.func,
  disabled: PropTypes.bool,
};
