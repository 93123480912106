import {
  DELETE_COLLABORATOR_ABSCENCE,
  SET_COLLABORATOR_ABSCENCE_DETAILS,
  ADD_COLLABORATOR_ABSCENCE,
  EDIT_COLLABORATOR_ABSCENCE,
  GET_COLLABORATOR_ABSCENCE,
  SET_ACCOUNTING_SETTING,
  GET_COLLABORATOR_EXPORT_FILE,
} from "@jumbo/constants/ActionTypes";
const INIT_STATE = {
  collaboratorMonitoring: [],
  currentCollaboratorMonitoring: null,
  fileExported: null,
};
const CollaboratorMonitoring = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COLLABORATOR_ABSCENCE: {
      return {
        ...state,
        collaboratorMonitoring: action.payload,
      };
    }
    case SET_COLLABORATOR_ABSCENCE_DETAILS: {
      return {
        ...state,
        currentCollaboratorMonitoring: action.payload,
      };
    }
    case ADD_COLLABORATOR_ABSCENCE: {
      return {
        ...state,
        collaboratorMonitoring: [
          action.payload,
          ...state.collaboratorMonitoring,
        ],
      };
    }
    case EDIT_COLLABORATOR_ABSCENCE: {
      return {
        ...state,
        collaboratorMonitoring: [
         
          ...state.collaboratorMonitoring.filter(
            (collaborator) => collaborator.id !== action.payload.id
          ), action.payload,
        ],
      };
    }
    case DELETE_COLLABORATOR_ABSCENCE: {
      return {
        ...state,
        collaboratorMonitoring: state.collaboratorMonitoring.filter(
          (collaborator) => collaborator.id !== action.payload
        ),
      };
    }
    case SET_ACCOUNTING_SETTING: {
      return {
        ...state,
        accountingSetting: action.payload,
      };
    }
    case GET_COLLABORATOR_EXPORT_FILE: {
      return {
        ...state,
        fileExported: action.payload,
      };
    }
    default:
      return state;
  }
};
export default CollaboratorMonitoring 