import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import PropTypes from "prop-types";
import React from "react";
import {withStyles} from "@material-ui/core/styles";

import {Fonts} from "../../../@jumbo/constants/ThemeOptions";

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 15,
    fontWeight: 700,
    fontFamily: Fonts.SECTIONTITLE,
    letterSpacing: 1,
    color: "#4D4B4B",
    opacity: 10,
    backgroundColor:theme.palette.purple
  },
  body: {}
}))(TableCell);
const headCells = [
  {
    id: "01",
    numeric: false,
    label: ""
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: <IntlMessages id="appModule.establishment.name" />
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.establishment.type" />
  },
  {
    id: "parentname",
    numeric: false,
    disablePadding: false,
    label: <IntlMessages id="appModule.establishment.parent.name" />
  }
];

function EstablishmentTableHead({classes, order, orderBy, onRequestSort}){
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={onSortOrderChange(headCell.id)}
            >
              <span className={classes.headStyle}>{headCell.label}</span>

              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}

        <StyledTableCell className={classes.headStyle} align="center">
          <IntlMessages id="app.Module.actions" />{" "}
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

EstablishmentTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf([ "asc", "desc" ]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default React.memo(EstablishmentTableHead);
