import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "axios";
import { crudApp } from "../../services/crudApp";
import {
  ADD_OPERATOR,
  DELETE_OPERATOR,
  EDIT_OPERATOR,
  GET_OPERATORS,
  SET_OPERATOR_DETAILS,
} from "../../@jumbo/constants/ActionTypes";
import IntlMessages from "@jumbo/utils/IntlMessages";

export const getOperator = (
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get("operators", { params: { filterOptions, searchTerm } })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_OPERATORS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>)
        );
      });
  };
};

export const setCurrentOperator = (operator) => {
  return (dispatch) => {
    dispatch({ type: SET_OPERATOR_DETAILS, payload: operator });
  };
};

export const addNewOperator = (operator, photo, userProfile, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("operators", operator)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          if (photo !== undefined && photo !== null) {
            let operatorId = data.data?.id;
            dispatch(fetchStart());
            var object = {};
            object.file = photo;
            object.fileName = photo.name;
            const newFile = new File([object.file], object.fileName, {
              type: object.file.type,
            });
            let establishementname = userProfile?.establishmentName;
            let subEstablishementName =
              userProfile?.currentSubEstablishmentName;
            let subEstablishmentId = userProfile?.currentSubEstablishmentId;
            let formadata = new FormData();
            formadata.append("file", newFile);
            formadata.append("userId", operatorId);
            formadata.append(
              "key",
              `${establishementname}${subEstablishementName}/Divers`
            );
            formadata.append("fileName", object.fileName);
            formadata.append("subEstablishmentId", subEstablishmentId);
            crudApp
              .put(`users/photo`, formadata)

              .then((dataPhoto) => {
                dispatch({
                  type: ADD_OPERATOR,
                  payload: { ...data.data, photo: dataPhoto.data.photo },
                });
              })
              .catch((error) => {
                dispatch(
                  fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>)
                );
              });
          } else {
            dispatch({ type: ADD_OPERATOR, payload: data.data });
          }
          dispatch(
            fetchSuccess(<IntlMessages id = "app.module.adminLocal.add.user.fetchSuccess"/>)
          );
          if (callbackFun) callbackFun(data.data);
        } else if (data?.response?.status === 409) {
          dispatch(fetchError(`Email ${operator.email} existe déjà `));
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
      });
  };
};

export const updateOperator = (
  operator,
  currentOperator,
  photo,
  userProfile,
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`operators/${currentOperator.id}`, operator)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          if (photo !== undefined && photo !== null) {
            let operatorId = currentOperator.id;
            dispatch(fetchStart());
            var object = {};
            object.file = photo;
            object.fileName = photo.name;
            const newFile = new File([object.file], object.fileName, {
              type: object.file.type,
            });
            let establishementname = userProfile?.establishmentName;
            let subEstablishementName =
              userProfile?.currentSubEstablishmentName;
            let subEstablishmentId = userProfile?.currentSubEstablishmentId;
            let formadata = new FormData();
            formadata.append("file", newFile);
            formadata.append("userId", operatorId);
            formadata.append(
              "key",
              `${establishementname}${subEstablishementName}/Divers`
            );
            formadata.append("fileName", object.fileName);
            formadata.append("subEstablishmentId", subEstablishmentId);
            crudApp
              .put(`users/photo`, formadata)
              .then((dataPicture) => {
                if (dataPicture.status === 200 || dataPicture.status === 201) {
                  dispatch(
                    fetchSuccess(<IntlMessages id = "appModule.edit.msg.success"/>)
                  );
                  dispatch({
                    type: EDIT_OPERATOR,
                    payload: { ...data.data, photo: dataPicture?.data?.photo },
                  });
                  if (callbackFun) callbackFun(data.data);
                }
              })

              .catch((error) => {
                dispatch(
                  fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>)
                );
              });
          } else {
            dispatch(
              fetchSuccess(<IntlMessages id = "appModule.edit.msg.success"/>)
            );

            dispatch({ type: EDIT_OPERATOR, payload: data.data });
            if (callbackFun) callbackFun(data.data);
          }
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>)
        );
      });
  };
};

export const deleteOperator = (operatorId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .delete("/operators", { params: { id: operatorId } })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess(<IntlMessages id = "app.module.operator.delete.fetchSuccess"/>));
          dispatch({ type: DELETE_OPERATOR, payload: operatorId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
      });
  };
};
