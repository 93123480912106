import React, { useState } from "react";
import {
  Box,
  IconButton,
  makeStyles,
  Popover,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CmtCardHeader from "../../../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../../../../@coremat/CmtCard/CmtCardContent";
import CmtList from "../../../../../../@coremat/CmtList";
import CmtCard from "../../../../../../@coremat/CmtCard";
import NotificationItem from "./NotificationItem";
 import clsx from "clsx";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import IntlMessages from "@jumbo/utils/IntlMessages";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 30,
    },
    "& .Cmt-card-content": {
      padding: "0 0 16px !important",
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    position: "relative",
    color: alpha(theme.palette.common.white, 0.38),
    "&:hover, &.active": {
      color: theme.palette.common.white,
    },
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20,
  },

  popoverRoot: {
    "& .MuiPopover-paper": {
      width: 375,
      // paddingTop: "20px",
    },
    marginTop: "20px",
  },
  cmtCardHeader: {
    fontWeight: "bold",
  },
  
}));

const HeaderNotifications = () => {
  const { notifications } = useSelector((state) => state.Notification);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const onOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box >
      <Tooltip title={<IntlMessages id="appNotification.title" />}>
        <IconButton
          onClick={onOpenPopOver}
          className={clsx(classes.iconRoot, "Cmt-appIcon", {
            active:
              notifications.filter((row) => row.status === "Unread").length > 0,
          })}
        >
          <Badge
            badgeContent={
              notifications.filter((row) => row.status === "Unread").length
            }
            classes={{ badge: classes.counterRoot }}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
      >
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            className={classes.cmtCardHeader}
            title={<IntlMessages id="appNotification.title" />}
            actionsPos="top-corner"
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: "solid",
            }}
             
          />
          <CmtCardContent  >
            {notifications.length > 0 ? (
             
                <CmtList
                  data={notifications}
                  renderRow={(item, index) => (
                    <NotificationItem key={index} item={item} />
                  )}
                />
              
            ) : (
              <Box p={6}>
                <Typography variant="body2">
                  <IntlMessages id="no.notifications" />
                </Typography>
              </Box>
            )}
          </CmtCardContent>
        </CmtCard>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
