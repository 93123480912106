import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CmtImage from "../../../../@coremat/CmtImage";
import CustomTextField from "../../../../routes/CommonComponents/CustomTextField/CustomTextField";

const useStyles = makeStyles((theme) => ({
  option: {
    borderTop: `1px solid ${theme.palette.borderColor.main}`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontWeight: "300",
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  AutocompleteRoot: {
    backgroundColor: theme.palette.background.paper,
    "& .MuiFormControl-root > label": {
      paddingLeft: 28,
    },
    "& .MuiFormControl-root > label.MuiInputLabel-shrink": {
      paddingLeft: 0,
    },
  },
  inputRoot: {
    paddingLeft: "35px !important",
  },
  avatar: {
    position: "absolute",
    margin: 5,
    width: 30,
    height: 30,
    borderRadius: "50%",
  },
  textFieldRoot: {
    backgroundColor: (props) => props.backgroundColor,
    "& .MuiIconButton-label": {
      color: "#5b13b4",
    },
  },
  autocompleteDisabledIcons: {
    "& .MuiIconButton-root.Mui-disabled": {
      color: "transparent",
    },
  },
}));

const AppAutocomplete = React.forwardRef((props, ref) => {
  const classes = useStyles(props);
  const {
    options,
    renderOption,
    getOptionLabel,
    selectedAllOption,
    name,
    id,
    backgroundColor,
    fullWidth,
    size,
    placeholder,
    variant,
    value,
    defaultValue,
    avatar,
    onChange,
    helperText,
    error,
    multiple,
    textValue,
    onChangeText,
    label,
    required,
    disabledIcon,
    disableClearable,
    ...rest
  } = props;
  return (
    <Box ref={ref}>
      <Autocomplete
        id={disableClearable ? "disable-clearable" : "fixed-tags-demo"}
        disableClearable={disableClearable}
        options={
          selectedAllOption
            ? [{ selectAll: "Tous", value: "select-all" }, ...options]
            : options
        }
        size="small"
        multiple={multiple}
        style={{ width: "100%" }}
        classes={{
          option: classes.option,
          root: avatar ? classes.AutocompleteRoot : null,
          inputRoot: avatar ? classes.inputRoot : null,
        }}
        value={value}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={(params) => (
          <React.Fragment>
            {avatar && typeof avatar === "string" ? (
              <CmtImage className={classes.avatar} src={avatar} alt="Avatar" />
            ) : (
              avatar
            )}
            <CustomTextField
              variant="outlined"
              {...params}
              // inputProps={{
              //   ...params.inputProps,
              //   autoComplete: 'disabled',
              // }}
              // name={name}
              defaultValue={defaultValue}
              value={textValue}
              onChange={(e) =>
                onChangeText ? onChangeText(e.target.value) : {}
              }
              className={
                disabledIcon === true
                  ? classes.autocompleteDisabledIcons
                  : classes.textFieldRoot
              }
              label={label}
              placeholder={placeholder}
              id={"input-" + (id || name)}
              size={size}
              fullWidth={fullWidth}
              error={error || helperText !== ""}
              helperText={helperText}
              required={required}
            />
          </React.Fragment>
        )}
        {...rest}
      />
    </Box>
  );
});

AppAutocomplete.prototype = {
  options: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  renderOption: PropTypes.func,
  getOptionLabel: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabledIcon: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  backgroundColor: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  selectedAllOption: PropTypes.bool,
  defaultValue: PropTypes.string,
};

AppAutocomplete.defaultProps = {
  options: [],
  fullWidth: true,
  size: "small",
  error: false,
  placeholder: "",
  helperText: "",
  avatar: "",
  backgroundColor: "transparent",
  multiple: false,
  label: "",
  required: false,
  selectedAllOption: false,
};

export default AppAutocomplete;
