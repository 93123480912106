import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  Grid,
  Button,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import GridContainer from "@jumbo/components/GridContainer";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import { useDropzone } from "react-dropzone";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import {
  emailNotValid,
  requiredMessage,
  phoneInvalid,
} from "@jumbo/constants/ErrorMessages";
import { isValidEmail, isValidphoneNumber } from "@jumbo/utils/commonHelper";
import { addNewUser, updateUser } from "../../../redux/actions/Users";
import IntlMessages from "@jumbo/utils/IntlMessages";
import useStyles from "../../CommonComponents/Style/AddStyle";
import MuiPhoneNumber from "material-ui-phone-number";
import close from "../../../assets/images/close.svg";
import AppAutocomplete from "@jumbo/components/Common/formElements/AppAutocomplete";
import { bloodGroup, civilityList } from "Config/listes";
import _ from "lodash";
const userInitialState = {
  firstName: "",
  lastName: "",
  email: "",
  address: null,
  phone: "",
  civility: "",
  communicationChannel: null,
  photo: null,
  establishmentId: "",
  bloodGroup: null,
};
const AddEditUser = ({ open, onCloseDialog, establishmentsList }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(
    ({ usersReducer }) => usersReducer.currentUser
  );
  const [userData, setUserData] = useState(userInitialState);
  const [profile_pic, setProfile_pic] = useState("");
  const [photo, setPhoto] = useState();
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [civilityError, setCivilityError] = useState("");
  const [establishmentIdError, setEstablishmentIdError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [bloodGroupItem, setBloodGroup] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setPhoto(URL.createObjectURL(acceptedFiles[0]));
      setProfile_pic(acceptedFiles[0]);
    },
  });
  useEffect(() => {
    if (currentUser) {
      setPhoto(currentUser.photo);
      let userDataForEdit = {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        address: currentUser.address,
        phone: currentUser.phone,
        civility: currentUser.civility,
        communicationChannel: "Téléphone",
        establishmentId: currentUser.establishment.id,
        bloodGroup: currentUser.bloodGroup,
        userName: currentUser.userName,
      };
      setUserData(userDataForEdit);
      let bloodGroupSelected = bloodGroup.filter(
        (item) => item.slug === currentUser.bloodGroup
      );
      setBloodGroup(_.head(bloodGroupSelected));
    }
  }, [currentUser]);

  const onSubmitClick = () => {
    if (userData.establishmentId === "") {
      setEstablishmentIdError(requiredMessage);
    } else if (!userData.civility) {
      setCivilityError(requiredMessage);
    } else if (!userData.lastName.trim()) {
      setLastNameError(requiredMessage);
    } else if (!userData.firstName.trim()) {
      setFirstNameError(requiredMessage);
    } else if (!userData.email) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(userData.email)) {
      setEmailError(emailNotValid);
    } else if (!userData.phone) {
      setPhoneError(requiredMessage);
    } else if (
      isValidphoneNumber(userData.phone) === false &&
      userData.phone.length > 4
    ) {
      setPhoneError(phoneInvalid);
    } else {
      onUserSave();
    }
  };

  const onUserSave = () => {
    if (currentUser) {
      dispatch(
        updateUser(currentUser, userData, profile_pic, () => {
          onCloseDialog();
        })
      );
    } else {
      dispatch(
        addNewUser(userData, profile_pic, () => {
          onCloseDialog();
        })
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          onCloseDialog();
        }
      }}
      className={classes.dialogRoot}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        <Grid container justifyContent="space-between" alignItems="center">
          {currentUser ? (
            <IntlMessages id="edit.user.sal" />
          ) : (
            <IntlMessages id="create.new.sal" />
          )}
          <img
            onClick={onCloseDialog}
            src={close}
            alt="img"
            style={{ textAlign: "end", cursor: "pointer" }}
          />
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        {/* 1er ligne */}
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <Box
            {...getRootProps()}
            mr={{ xs: 0, md: 5 }}
            mb={{ xs: 3, md: 0 }}
            className="pointer"
          >
            <input aria-label="" {...getInputProps()} />
            <CmtAvatar
              size={70}
              src={photo}
              style={{ color: "white", backgroundColor: "#b8b1f3" }}
            />
          </Box>

          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppSelectBox
                fullWidth
                required
                id="establishmentId"
                name="establishmentId"
                data={establishmentsList}
                disabled={currentUser && true}
                label={<IntlMessages id="parent.establisment" />}
                value={userData.establishmentId}
                valueKey="id"
                variant="outlined"
                labelKey="name"
                onChange={(e) => {
                  setUserData({ ...userData, [e.target.name]: e.target.value });
                  setEstablishmentIdError("");
                }}
                helperText={establishmentIdError}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AppSelectBox
                fullWidth
                required
                id="civility"
                name="civility"
                data={civilityList}
                label={<IntlMessages id="user.civility" />}
                valueKey="slug"
                variant="outlined"
                labelKey="title"
                value={userData.civility}
                onChange={(e) => {
                  setUserData({ ...userData, [e.target.name]: e.target.value });
                  setCivilityError("");
                }}
                helperText={civilityError}
              />
            </Grid>
          </GridContainer>
        </Box>

        {/* 2eme ligne */}
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              required
              id="lastName"
              name="lastName"
              variant="outlined"
              label={<IntlMessages id="appModule.LastName" />}
              value={userData.lastName}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  [e.target.name]: e.target.value?.toUpperCase(),
                });
                setLastNameError("");
              }}
              helperText={lastNameError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              required
              id="firstName"
              name="firstName"
              variant="outlined"
              label={<IntlMessages id="appModule.FirstName" />}
              value={userData.firstName}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  [e.target.name]:
                    e.target.value.charAt(0)?.toUpperCase() +
                    e.target.value.substring(1),
                });
                setFirstNameError("");
              }}
              helperText={firstNameError}
            />
          </Grid>
        </GridContainer>

        {/* 3eme ligne */}
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              required
              id="email"
              name="email"
              variant="outlined"
              label={<IntlMessages id="appModule.information.email" />}
              value={userData.email}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  [e.target.name]: e.target.value.trim(),
                });
                setEmailError("");
              }}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPhoneNumber
              autoFormat={false}
              size="small"
              required
              variant="outlined"
              id="phone"
              name="phone"
              value={userData.phone || ""}
              onChange={(number) => {
                setUserData({
                  ...userData,
                  phone: number.length > 4 ? number : null,
                });
                setPhoneError("");
              }}
              fullWidth={true}
              placeholder="(+XXX) XXX XXX XXX"
              label={<IntlMessages id="appModule.information.phoneNumber" />}
              defaultCountry="fr"
              helperText={<div style={{ color: "red" }}>{phoneError}</div>}
            />
          </Grid>
        </GridContainer>

        {/* 4ème ligne */}
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={currentUser ? 6 : 12}>
            <AppAutocomplete
              id="bloodGroup"
              name="bloodGroup"
              fullWidth
              label={<IntlMessages id="user.bloodGroup" />}
              variant="outlined"
              options={bloodGroup}
              value={bloodGroupItem}
              getOptionLabel={(option) => !_.isEmpty(option) && option?.title}
              onChange={(e, item) => {
                item !== null ? setBloodGroup(item) : setBloodGroup("");
                item !== null
                  ? setUserData({ ...userData, bloodGroup: item?.slug })
                  : setUserData({ ...userData, bloodGroup: null });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {currentUser && (
              <AppTextInput
                fullWidth
                required
                id="username"
                name="username"
                variant="outlined"
                label={<IntlMessages id="appModule.identifiant" />}
                value={userData.userName}
                disabled
                helperText={lastNameError}
              />
            )}
          </Grid>
        </GridContainer>

        {/* Actions */}
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={onCloseDialog}
          >
            {<IntlMessages id="appModule.cancel" />}
          </Button>
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={onSubmitClick}
            >
              {currentUser ? (
                <IntlMessages id="button.edit" />
              ) : (
                <IntlMessages id="button.add" />
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
