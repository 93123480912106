import {
  GET_ITINERAIRE,
  ADD_ITINERAIRE,
  EDIT_ITINERAIRE,
  SET_ITINERAIRE_DETAILS,
  DELETE_ITINERAIRE,
} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  itineraires: [],
  currentItineraire: null,
};

const Itineraires = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ITINERAIRE: {
      return {
        ...state,
        itineraires: action.payload,
      };
    }

    case ADD_ITINERAIRE: {
      return {
        ...state,
        itineraires: [action.payload, ...state.itineraires],
      };
    }
    case EDIT_ITINERAIRE: {
      return {
        ...state,
        itineraires: state.itineraires.map(
          ITINERAIRE => ITINERAIRE.id === action.payload.id ? action.payload : ITINERAIRE
        ),
      };
    }

    case DELETE_ITINERAIRE: {
      return {
        ...state,
        itineraires: state.itineraires.filter(ITINERAIRE => ITINERAIRE.id !== action.payload),
      };
    }
    case SET_ITINERAIRE_DETAILS: {
      return {
        ...state,
        currentItineraire: action.payload,
      };
    }

    default:
      return state;
  }
};
export default Itineraires;
