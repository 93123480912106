import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import configureStore from "./redux/store";
import AppWrapper from "./@jumbo/components/AppWrapper";
import AppContextProvider from "./@jumbo/components/contextProvider/AppContextProvider";
import SalRoutes from "./routes";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IntlMessages from "@jumbo/utils/IntlMessages";


export const store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppContextProvider>
          <AppWrapper>
            {((window.localStorage.userETQsal14mp52 &&
              window.location.pathname.toString().search("/superadmin/") ===
                -1) ||
              (window.localStorage.salETQ12gtu47 &&
                window.location.pathname.toString().search("/sag/") ===
                  -1)) && (
              <Alert
                severity="error"
                style={{ position: "fixed", zIndex: "1999", top: "0" }}
              >
                <AlertTitle>
                  <strong>{<IntlMessages id="connexion.etq.traces" />}</strong>
                </AlertTitle>
              </Alert>
            )}
            
            <SalRoutes />
          </AppWrapper>
        </AppContextProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;

/*Router location={history.location} navigator={history}*/
