import React, { useEffect, useState } from "react";
import useStyles from "../../CommonComponents/Style/TypoModelStyle";
import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  establishmentSingleSchool,
  establishmentTypeList,
  systemeEducationList,
} from "Config/listes";
import { useSelector } from "react-redux";
import VisioConference from "./VisioConference";

export default function CategoryInformation({
  disabled,
  establishmentData,
  establishmentValidation,
  setEstablishmentData,
  setEstablishmentValidation,
  currentEstablishment,
}) {
  const establishmentStatus = [
    { label: "Publique", id: "public" },
    { label: "Privée", id: "private" },
  ];
  const classes = useStyles();
  const { establishments } = useSelector(
    ({ EstablishmentsReducer }) => EstablishmentsReducer
  );
  const [establishementParentTab, setEstablishementParentTab] = useState([]);
  useEffect(() => {
    setEstablishementParentTab(
      establishments.filter(
        (establishment) => establishment.establishmentType === "complex"
      )
    );
  }, [establishments]);
  return (
    <>
      {" "}
      <h5 className={classes.sectionTitle}>
        <ApartmentIcon className={classes.iconSectionTitle} />
        <IntlMessages id="appModule.information.categories" />
      </h5>
      <GridContainer>
        <Grid item xs={12} sm={12}>
          <AppSelectBox
            fullWidth
            data={systemeEducationList}
            label={
              <IntlMessages id="appModule.information.categories.systeme" />
            }
            valueKey="slug"
            variant="outlined"
            required
            disabled={disabled}
            value={establishmentData.educationSystem}
            id="educationSystem"
            labelKey="title"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                educationSystem: e.target.value,
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                educationSystem: undefined,
              });
            }}
            helperText={establishmentValidation.educationSystem}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AppSelectBox
            disabled={disabled || currentEstablishment}
            fullWidth
            data={establishmentTypeList}
            label={
              <IntlMessages id="appModule.information.establishment.type" />
            }
            valueKey="slug"
            variant="outlined"
            required
            labelKey="title"
            value={establishmentData.establishmentType}
            id="establishmentType"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                educationType:
                  e.target.value === "complex"
                    ? undefined
                    : establishmentData.educationType,
                establishmentType: e.target.value,
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                educationType:
                  e.target.value === "complex"
                    ? undefined
                    : establishmentValidation.educationType,
                establishmentType: undefined,
              });
            }}
            helperText={establishmentValidation.establishmentType}
          />
        </Grid>
        {establishmentData.establishmentType !== "simple" && (
          <Grid item xs={12} sm={12} style={{ paddingRight: 0 }}>
            <span style={{ padding: "0 15px" }}>
              Choisir le statut de l'établissement :
            </span>
            {establishmentStatus.map((row) => (
              <>
                <FormControlLabel
                  disabled={disabled}
                  className={classes.formcontrolLabelRoot}
                  control={<Checkbox name={row.id} />}
                  label={row.label}
                  value={establishmentData.establishmentStatus}
                  labelKey={row.label}
                  name={"establishmentStatus"}
                  checked={establishmentData.establishmentStatus === row.id}
                  onChange={(e) => {
                    setEstablishmentData({
                      ...establishmentData,
                      establishmentStatus: e.target.name,
                    });
                  }}
                />
              </>
            ))}
          </Grid>
        )}
        {establishmentData.establishmentType === "simple" && (
          <>
            {" "}
            <GridContainer
              style={{ marginBottom: 1, marginLeft: 1, marginRight: 1 }}
            >
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="combo-box-demo"
                  required
                  disabled={disabled}
                  value={
                    establishmentSingleSchool.find(
                      (e) => e.slug === establishmentData.educationType
                    ) ?? ""
                  }
                  size="small"
                  options={establishmentSingleSchool}
                  getOptionLabel={(option) => option.slug || ""}
                  onChange={(e, newValue) => {
                    setEstablishmentData({
                      ...establishmentData,
                      educationType: newValue?.slug,
                    });
                    setEstablishmentValidation({
                      ...establishmentValidation,
                      educationType: undefined,
                    });
                  }}
                  renderInput={(params) => (
                    <AppTextInput
                      {...params}
                      required
                      label={
                        <IntlMessages id="appModule.information.education.type" />
                      }
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
                <span
                  style={{
                    color: "red",
                    fontSize: 12,
                    marginLeft: 12,
                    fontWeight: 400,
                  }}
                >
                  {establishmentValidation.educationType}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="combo-box-demo"
                  disabled={disabled || currentEstablishment}
                  value={establishmentData.establishementParent}
                  size="small"
                  options={establishementParentTab}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <AppTextInput
                      {...params}
                      label={
                        <IntlMessages id="appModule.establishment.parent.name" />
                      }
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                  onChange={(e, newValue) => {
                    setEstablishmentData({
                      ...establishmentData,
                      establishementParent: newValue,
                    });
                    setEstablishmentValidation({
                      ...establishmentValidation,
                      establishementParent: undefined,
                    });
                  }}
                />
                <span
                  style={{
                    color: "red",

                    fontSize: 12,

                    marginLeft: 12,

                    fontWeight: 400,
                  }}
                >
                  {establishmentValidation.establishementParent}
                </span>
              </Grid>
            </GridContainer>
            <VisioConference
              currentEstablishment={currentEstablishment}
              disabled={disabled}
              establishmentValidation={establishmentValidation}
              establishmentData={establishmentData}
              setEstablishmentData={setEstablishmentData}
              setEstablishmentValidation={setEstablishmentValidation}
            />
          </>
        )}
      </GridContainer>
    </>
  );
}
