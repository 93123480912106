import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router";
import ListSubEstablishment from "./Auth/ListSubEstablishment";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import EstablishmentManagement from "./EstablishmentManagement/index";
import LicenseManagement from "./LicenseManagement";
import AccountManagement from "./AccountManagement/index";
import ConnectionAs from "./ConnectionAs";
import SalComponents from "./SalComponents";
import { AuhMethods } from "../services/auth";
import { roleIdSAG, roleIdSAL, roleIdADL, roleIdOperator } from "../Config";
import NotFoundPage from "../routes/ExtraPages/404/index";
import { Alert } from "@mui/material";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Callback from "./Auth/Callback";

const RestrictedRoute = ({ children }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  if (!authUser) {
    return <Navigate to={"/signin"} replace />;
  }
  return children;
};

const SalRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userProfile, authUser } = useSelector(({ auth }) => auth);


  const schoolYearStatus = useSelector((state) => state?.auth?.selectedYear);

  // useEffect(() => {
  //   if (window.location.pathname === "/callback") {
  //     localStorage.removeItem("subEstab45lo54");
  //     localStorage.removeItem("first478loi52");
  //   }
  // });
  // ************** block retour en arriére  **********

  useEffect(() => {
    if (
      window.location.pathname === "/subEstablishment" ||
      window.location.pathname === "/callback"
    ) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function(event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  }, []);

  useEffect(() => {
    if (
      authUser &&
      userProfile === "" &&
      window.location.pathname !== "/callback"
    ) {
      dispatch(AuhMethods["jwtAuth"].getData(authUser));
    } else if (
      authUser &&
      userProfile?.roleId !== roleIdSAG &&
      userProfile?.licence === null
    ) {
      localStorage.removeItem("subEstab45lo54");
      localStorage.removeItem("first478loi52");
    } else if (authUser === null && location.pathname !== "/callback") {
      localStorage.removeItem("subEstab45lo54");
      localStorage.removeItem("first478loi52");
      document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
    }
  }, [userProfile, authUser, location, dispatch]);

  // ************** redirection after callback **********
  switch (userProfile?.roleId) {
    case roleIdSAG:
      if (authUser && ["/", "/callback"].includes(location.pathname)) {
        return <Navigate to={"/app/sag/establishment-management"} />;
      }

      break;

    case roleIdSAL:
      if (
        authUser &&
        ["/", "/callback"].includes(location.pathname) &&
        userProfile?.establishmentType === "simple" &&
        userProfile?.licence !== null
      ) {
        return <Navigate to={"/app/superadmin"} />;
      } else if (
        ["/", "/callback"].includes(location.pathname) &&
        userProfile?.establishment?.establishmentType === "complex"
      ) {
        return <Navigate to={"/subEstablishment"} />;
      } else if (
        authUser &&
        location.pathname === "/subEstablishment" &&
        userProfile?.establishmentType === "complex" &&
        userProfile?.subEstablishmentId !== null &&
        userProfile?.licence !== null
      ) {
        return <Navigate to={"/app/superadmin"} />;
      } else if (
        authUser &&
        location.pathname === "/app/sag/connection-as" &&
        userProfile?.establishmentType === "simple" &&
        userProfile?.licence !== null
      ) {
        return <Navigate to={"/app/superadmin"} />;
      } else if (
        location.pathname === "/app/sag/connection-as" &&
        userProfile?.establishment?.establishmentType === "complex"
      ) {
        return <Navigate to={"/subEstablishment"} />;
      }

      break;

    case roleIdADL:
      if (
        authUser &&
        ["/", "/callback"].includes(location.pathname) &&
        userProfile?.licence !== null
      ) {
        return <Navigate to={"/app/admin"} />;
      }
      break;
    case roleIdOperator:
      if (
        authUser &&
        ["/", "/callback"].includes(location.pathname) &&
        userProfile?.establishmentType === "simple" &&
        userProfile?.licence !== null
      ) {
        return <Navigate to={"/app/operator"} />;
      } else if (
        ["/", "/callback"].includes(location.pathname) &&
        userProfile?.establishment?.establishmentType === "complex"
      ) {
        return <Navigate to={"/subEstablishment"} />;
      } else if (
        authUser &&
        location.pathname === "/subEstablishment" &&
        userProfile?.establishmentType === "complex" &&
        userProfile?.subEstablishmentId !== null &&
        userProfile?.licence !== null
      ) {
        return <Navigate to={"/app/operator"} />;
      } else if (
        authUser &&
        location.pathname === "/app/sag/connection-as" &&
        userProfile?.establishmentType === "simple" &&
        userProfile?.licence !== null
      ) {
        return <Navigate to={"/app/operator"} />;
      } else if (
        location.pathname === "/app/superadmin/connection-as" &&
        userProfile?.establishment?.establishmentType === "complex"
      ) {
        return <Navigate to={"/subEstablishment"} />;
      }
      break;
    default:
  }

  return (
    <>
     
      <React.Fragment>
        {userProfile && schoolYearStatus === "Expirée" && (
          <div
            style={{
              color: "red",
              fontSize: "14px",
              marginBottom: "8px",
              marginTop: "2rem",
              zIndex: "9",
            }}
          >
            <Alert severity="warning">
              <IntlMessages id="appModule.expired.licence" />
            </Alert>
          </div>
        )}
        <Routes>
          {/* super admin globale */}
          <Route
            path="app/sag/establishment-management"
            element={
              <RestrictedRoute>
                {userProfile?.roleId === roleIdSAG && (
                  <EstablishmentManagement />
                )}
              </RestrictedRoute>
            }
          />
          <Route
            path="app/sag/license-management"
            element={
              <RestrictedRoute>
                {userProfile?.roleId === roleIdSAG && <LicenseManagement />}
              </RestrictedRoute>
            }
          />
          <Route
            path="app/sag/account-management"
            element={
              <RestrictedRoute>
                {userProfile?.roleId === roleIdSAG && <AccountManagement />}
              </RestrictedRoute>
            }
          />
          

          <Route
            path="app/sag/connection-as"
            element={
              <RestrictedRoute>
                {userProfile?.roleId === roleIdSAG && <ConnectionAs />}
              </RestrictedRoute>
            }
          />

          {/* super admin local */}

          <Route
            path="app/superadmin/*"
            element={userProfile?.roleId === roleIdSAL && <SalComponents />}
          />
          <Route
            path="app/admin/*"
            element={userProfile?.roleId === roleIdADL && <SalComponents />}
          />
          {/* Operator */}
          <Route
            path="app/operator/*"
            element={
              userProfile?.roleId === roleIdOperator && <SalComponents />
            }
          />
          {/* authentification */}
          <Route path="/callback" element={<Callback />} />
          {authUser && (
            <Route
              path="subEstablishment/*"
              element={<ListSubEstablishment />}
            />
          )}
          

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default SalRoutes;
