import {
    ADD_MATERIAL,
    DELETE_MATERIAL,
    EDIT_MATERIAL,
    GET_MATERIALS,
    SET_MATERIAL_DETAILS
  } from "../../@jumbo/constants/ActionTypes";
  
  const INIT_STATE = {
    materials: [],
    currentMaterial: null
  };
  
  const Materials = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_MATERIALS: {
        return {
          ...state,
          materials: action.payload
        };
      }
      case SET_MATERIAL_DETAILS: {
        return {
          ...state,
          currentMaterial: action.payload
        };
      }
      case ADD_MATERIAL: {
        return {
          ...state,
          materials: [ action.payload, ...state.materials ]
        };
      }
      case EDIT_MATERIAL: {
        return {
          ...state,
          materials: state.materials.map(
            MATERIAL =>
              MATERIAL.id === action.payload.id
                ? action.payload
                : MATERIAL
          )
        };
      }
      case DELETE_MATERIAL: {
        return {
          ...state,
          materials: state.materials.filter(
            MATERIAL => MATERIAL.id !== action.payload
          )
        };
      }
  
      default:
        return state;
    }
  };
  export default Materials