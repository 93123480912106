import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";

import { crudApp } from "../../services/crudApp";
import {
  ADD_ROOM,
  EDIT_ROOM,
  GET_ROOMS,
  SET_ROOM_DETAILS,
} from "@jumbo/constants/ActionTypes";
import IntlMessages from "@jumbo/utils/IntlMessages";

export const getRoom = (
  establishmentId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`rooms?establishmentId=${establishmentId}`, {
        params: { filterOptions, searchTerm },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ROOMS, payload: data.data.reverse() });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(
              <IntlMessages id="app.module.get.serverProblem.fetchError" />
            )
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};

export const setCurrentRoom = (rooms) => {
  return (dispatch) => {
    dispatch({ type: SET_ROOM_DETAILS, payload: rooms });
  };
};

export const addNewRoom = (rooms, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("rooms", rooms)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(<IntlMessages id="app.module.room.add.fetchSuccess" />)
          );
          dispatch({ type: ADD_ROOM, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};

export const updateRoom = (rooms, establishmentId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`rooms/${establishmentId}`, rooms)
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />)
          );
          dispatch({ type: EDIT_ROOM, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};
export const importSalle = (salles, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("rooms/import", salles)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.Student.getRooms.fetchSuccess" />
            )
          );
          if (callbackFun) callbackFun(data.data);
        } else {
          if (data.response.data.id === "existing.room")
            dispatch(fetchError("Il existe des salles déjà ajoutés"));
          else if (data.response.data.id === "unsupported.file.header")
            dispatch(fetchError("Le type de fichier est incorrecte !"));
          else if (data.response.data.id === "invalid.date")
            dispatch(
              fetchError("La date de couverture est inférieur à aujourd'hui!")
            );
          else if (data.response.data.id === "room.capicity")
            dispatch(
              fetchError(
                "La capacité de la salle d'examen devrait être inférieure à celle du cours!"
              )
            );
          else
            dispatch(
              fetchError(
                <IntlMessages id="app.module.get.problem.fetchError" />
              )
            );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};
