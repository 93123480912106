module.exports = {
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
  captchaSiteKey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
  captchaCheckKey : process.env.REACT_APP_CAPTCHA_CHECK_KEY,
  roleIdSAG: 1,
  roleIdSAL: 2,
  roleIdADL: 3,
  roleIdOperator: 4,
  roleIdProfessor: 5,
  roleIdParent: 6,
  roleIdStudent: 7,
  socketIOURL: process.env.REACT_APP_SOCKETIO_ENDPOINT,
};
