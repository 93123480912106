import React from 'react';
import { Radio, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
})); 

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#5B13B4',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#5B13B4',
  },
});

const AppRadioButton = ({
  name,
  id,
  value,
  label,
  labelPlacement,
  checked,
  onChange,
  color,
  required,
  error,
  helperText,
  style,
  ...rest
}) => {
  return (
    <FormControl style={style} required={required} error={error} component="fieldset" {...rest}>
      <FormControlLabel
        value={value}
        control={<Radio checked={checked} name={name} id={id || name} onChange={onChange} color={color} checkedIcon={<BpCheckedIcon />}/>}
        label={label}
        labelPlacement={labelPlacement}
      />

      <FormHelperText error={error || helperText !== ''}>{helperText}</FormHelperText>
    </FormControl>
  );
};

AppRadioButton.prototype = {
  name: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  labelPlacement: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
};

AppRadioButton.defaultProps = {
  type: 'text',
  checked: false,
  color: 'primary',
  labelPlacement: 'end',
  error: false,
  helperText: '',
};

export default AppRadioButton;
