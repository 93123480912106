import React, { lazy, Suspense, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import PageLoader from "../../@jumbo/components/PageComponents/PageLoader";
import { useSelector } from "react-redux";
import { Joyride } from "routes/TourGuide";
import { Alert, AlertTitle } from "@mui/material";
import IntlMessages from "@jumbo/utils/IntlMessages";
import moment from "moment";

const ClassroomManagement = lazy(() => import("./ClassroomManagement"));
const AdministrativeDocuments = lazy(() => import("./AdministrativeDocuments"));
const EstablishmentSheet = lazy(() => import("./EstablishmentSheet"));
const AccountManagement = lazy(() => import("./AccountManagement"));
const SchoolParameters = lazy(() => import("./SchoolParameters"));
const Profile = lazy(() => import("./Profile"));
const Dashboard = lazy(() => import("./Dashboard"));
const ELibrary = lazy(() => import("./ELibrary"));
const EduDrive = lazy(() => import("./Edu-Drive"));
const Reports = lazy(() => import("./Reports"));
const Cafeteria = lazy(() => import("./Cafeteria"));
const Supervisor = lazy(() => import("./Supervisor"));
const Complaints = lazy(() => import("./Complaints"));
const MedicalFolder = lazy(() => import("./MedicalFolder"));
const HRMonitoring = lazy(() => import("./HRMonitoring"));
const ClubsManagement = lazy(() => import("./ClubsManagement"));
const ParkManagement = lazy(() => import("./ParkManagement"));
const RealTime = lazy(() => import("./ParkManagement/RealTimeTracking"));
const DashboardDriver = lazy(() => import("./DashboardDriver"));

const FinancialSettings = lazy(() => import("./FinancialSettings"));
const Evaluations = lazy(() => import("./Evaluations"));
const News = lazy(() => import("./News"));
const Messaging = lazy(() => import("./Messaging"));
const Ratings = lazy(() => import("./Ratings"));
const DiscussionGroups = lazy(() => import("./DiscussionGroups"));
const Harassment = lazy(() => import("./Harassment"));
const ConnexionAs = lazy(() => import("./ConnexionAs"));
const ExtraPages = lazy(() => import("../../routes/ExtraPages"));
const About = lazy(() => import("./HelpCenter/About"));
const Community = lazy(() => import("./HelpCenter/Community"));
const Compte = lazy(() => import("./HelpCenter/Profil"));
const Confidentiality = lazy(() => import("./HelpCenter/Confidentiality"));
const Modules = lazy(() => import("./HelpCenter/Modules"));
const FAQ = lazy(() => import("./HelpCenter/FAQ"));
const PreInsciprion = lazy (()=> import ("./PreInscription"))
const HelpCenter = lazy(() => import("./HelpCenter"));
const Dashboards = () => {
  const schoolYearStatus = useSelector((state) => state?.auth?.selectedYear);
  const [run, setRun] = useState(true);
  const [stepIndexState, setStepIndexState] = useState(0);
  const { userProfile } = useSelector(({ auth }) => auth);

  return (
    <Suspense fallback={<PageLoader />}>
      {userProfile?.firstConnexion && (
        <Joyride
          run={run}
          setRun={setRun}
          stepIndexState={stepIndexState}
          setStepIndexState={setStepIndexState}
        />
      )}
      {userProfile?.licence?.licenseStatus === "Demo" && (
        <div style={{ display: "flex", justifyContent: "center",  marginBottom: "30px", }}>
          <Alert
            severity="warning"
            style={{
              position: "fixed",
              zIndex: "1999",
              top: "0",
              width: "70.7%",
              paddingBottom: 0,
              marginBottom: "20px",
            }}
          >
            <AlertTitle>
              <strong>
                {<IntlMessages id="app.module.demo.lisence.alert" />}{" "}
                {moment(userProfile?.licence?.endDemo).format("DD-MM-YYYY")} à{" "}
                {moment(userProfile?.licence?.endDemo).format("HH:mm")}
              </strong>
            </AlertTitle>
          </Alert>
        </div>
      )}
      <Routes>
        <Route index element={<Navigate to="dashboard" replace />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route
          path="classroom-management/*"
          element={<ClassroomManagement />}
        />
        <Route
          path="administrative-documents/*"
          element={<AdministrativeDocuments />}
        />
        <Route path="establishment-sheet/*" element={<EstablishmentSheet />} />
        <Route path="profile" element={<Profile />} />
        <Route path="account-management/*" element={<AccountManagement />} />

        <Route path="school-parameters/*" element={<SchoolParameters />} />
        <Route path="eLibrary/*" element={<ELibrary />} />
        {schoolYearStatus !== "Expirée" && (
          <Route path="cafeteria/*" element={<Cafeteria />} />
        )}
        <Route path="supervisor/*" element={<Supervisor />} />
        <Route path="complaints/*" element={<Complaints />} />
        <Route path="medical-file/*" element={<MedicalFolder />} />
        <Route path="HR-monitoring/*" element={<HRMonitoring />} />
        <Route path="clubs-management/*" element={<ClubsManagement />} />

        {schoolYearStatus !== "Expirée" && (
          <Route path="financial-settings/*" element={<FinancialSettings />} />
        )}
        <Route path="evaluation/*" element={<Evaluations />} />
        <Route path="news" element={<News />} />
        {schoolYearStatus !== "Expirée" && (
          <Route path="messaging" element={<Messaging />} />
        )}
        <Route path="ratings/*" element={<Ratings />} />
        {schoolYearStatus !== "Expirée" && (
          <Route path="discussion-group" element={<DiscussionGroups />} />
        )}
        <Route path="harassment-alert" element={<Harassment />} />
        <Route path="connection-as" element={<ConnexionAs />} />
        <Route path="edu-drive/*" element={<EduDrive />} />
        <Route path="help-center" element={<HelpCenter />} />
        <Route path="help-center/about" element={<About />} />
        <Route path="help-center/community" element={<Community />} />
        <Route path="help-center/compte" element={<Compte />} />
        <Route
          path="help-center/confidentiality"
          element={<Confidentiality />}
        />
        <Route path="help-center/modules" element={<Modules />} />
        <Route path="help-center/FAQ" element={<FAQ />} />
        <Route path="reports" element={<Reports />} />
        <Route path="*" element={<ExtraPages />} />
        <Route path="park-managment/*" element={<ParkManagement />} />

        <Route path="/realtime-management/*" element={<RealTime />} />
        <Route path="/driver/*" element={<DashboardDriver />} />
        <Route path="/users-management" element={<PreInsciprion />} />
      </Routes>
    </Suspense>
  );
};

export default Dashboards;
