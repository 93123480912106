import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import {
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser,
  getModules,
  setPassWordChanged,
  setErrorMessage,
} from "../../../redux/actions/Auth";
import React from "react";

import axios from "./config";
import { roleIdOperator, roleIdSAG, roleIdSAL } from "../../../Config/index";
import { menuSAG } from "../../../Config/listes";
import jwt_decode from "jwt-decode";
import { getLevels } from "redux/actions/Level";
import { getClassrooms } from "redux/actions/Classroom";
import { getSubjects } from "redux/actions/Subject";
import { getRoom } from "redux/actions/Room";
import { getProfessors } from "redux/actions/Professor";
import { getGroups } from "redux/actions/GroupClass";
import { getStudents, getParents } from "redux/actions/Student";
import { getOperator } from "redux/actions/Operator";
import { getUsers } from "redux/actions/Users";
import { getSchoolSession } from "redux/actions/session";
import {
  getSubEstablishment,
  getEstablishments,
} from "redux/actions/Establishments";

import { getComplaints } from "redux/actions/Complaint";
import { getNews } from "redux/actions/News";
import { getHolidays } from "redux/actions/Holiday";
import { getVacations } from "redux/actions/Vacation";
import { crudApp } from "../../../services/crudApp";
import _ from "lodash";
import { connectToCanal } from "redux/actions/SocketIO";
import { history } from "redux/store";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { getSubjectModules } from "redux/actions/SubjectModule";

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return (dispatch) => {
      dispatch(fetchStart());
      crudApp
        .post("auth/register", {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem("first478loi52", data.token.access_token);
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password, token, key }) => {
    return (dispatch) => {
      try {
        dispatch(fetchStart());
        axios
          .post(
            "users/login",
            {
              email: email,
              password: password,
              key: key,
            },
            {
              headers: {
                recaptcha: token,
              },
            }
          )
          .then(({ data }) => {
            if (data.accessToken) {
              if (data.application === "BackOffice") {
                localStorage.setItem("first478loi52", data.accessToken);
                dispatch(fetchSuccess());
                dispatch(JWTAuth.getDataToken(data.accessToken));
              } else {
                document.location.href = `${process.env.REACT_APP_PARENT_APP}/callback#access_token=${data.accessToken}`;
              }
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(setErrorMessage(error.response.data.message));
            dispatch(
              fetchError(
                "Votre identifiant ou mot de passe est incorrect \n Veuillez vérifier"
              )
            );
          });
      } catch (error) {
        dispatch(fetchError(error?.message));
      }
    };
  },

  getDataToken: (token, user) => {
    return (dispatch) => {
      let tokenDecoded = jwt_decode(token);
      if (
        tokenDecoded.roleId === roleIdSAL ||
        tokenDecoded.roleId === roleIdOperator
      ) {
        if (tokenDecoded?.establishment?.establishmentType === "simple") {
          let subEstablishmentId =
            tokenDecoded?.establishment?.subEstablishments[0]?.id;
          if (window.localStorage.userETQsal14mp52)
            dispatch(JWTAuth.getScopeUser(subEstablishmentId, "simple", user));
          else if (window.localStorage.salETQ12gtu47) {
            dispatch(JWTAuth.getScopeUser(subEstablishmentId, "simple"));
          } else dispatch(JWTAuth.getScopeUser(subEstablishmentId));
        } else {
          dispatch(setAuthUser(tokenDecoded));

          if (
            localStorage.subEstab45lo54 !== undefined &&
            tokenDecoded.roleId === roleIdSAL
          ) {
            dispatch(JWTAuth.getScopeUser(localStorage.subEstab45lo54));
          }
        }
      } else dispatch(JWTAuth.getAuthUser(true, token, user));
    };
  },

  getScopeUser: (subEstablishmentId, type, user, schoolYearId) => {
    let apiEndpoint = `users/scope?subEstablishmentId=${subEstablishmentId}${
      schoolYearId ? "&schoolYearId=" + schoolYearId : ""
    }`;

    return (dispatch) => {
      crudApp
        .get(apiEndpoint, type)
        .then(({ data }) => {
          //License bloqued or expired
          if (data === undefined) {
            localStorage.removeItem("subEstab45lo54");
            localStorage.removeItem("first478loi52");
            document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
          }
          if (data) {
            if (
              window.localStorage.userETQsal14mp52 &&
              window.location.pathname
                .toString()
                .search("/subEstablishment") !== -1
            ) {
              window.open(`${window.location.origin}/app/${user}/dashboard`);

              if (
                window.localStorage.userETQsal14mp52 &&
                window.localStorage.salETQ12gtu47 &&
                window.location.pathname
                  .toString()
                  .search("/subEstablishment") === -1
              )
                dispatch(JWTAuth.getAuthUser(true, data.accessToken, user));
            } else if (
              window.localStorage.userETQsal14mp52 &&
              type === "simple"
            ) {
              window.open(`${window.location.origin}/app/${user}/dashboard`);

              if (
                window.localStorage.userETQsal14mp52 &&
                type === "simple" &&
                window.location.pathname.toString().search("/superadmin/") ===
                  -1
              )
                dispatch(JWTAuth.getAuthUser(true, data.accessToken, user));
            } else if (
              window.localStorage.salETQ12gtu47 &&
              window.location.pathname
                .toString()
                .search("/subEstablishment") !== -1
            ) {
              window.open(`${window.location.origin}/app/superadmin/dashboard`);
              if (
                window.localStorage.salETQ12gtu47 &&
                window.location.pathname
                  .toString()
                  .search("/subEstablishment") === -1
              )
                dispatch(JWTAuth.getAuthUser(true, data.accessToken));
            } else if (window.localStorage.salETQ12gtu47 && type === "simple") {
              window.open(`${window.location.origin}/app/superadmin/dashboard`);

              if (
                window.localStorage.salETQ12gtu47 &&
                type === "simple" &&
                window.location.pathname.toString().search("/sag/") === -1
              )
                dispatch(JWTAuth.getAuthUser(true, data.accessToken));
            } else dispatch(JWTAuth.getAuthUser(true, data.accessToken));

            if (
              (window.localStorage.userETQsal14mp52 &&
                window.location.pathname.toString().search("/superadmin/") ===
                  -1) ||
              (window.localStorage.userETQsal14mp52 && type === "simple")
            )
              window.localStorage.setItem("userETQsal14mp52", data.accessToken);
            else
              (window.localStorage.salETQ12gtu47 &&
                window.location.pathname.toString().search("/sag/") === -1) ||
              (window.localStorage.salETQ12gtu47 && type === "simple")
                ? window.localStorage.setItem("salETQ12gtu47", data.accessToken)
                : localStorage.setItem("first478loi52", data.accessToken);
          } else {
            dispatch(updateLoadUser(true));
            dispatch(
              fetchError(<IntlMessages id="appModule.nolisence.error" />)
            );
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  getAuthUser: (loaded = false, token, user) => {
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get("users/me")
        .then(({ data }) => {
          if (data) {
            dispatch(fetchSuccess());
            let decodedNewToken = jwt_decode(token);
            if (data.roleId === roleIdSAG) {
              dispatch(JWTAuth.getModules(data.roleId, []));
              dispatch(setAuthUser(data));
              dispatch(getEstablishments());
              dispatch(getUsers());
            } else {
              dispatch(
                setAuthUser({
                  ...data,
                  establishmentName: decodedNewToken?.establishment?.name,
                  establishmentId: decodedNewToken?.establishment?.id,
                  subEstablishmentId: decodedNewToken?.subEstablishment?.id,
                  subEstablishmentList:
                    decodedNewToken?.establishment?.subEstablishments,
                  establishmentType:
                    decodedNewToken?.establishment?.establishmentType,
                  schoolYearId:
                    decodedNewToken?.subEstablishment?.currentLicence
                      ?.schoolyear?.id,
                  schoolYearName:
                    decodedNewToken?.subEstablishment?.currentLicence
                      ?.schoolyear?.year,
                  licence: decodedNewToken?.subEstablishment?.currentLicence,
                  subEstablishmentName: decodedNewToken?.subEstablishment?.name,
                  bnh12p7:
                    decodedNewToken?.establishment?.establishmentType ===
                      "complex" && token,
                  currentSubEstablishmentName:
                    decodedNewToken?.subEstablishment?.name,
                  currentSubEstablishmentId:
                    decodedNewToken?.subEstablishment?.id,
                  allLicences: decodedNewToken.subEstablishment.allLicences,
                })
              );
              dispatch(
                JWTAuth.getModules(
                  decodedNewToken?.roleId,
                  decodedNewToken?.subEstablishment?.currentLicence?.modules
                )
              );
              if (decodedNewToken?.subEstablishment?.currentLicence === null) {
                if (
                  decodedNewToken?.establishment?.establishmentType ===
                  "complex"
                ) {
                  document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
                  // window.location.href = '/signin';
                  dispatch(fetchError("Merci de vérifier votre licence"));
                } else {
                  dispatch(fetchError("Merci de vérifier votre licence"));
                }
              } else {
                dispatch(
                  JWTAuth.initStore(
                    decodedNewToken?.establishment?.id,
                    decodedNewToken?.subEstablishment?.id,
                    decodedNewToken?.subEstablishment?.currentLicence
                      ?.schoolyear?.id,
                    decodedNewToken?.subEstablishment?.currentLicence?.modules,
                    token
                  )
                );
              }
            }
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          if (error?.response?.status === 401) {
            localStorage.removeItem("subEstab45lo54");
            localStorage.removeItem("first478loi52");
            localStorage.removeItem("subEstabETQUser45lo54");
            localStorage.removeItem("userETQsal14mp52");
            // window.location.href = '/signin';
            document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
          }
          dispatch(updateLoadUser(true));
        });
    };
  },

  getModules: (roleId, modulesList) => {
    return (dispatch) => {
      if (roleId === roleIdSAG) {
        dispatch(getModules(menuSAG));
      } else {
        dispatch(getModules(modulesList));
      }
    };
  },

  initStore: (
    establishmenId,
    subEstablishmentId,
    schoolYearId,
    modules,
    token
  ) => {
    let pathModules = _.map(modules, "pathName");
    let subModules = _.map(_.flatten(_.map(modules, "subModules")), "pathName");

    return (dispatch) => {
      dispatch(getSubEstablishment(subEstablishmentId));
      dispatch(getParents(subEstablishmentId));
      dispatch(getUsers());
      dispatch(getOperator());
      dispatch(getStudents(subEstablishmentId));
      dispatch(getProfessors(subEstablishmentId));
      dispatch(getClassrooms(subEstablishmentId, schoolYearId));
      dispatch(connectToCanal(token));

      if (_.includes(subModules, "/school-settings")) {
        dispatch(getHolidays(establishmenId));
        dispatch(getVacations(subEstablishmentId));
        dispatch(getSubjects(subEstablishmentId));
        dispatch(getGroups(+schoolYearId, subEstablishmentId));
        dispatch(getSchoolSession(subEstablishmentId, +schoolYearId));
        dispatch(getLevels(subEstablishmentId));
        dispatch(getClassrooms(subEstablishmentId, schoolYearId));
      }

      if (_.includes(subModules, "/reports")) {
        dispatch(getLevels(subEstablishmentId));
        dispatch(getSubjectModules(subEstablishmentId));
      }

      if (_.includes(subModules, "/room-management")) {
        dispatch(getRoom(establishmenId));
      }

      if (_.includes(pathModules, "/complaints")) {
        dispatch(getComplaints(subEstablishmentId, schoolYearId));
      }

      if (_.includes(pathModules, "/news")) {
        dispatch(getNews(subEstablishmentId));
      }
    };
  },

  getData: (token) => {
    return (dispatch) => {
      dispatch(JWTAuth.getAuthUser(true, token));
    };
  },

  onLogout: () => {
    return (dispatch) => {
      dispatch(fetchStart());
      axios
        .post("auth/logout")
        .then(({ data }) => {
          if (data.result) {
            dispatch(fetchSuccess());

            localStorage.removeItem("subEstab45lo54");
            dispatch(setAuthUser(null));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onForgotPassword: (email) => {
    return (dispatch) => {
      dispatch(fetchStart());
      crudApp
        .post("users/forget-password", email)
        .then((data) => {
          if (data.status === 200 || data.status === 201) {
            setTimeout(() => {
              dispatch(setForgetPassMailSent(true));
              dispatch(fetchSuccess());
            }, 300);
          } else {
            dispatch(
              fetchError("Votre email est incorrect \n Veuillez vérifier")
            );
          }
        })
        .catch(function(error) {
          dispatch(setForgetPassMailSent(false));
        });
    };
  },

  onInitPassword: (newPassword, callbackfun) => {
    return (dispatch) => {
      try {
        dispatch(fetchStart());
        axios
          .put("users/change-password?fromProfile=true", newPassword)
          .then(({ data }) => {
            if (data) {
              dispatch(
                fetchSuccess("Votre mot de passe a été changé avec succès")
              );
            }
            if (callbackfun) return callbackfun(data.data);
          })
          .catch(function(error) {
            dispatch(
              fetchError(
                "Votre ancien mot de passe est incorrect \n Veuillez vérifier"
              )
            );
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onChangePassword: (newPassword, token) => {
    return (dispatch) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        try {
          axios
            .put(`users/change-password?fromForget=${true}`, newPassword)
            .then(({ data }) => {
              if (data) {
                dispatch(fetchSuccess("Le mot de passe a été changé"));
                history.push(`/signin`);
              }
            })
            .catch(function(error) {
              dispatch(setPassWordChanged(false));
              dispatch(
                fetchError(
                  "Votre mot de passe est incorrect \n Veuillez vérifier"
                )
              );
            });
        } catch (error) {
          dispatch(fetchError(error.message));
        }
      }
    };
  },

  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
