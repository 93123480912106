import * as moment from "moment";
export const getTodayDate = (format = "DD.MM.YYYY") => {
  return moment().format(format);
};

export const getYesterdayDate = () => {
  return moment()
    .subtract(1, "day")
    .format("DD.MM.YYYY");
};

export const timeFromNow = (date) => {
  const timestamp = moment(date).format("X");
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

export const isToday = (date) => {
  return moment().isSame(date, "day");
};

export const getNewDate = (noOfDays, format = "DD MMM, YYYY") => {
  return moment()
    .add(noOfDays, "days")
    .format(format);
};

export const getDateElements = (date) => {
  const dateString = moment(date).format("dddd, MMMM DD YYYY, hh:mm A");
  const dateSections = dateString.split(",");
  const day = dateSections[0];
  const time = dateSections[2];
  const datePart = dateSections[1].trim().split(" ");
  return {
    day,
    time,
    date: {
      dateString: dateSections[1],
      month: datePart[0],
      date: datePart[1],
      year: datePart[2],
    },
  };
};

export const getTime = (date) => {
  const dateObj = moment(date, "dddd, MMMM DD YYYY, hh:mm a");
  return moment(dateObj).format("LT");
};

export const isDatesSame = (dateA, dateB) => {
  return moment(dateA).isSame(dateB, "day");
};

export const isDateAfter = (startDate, endDate) => {
  return moment(startDate).isAfter(endDate);
};

export const getDateinDesiredFormat = (date, format) => {
  return moment(date).format(format);
};

export const makeJSDateObject = (date) => {
  if (date) {
    return new Date(date.getTime());
  }
  return date;
};

/**
 * Get Formatted Date
 * @param date
 * @param format
 * @returns {string}
 */
export const getFormattedDate = (date, format = "YYYY-MM-DD") => {
  if (moment(date, "YYYY-MM-DD").isValid()) {
    return moment(date).format(format);
  }

  return "";
};

/**
 * Check Is dateTime of Tomorrow
 * @param inputDateTime
 * @returns {boolean}
 */
export const isTomorrow = (inputDateTime) => {
  const tomorrow = moment()
    .add(1, "days")
    .format("YYYY-MM-DD");

  return moment(inputDateTime).isSame(tomorrow, "day");
};

/**
 * Check Is dateTime of Yesterday
 * @param inputDateTime
 * @returns {boolean}
 */
export const isYesterday = (inputDateTime) => {
  const yesterday = moment()
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  return moment(inputDateTime).isSame(yesterday, "day");
};

/**
 * Get Custom Date Time
 * @param value
 * @param unit
 * @param format
 * @returns {string}
 */
export const getCustomDateTime = (
  value = 0,
  unit = "days",
  format = "YYYY-MM-DD"
) => {
  if (value === 0) {
    return moment().format(format);
  } else {
    return moment()
      .add(value, unit)
      .format(format);
  }
};

/**
 * Get Custom Date object
 * @param value
 * @param unit
 * @returns Date object
 */
export const getCustomDateObject = (value = 0, unit = "days") => {
  if (value === 0) {
    return moment();
  } else {
    return moment().add(value, unit);
  }
};

export const getDateText = (date) => {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else if (isTomorrow(date)) {
    return "Tomorrow";
  } else {
    return date;
  }
};

export const getTimeDiffString = (
  date,
  format = "DD MMM, YYYY",
  suffix = ""
) => {
  const postDate = moment(date, "ddd MMM DD YYYY kk:mm:ss Z");
  const currentDate = moment();
  const duration = moment.duration(currentDate.diff(postDate));
  const minutes = duration.asMinutes() | 0;
  const hours = duration.asHours() | 0;

  switch (true) {
    case minutes === 0:
      return "Just now";
    case minutes < 60:
      return `${minutes} min ${suffix}`;
    case hours < 24:
      return `${hours} hours ${suffix}`;
    default:
      return postDate.format(format);
  }
};
export const getYesterday = () => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 2);
  return yesterday;
};

export const isMonth = (date) => {
  return date.getMonth() + 1;
};
export const toDay = () => {
  var day = new Date();
  return day.getDate() + "-" + isMonth(day) + "-" + day.getFullYear();
};
export function giveDateFormat(date) {
  let d = date.split("-");
  if (d[2][0] === "0") {
    d[2] = d[2].replace(d[2], d[2][1]);
  }
  if (d[1][0] === "0") {
    d[1] = d[1].replace(d[1], d[1][1]);
  }

  return d[2] + "-" + d[1] + "-" + d[0];
}

export const getIsBetweenWeek = (date) => {
  let startWeek = moment().startOf("week");
  let endWeek = moment().endOf("week");
  return moment(date).isBetween(startWeek, endWeek);
};

export const isInvalidDate = (date) => {
  return date === "Invalid date";
};

export const isDateBefor = (startDate) => {
  return moment(startDate).isBefore(moment().format());
};

export const isHourBefor = (hour) => {
  return moment(hour).isBefore(moment().format());
};
