import React, { useEffect, useState } from "react";

import { Paper } from "@material-ui/core";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import TableToolbar1 from "../CommonComponents/TableComponent/TableToolbar 1";
import useStyles from "../CommonComponents/Style/TableStyle";
import ConnexionList from "./ConnexionList";
import { useSelector, useDispatch } from "react-redux";
import { loginAs } from "redux/actions/Users";

const hedearToolbar = {
  title: <IntlMessages id="sidebar.connexion.etq" />,
};

const ConnexionAs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { establishments } = useSelector(
    ({ EstablishmentsReducer }) => EstablishmentsReducer
  );
  const { users } = useSelector(({ usersReducer }) => usersReducer);
  const [establishmentsList, setEstablishmentsList] = useState([]);

  useEffect(() => {
    let establishmentsFiltred = [];
    establishments.map((element) => {
      if (element.establishmentType === "complex") {
        establishmentsFiltred.push(element);
      } else if (element.name === element?.parentEstablishment) {
        establishmentsFiltred.push({ ...element, id: element.establishmentId });
      }
      return element;
    });
    setEstablishmentsList(establishmentsFiltred);
  }, [establishments]);

  const handleLoginAs = (userId) => {
    dispatch(loginAs(userId));
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar1 hedearToolbar={hedearToolbar} />
        <ConnexionList
          establishmentsList={establishmentsList}
          users={users}
          handleLoginAs={handleLoginAs}
        />
      </Paper>
    </div>
  );
};

export default ConnexionAs;
