import {
  ADD_LICENSE,
  DELETE_LICENSE,
  EDIT_LICENSE,
  GET_LICENSES,
  SET_LICENSE_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  licenses: [],
  currentLicense: null
};

const Licenses = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LICENSES: {
      return {
        ...state,
        licenses: action.payload
      };
    }
    case SET_LICENSE_DETAILS: {
      return {
        ...state,
        currentLicense: action.payload
      };
    }
    case ADD_LICENSE: {
      return {
        ...state,
        licenses: [action.payload, ...state.licenses]
      };
    }
    case EDIT_LICENSE: {

      return {
        ...state,
        licenses: state.licenses.map(

          LICENSE => {
       
            return (LICENSE.id === action.payload.id
              ? action.payload
              : LICENSE)

          }
        )
      };

    }
    case DELETE_LICENSE: {
      return {
        ...state,
        licenses: state.licenses.filter(
          LICENSE => LICENSE.id !== action.payload
        )
      };
    }

    default:
      return state;
  }
};
export default Licenses