import {
  ADD_VACCINATION,
  DELETE_VACCINATION,
  EDIT_VACCINATION,
  SET_VACCINATION_EDIT,
  GET_VACCINATIONS,
  SET_VACCINATION_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  vaccination: [],
  currentVaccination: null,
  currentVaccinationEdit:null
};

const Vaccinations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VACCINATIONS: {
      return {
        ...state,
        vaccination: action.payload
      };
    }
    case SET_VACCINATION_DETAILS: {
      return {
        ...state,
        currentVaccination: action.payload
      };
    }
    case SET_VACCINATION_EDIT: {
      return {
        ...state,
        currentVaccinationEdit: action.payload
      };
    }
    case ADD_VACCINATION: {

      return {
        ...state,
        vaccination: [ ...action.payload , ...state.vaccination ]
      };
    }
    case EDIT_VACCINATION: {
    
      return {
        ...state,
        vaccination: state.vaccination.map(
          VACCINATION =>
            VACCINATION.id === action.payload.id ? action.payload : VACCINATION
        )
      };
      
    }
    
    case DELETE_VACCINATION: {
      return {
        ...state,
        vaccination: state.vaccination.filter(
          VACCINATION => VACCINATION.id !== action.payload
        )
      };
    }

    default:
      return state;
  }
};
export default Vaccinations