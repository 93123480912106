import {
  GET_DEDLINES_LIST,
  SET_LIST_DETAILS,
} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  filter:"",
  listsFinancier: [
    {
      id: 1,
      studentName: "Emna SLIMI",
      classroom: "3ème B1",
      periode: "Septembre 2022",
      date: "22/10/2022",
      amount: "645",
      status: "Facture payé",
    },
    {
      id: 2,
      studentName: "salma belkhir",
      classroom: "3ème B1",
      periode: "Septembre 2022",
      date: "22/10/2022",
      amount: "645",
      status: "Facture non payé",
    },
  ],
  list: [],
  listsPayment:[

    {
      id: 1,
      lastname: "wifek",
      firstname: "3ème B1",
      paymentDate: "Septembre 2022",
       amount: "645",
       paymentMethod: "Facture payé",
    },
    {
      id: 2,
      lastname: "souha",
      firstname: "3ème B2",
      paymentDate: "Novembre 2022",
       amount: "685",
       paymentMethod: "Facture payé",
    },
  ]
};



const FinancialMonitoring = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEDLINES_LIST: {
      return {
        ...state,
        listsFinancier: [...action.payload],
      };
    }

    case SET_LIST_DETAILS: {
      return {
        ...state,
        list: [action.payload],
      };
    }

    default:
      return state;
  }
};
export default FinancialMonitoring 