import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";
import {
  ADD_HOLIDAY,
  EDIT_HOLIDAY,
  GET_HOLIDAYS,
  SET_HOLIDAY_DETAILS,
} from "@jumbo/constants/ActionTypes";
import IntlMessages from "@jumbo/utils/IntlMessages";
import moment from "moment";

export const getHolidays = (
  establishmentId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`holidays?establishmentId=${establishmentId}`, {
        params: { filterOptions, searchTerm },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_HOLIDAYS,
            payload: data.data.reverse()?.map((element) => {
              let start = moment(element.startDate)
                .set("hour", 6)
                .set("minutes", 0)
                .format();
              let end = moment(element.endDate)
                .set("hour", 23)
                .set("minutes", 0)
                .format();
              return {
                ...element,
                start: new Date(start),
                end: new Date(end),
                vacation: element.name,
              };
            }),
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};

export const setcurrentHoliday = (holiday) => {
  return (dispatch) => {
    dispatch({ type: SET_HOLIDAY_DETAILS, payload: holiday });
  };
};

export const addNewHolidays = (holiday, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("holidays", holiday)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.holiday.add.fetchSuccess" />
            )
          );
          let res = data.data;

          dispatch({
            type: ADD_HOLIDAY,
            payload: {
              ...res,
              start: new Date(
                moment(res.startDate)
                  .set("hour", 6)
                  .set("minutes", 0)
                  .format()
              ),
              end: new Date(
                moment(res.endDate)
                  .set("hour", 23)
                  .set("minutes", 0)
                  .format()
              ),
              vacation: res.name,
            },
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};

export const updateHolidays = (holiday, holidayId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`holidays/${holidayId}`, holiday)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />)
          );
          dispatch({
            type: EDIT_HOLIDAY,
            payload: {
              ...holiday,
              start: new Date(
                moment(holiday.startDate)
                  .set("hour", 6)
                  .set("minutes", 0)
                  .format()
              ),
              end: new Date(
                moment(holiday.endDate)
                  .set("hour", 23)
                  .set("minutes", 0)
                  .format()
              ),
              vacation: holiday.name,
            },
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};
