import {
    ADD_COMPLAINT,
    DELETE_COMPLAINT,
    EDIT_COMPLAINT,
    EDIT_REPLY_COMPLAINT,
    GET_COMPLAINTS,
    SET_COMPLAINT_DETAILS
  } from "../../@jumbo/constants/ActionTypes";
  
  
  const INIT_STATE = {
    complaints: [
      // { role: "PARENT",  name: "Amina", lastName: "SELMI", type:"Cantine",date: "Mardi 05 Mai",hour:"13:00 H" ,status:""},
      // { role: "PARENT",  name: "Amina", lastName: "SELMI", type:"Cantine",date: "Mardi 05 Mai",hour:"13:00 H" ,status:""},
      // { role: "PARENT",  name: "Amina", lastName: "SELMI", type:"Cantine",date: "Mardi 05 Mai",hour:"13:00 H" ,status:""},
  
    ],
    currentComplaint: null
  };
  
 const Complaint = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_COMPLAINTS: {
        return {
          ...state,
          complaints: action.payload
        };
      }
      case SET_COMPLAINT_DETAILS: {
        return {
          ...state,
          currentComplaint: action.payload
        };
      }
      case ADD_COMPLAINT: {
        return {
          ...state,
          complaints: [action.payload, ...state.complaints]
        };
      }
      case EDIT_REPLY_COMPLAINT:{
       
        return {
          ...state,
          complaints: state.complaints.map(
            COMPLAINT =>
              COMPLAINT.id === action.payload.id ? action.payload : COMPLAINT
          )
        };
      }
      case EDIT_COMPLAINT: {
  
        return {
          ...state,
          complaints: state.complaints.map(
            COMPLAINT =>
              COMPLAINT.id === action.payload.id ? action.payload : COMPLAINT
          )
        };
  
      }
  
      case DELETE_COMPLAINT: {
        return {
          ...state,
          complaints: state.complaints.filter(
            COMPLAINT => COMPLAINT.id !== action.payload
          )
        };
      }
  
      default:
        return state;
    }
  };
  export default Complaint