import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import useStyles from "routes/CommonComponents/Style/TableToolbarStyle";

 
const UserTableToolbar = ({ onAdd, hedearToolbar }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Toolbar className={clsx(classes.root)}>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          {hedearToolbar.title}
          {onAdd && (
            <Button
              className={classes.spaceTitleToolbar}
              onClick={() => onAdd(true)}
            >
              {hedearToolbar.action}
            </Button>
          )}
        </Typography>
      </Toolbar>
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  onAdd: PropTypes.func,
};

export default React.memo(UserTableToolbar);
