import { makeStyles } from "@material-ui/core/styles";
import { Fonts } from "../../../@jumbo/constants/ThemeOptions";

const MenuStyle = makeStyles((theme) => ({
  dropDownBg: {
    "& .MuiSelect-icon": {
      color: "#b8b1f3 !important",
    },
  },
  Grid: {
    padding: "3%",
  },
  GridCard: {
    padding: "0% 2% 2% 2%",
  },

  line: {
    boxShadow: "0px 1px 3px #DCDCDC",
    borderTop: "2px solid #e8e8e8",
  },
  month: {
    color: "#B8B1F3",
    font: "bold 40px",
    fontWeight: "bolder",
    opacity: "1",
    textAlign: "left",
    letterSpacing: " 0.54px",
    textShadow: " 3px  #B8B1F3",
  },
  icone: {
    color: "#5B13B4",
    opacity: "1",
    paddingRight: "3%",
    transform: "scale(1.1)",
    width: "27px",
    height: "18px",
  },
  horizontal: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  justifyContent1: {
    justifyContent: "flex-start",
    display: "flex",
  },
  justifyContent2: {
    justifyContent: "flex-end",
    display: "flex",
  },
  arrowLeft: {
    color: "#5B13B4",
    cursor: "pointer",
    marginTop: "-1.5px",
  },
  arrowRight: {
    color: "#5B13B4",
    cursor: "pointer",
    marginTop: "-1px",
  },
  week: {
    color: "#9C9C9C",
    textAlign: "left",
    font: "normal normal normal 16px/21px Futura LT",
    letterSpacing: "0px",
    cursor: "pointer",
  },
  isMonth: {
    color: "#B8B1F3",
    textAlign: "left",
    font: " normal normal bold 18px/26px Futura LT",
    letterSpacing: "0.54px",
  },
  day: {
    textAlign: "center",
    font: "normal normal bold 18px/26px Futura LT",
    letterSpacing: "0.54px",
    color: "#666666",
  },
  date: {
    color: "#9C9C9C",
    textAlign: "center",
    font: " normal normal normal 14px/19px Futura LT",
  },
  card: {
    width: "313px",
    height: "320px",
    margin: "0 auto",
    shadowColor: "#888888",
    background: "rgba(232,231,246, 0.4)",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid 	#DCDCDC",
    borderRadius: "5px",
    color: "#E8E7F6",
    minHeight: "320px",
  },
  media: {
    width: "313px",
    maxHeight: "181px",
    padding: "3.5% 5% 0 5%",
   height: "200px",
  },
  plat: {
    color: "#5B13B4",
    font: "normal normal bold 15px/21px Futura LT",
    letterSpacing: "0.45px",
    textTransform: "uppercase",
    opacity: "1",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    display: "inline-blocks",
    wordBreak: "break-all",
  },
  ingredient: {
    color: "#666666",
    font: "normal normal normal 14px/19px Futura LT",
    letterSpacing: "0.42px",
    wordBreak: "break-all",
    maxWidth: "200px",
    display: "inline-blocks",
  },
  FastfoodSharpIcon: {
    color: "#5B13B4 ",
    opacity: "1",
  },
  justifyContentIngredient: {
    display: "flex",
    justifyContent: "flex-start",
  },
  point: {
    textAlign: "left",
    paddingRight: "20%",
    fontSize: "30px",
    color: "#5B13B4",
    font: " normal normal normal 25px/34px Futura LT",
    letterSpacing: "0.75px",
  },
  paper: {
    position: "absolute",
    maxWidth: "737px",
    maxHeight: "612px",
    background: " #FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "5px",
    padding: theme.spacing(4, 0, 4, 0),
  },

  formControl: {
    width: "89%",
    height: "50px",
    marginLeft: "10%",
    marginBottom: "7%",
  },
  line2: {
    borderTop: "2px solid #e8e8e8",
    marginBottom: "30px",
    marginTop: "20px",
  },

  spaceTitleToolbar: {
    marginLeft: "20px",
    fontSize: 15,
    fontWeight: 300,
    textTransform: "uppercase",
    color: "#5B13B4",
    fontFamily: Fonts.MODLETITLE,
  },
  button: {
    marginLeft: "31px",
    marginTop: "-5%",
    color: "#6C5CE7",
  },
  addIcon: {
    marginLeft: "-15%",
  },
  addPhoto: {
    textTransform: "none",
    marginRight: "3%",
    letterSpacing: "0px",
    textAlign: "left",
    font: "normal normal normal 16px/22px Futura LT",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button1: {
    color: "#B8B1F3",
    backgroundColor: "white",
    marginLeft: "-15%",
    padding: " 2% 7% 2% 7%",
  },
  button2: {
    backgroundColor: "#5B13B4",
    color: "white",
    marginRight: "-10%",
    marginLeft: "10px",
  },
  root: {
    marginRight: "7%",
    marginBottom: "2%",
  },
  header: {
    width: "133px",
    textAlign: "center",
  },
  currentLicence: {
    textAlign: "center",
    display: "flex",
    justifyContent: "end",
    opacity: 0.4,
    fontFamily: Fonts.MODLETITLE,
    alignItems: "center",
  },
  TableCell: {
    width: "27%",
  },
  justifyContent: {
    textAlign: "start",
  },
  navEstablishmenetTitle: {
    fontSize: 14,
    fontFamily: Fonts.SECTIONTITLE,
    fontWeight: 700,
    opacity: 1,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default MenuStyle;
