import { SET_LANG } from "@jumbo/constants/ActionTypes";

 
const initialState = "fr";

function LangReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LANG:
      state = action.payload;
      return state;

    default:
      return state;
  }
}

export default LangReducer;
