import {
    ADD_HOLIDAY,
    DELETE_HOLIDAY,
    EDIT_HOLIDAY,
    GET_HOLIDAYS,
    SET_HOLIDAY_DETAILS
  } from "../../@jumbo/constants/ActionTypes";
  
  const INIT_STATE = {
    holidays: [],
    currentHoliday: null
  };
  
  const Holiday = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_HOLIDAYS: {
        return {
          ...state,
          holidays: action.payload
        };
      }
      case SET_HOLIDAY_DETAILS: {
        return {
          ...state,
          currentHoliday: action.payload
        };
      }
      case ADD_HOLIDAY: {
        return {
          ...state,
          holidays: [ action.payload, ...state.holidays ]
        };
      }
      case EDIT_HOLIDAY: {
        return {
          ...state,
          holidays: state.holidays.map(
            HOLIDAY =>
              HOLIDAY.id === action.payload.id
                ? action.payload
                : HOLIDAY
          )
        };
      }
      case DELETE_HOLIDAY: {
        return {
          ...state,
          holidays: state.holidays.filter(
            HOLIDAY => HOLIDAY.id !== action.payload
          )
        };
      }
  
      default:
        return state;
    }
  };
  export default Holiday