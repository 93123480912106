import { COUNT_DATA, ADD_COUNT } from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  countData: 0,
};

const CountData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COUNT_DATA: {
      return {
        ...state,
        countData: action.payload.itemCount,
      };
    }
    case ADD_COUNT: {
      return {
        ...state,
        countData: state.countData + action.payload,
      };
    }
    default:
      return state;
  }
};
export default CountData 