import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import GridContainer from "../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { requiredMessage } from "../../@jumbo/constants/ErrorMessages";
import PropTypes from "prop-types";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import useStyles from "../CommonComponents/Style/AddStyle";
import AppAutocomplete from "../../@jumbo/components/Common/formElements/AppAutocomplete";
import _ from "lodash";

const ConnexionList = ({
  onCloseDialog,
  establishmentsList,
  users,
  handleLoginAs,
}) => {
  const classes = useStyles();
  const [establishmentName, setEstablishmentName] = useState("");
  const [salList, setSalList] = useState([]);
  const [salItem, setSalItem] = useState("");
  const [errorEstablishmentName, setErrorEstablishmentName] = useState("");
  const [errorSalItem, setErrorSalItem] = useState("");

  const handleSave = () => {
    if (!establishmentName) {
      setErrorEstablishmentName(requiredMessage);
    } else if (!salItem) {
      setErrorSalItem(requiredMessage);
    } else {
      handleLoginAs(salItem?.id);
    }
  };

  return (
    <Paper className={classes.paper}>
      {/* 1er ligne */}

      <div style={{ marginTop: "10%" }}>
        <GridContainer className="mt-2">
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={5}>
            <AppAutocomplete
              fullWidth
              required
              id="estblishmentName"
              name="estblishmentName"
              options={establishmentsList}
              value={establishmentName}
              getOptionLabel={(option) => option?.name}
              label={<IntlMessages id="appModule.establishment.parent.name" />}
              onChange={(e, item) => {
                item !== null
                  ? setEstablishmentName(item)
                  : setEstablishmentName("");
                setSalItem("");
                let salFiltred = users.filter(
                  (elm) => elm?.establishment?.id === item?.id
                );
                setSalList(salFiltred);
                setErrorEstablishmentName("");
              }}
              variant="outlined"
              helperText={errorEstablishmentName}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <AppAutocomplete
              fullWidth
              required
              id="name.operateur"
              name="name.operateur"
              options={salList}
              value={salItem}
              getOptionLabel={(option) =>
                !_.isEmpty(option) && option?.lastName + " " + option?.firstName
              }
              label={<IntlMessages id="sag.accounts" />}
              onChange={(e, item) => {
                item !== null ? setSalItem(item) : setSalItem("");
                setErrorSalItem("");
              }}
              variant="outlined"
              helperText={errorSalItem}
            />
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
        </GridContainer>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <GridContainer>
          <Grid item xs={12} sm={10}>
            <Box display="flex" justifyContent="flex-end">
          
              <Box ml={2}>
                <Button
                  variant="contained"
                  className={classes.saveButton}
                  onClick={handleSave}
                >
                  {<IntlMessages id="sidebar.appModule.login" />}
                </Button>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <GridContainer>
          <Grid item xs={12} sm={8}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Grid>
          <Grid item xs={12} sm={8}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Grid>
        </GridContainer>
      </div>
    </Paper>
  );
};

export default ConnexionList;

ConnexionList.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
