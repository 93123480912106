import { DASHBOARD_STATISTIC, GET_WEATHER } from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  weatherData: [],
  dashboardStatistics: []
};
const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_WEATHER: {
        return {
            ...state,
            weatherData: action.payload,
        }
      }
      case DASHBOARD_STATISTIC: {
        return {
            ...state,
            dashboardStatistics: action.payload,
        }
      }
      default:
        return state;
    }
  };
export default Dashboard 