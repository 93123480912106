import {
  ADD_ASSIGNMENTSERVICES,
  DELETE_ASSIGNMENTSERVICES,
  EDIT_ASSIGNMENTSERVICES,

  GET_ASSIGNMENTSERVICES,
  SET_ASSIGNMENTSERVICES_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {

  assignmentServices: [],
  currentAssignmentService: null
};

const AssignmentServices  = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ASSIGNMENTSERVICES: {
      return {
        ...state,
        assignmentServices: action.payload
      };
    }
    case SET_ASSIGNMENTSERVICES_DETAILS: {
      return {
        ...state,
        currentAssignmentService: action.payload
      };
    }
    case ADD_ASSIGNMENTSERVICES: {
      return {
        ...state,
        assignmentServices: [ action.payload, ...state.assignmentServices ]
      };
    }
    case EDIT_ASSIGNMENTSERVICES: {
      return {
        ...state,
        assignmentServices: state.assignmentServices.map(
          AssignmentService =>
          AssignmentService.student?.id ===  action.payload.student?.id ? action.payload : AssignmentService
  
        )
      };
    
    }
    case DELETE_ASSIGNMENTSERVICES: {
      return {
        ...state,
        assignmentServices: state.assignmentServices.filter(
          assignmentService => assignmentService?.student?.id !== action.payload
        )
      };
    }
  

    default:
      return state;
  }
};
export default AssignmentServices 