import React, { useState } from "react";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@material-ui/core";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import useStyles from "../../CommonComponents/Style/TypoModelStyle";
 import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function BankInformation({
  disabled,
  establishmentValidation,
  establishmentData,
  setEstablishmentData,
  setEstablishmentValidation,listBanks
}) {
  const classes = useStyles();
  const [showInputBank, setInputBank] = useState("");
  const [bankNameExist, setBankNameExist] = useState();

  useEffect(() => {
    setBankNameExist(listBanks.indexOf(establishmentData.bankName));
  }, [establishmentData.bankName,listBanks]);

  const currentEstablishmentAction = useSelector(({EstablishmentsReducer}) => EstablishmentsReducer.currentEstablishmentAction);

  return (
    <>
      <h5 className={classes.sectionTitle}>
        <CreditCardIcon className={classes.iconSectionTitle} />

        <IntlMessages id="appModule.information.bank.data" />
      </h5>
      <GridContainer>
        <Grid item xs={12} sm={3}>
          <AppSelectBox
            fullWidth
            data={listBanks}
            disabled={disabled}
            label={<IntlMessages id="appModule.information.bank" />}
            valueKey="slug"
            value={
              showInputBank !== "" ? showInputBank : establishmentData.bankName
            }
            id="bankName"
            variant="outlined"
            required
            icons={{ color: "red" }}
            labelKey="title"
            onChange={(e) => {
              if (e.target.value === "autre") {
                setInputBank(e.target.value);
                setEstablishmentData({
                  ...establishmentData,
                  bankName: '',
                });
              } else {
                setEstablishmentData({
                  ...establishmentData,
                  bankName: e.target.value,
                });
                setEstablishmentValidation({
                  ...establishmentValidation,
                  bankName: undefined,
                });
              }
            }}
            helperText={establishmentValidation.bankName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppTextInput
            fullWidth
            variant="outlined"
            required
            disabled={disabled}
            label={<IntlMessages id="appModule.information.bank.rib" />}
            value={establishmentData.rib}
            id="rib"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                rib: e.target.value,
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                rib: undefined,
              });
            }}
            inputProps={{
              muinLength: 14,
              maxLength: 35,
            }}
            helperText={establishmentValidation.rib}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <AppTextInput
            fullWidth
            variant="outlined"
            required
            disabled={disabled}
            label={<IntlMessages id="appModule.information.bank.bic" />}
            value={establishmentData.bic}
            id="bic"
            helperText={establishmentValidation.bic}
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                bic: e.target.value,
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                bic: undefined,
              });
            }}
          />
        </Grid>
        {((showInputBank !== "" && bankNameExist === -1) || currentEstablishmentAction !== "") && (
          <Grid item xs={12} sm={12}>
            <AppTextInput
              fullWidth
              variant="outlined"
              required
              disabled={currentEstablishmentAction === "view"}
              label={<IntlMessages id="appModule.information.bank" />}
              value={establishmentData.bankName}
              id="bankName"
              helperText={establishmentValidation.bankName}
              onChange={(e) => {
                setEstablishmentData({
                  ...establishmentData,
                  bankName: e.target.value,
                });
                setEstablishmentValidation({
                  ...establishmentValidation,
                  bankName: undefined,
                });
              }}
            />
          </Grid>
        )}
      </GridContainer>
    </>
  );
}
