import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Edit, MoreHoriz, Visibility } from "@material-ui/icons";
import CmtDropdownMenu from "../../../@coremat/CmtDropdownMenu";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import useStyles from "../../CommonComponents/Style/TypoModelStyle";
import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import CmtAvatar from "../../../@coremat/CmtAvatar";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.purple,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const EstablishmentListRow = ({
  row,
  onRowClick,
  onEstablishmentEdit,
  onEstablishmentView,
}) => {
  const classes = useStyles();
  const onEstablishmentMenuClick = (menu) => {
    if (menu.action === "view") {
      onEstablishmentView(row, menu.action);
    } else if (menu.action === "edit") {
      onEstablishmentEdit(row, menu.action);
    }
  };

  const getEstablishmentActions = (establishment) => {
    const actions = [
      {
        action: "view",
        label: <IntlMessages id="appModule.action.view" />,
        icon: <Visibility className={classes.iconActionRoot} />,
      },
      {
        action: "edit",
        label: <IntlMessages id="button.modify" />,
        icon: <Edit className={classes.iconActionRoot} />,
      },
    ];
    return actions;
  };
  const labelId = `enhanced-table-checkbox-${row.id}`;

  const establishmentAction = getEstablishmentActions(row);

  return (
    <StyledTableRow
      hover
      onClick={(event) => onRowClick(event, row.id)}
      tabIndex={-1}
      key={row.id}
    >
      {/* <StyledTableCell padding="checkbox" /> */}
      <StyledTableCell component="th" id={labelId} scope="row">
        <Box>
          <CmtAvatar size={40} src={row.logo} alt={row.name} />
        </Box>
      </StyledTableCell>
      <StyledTableCell
        className={classes.dataList}
        component="th"
        id={labelId}
        scope="row"
      >
        {row.name}
      </StyledTableCell>
      <StyledTableCell className={classes.dataList}>
        {row.establishmentType === "complex" ? "Complexe" : "Simple"}
      </StyledTableCell>
      <StyledTableCell className={classes.dataList}>
        {row.parentEstablishment}
      </StyledTableCell>

      <StyledTableCell
        align="center"
        onClick={(event) => event.stopPropagation()}
        className={classes.iconActionRoot}
      >
        <CmtDropdownMenu
          items={establishmentAction}
          onItemClick={onEstablishmentMenuClick}
          triggerComponent={<MoreHoriz />}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default React.memo(EstablishmentListRow);
