import React from "react";
import { crudApp } from "../../services/crudApp";
import {
  DATA_LOADED_SCHOOLYEAR,
  DATA_LOADED_MODULE,
  UPDATE_PHOTO,
  UPDATE_PROFIL,
  ACCESS_DATA,
} from "../../@jumbo/constants/ActionTypes";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
  emailNotValid,
  phoneInvalid,
  requiredEmail,
  requiredPhone,
} from "@jumbo/constants/ErrorMessages";
import { isValidEmail, isValidphoneNumber } from "@jumbo/utils/commonHelper";

export const getSchoolYear = () => {
  return (dispatch) => {
    crudApp.get(`school-years`).then((response) => {
      if (response) {
        dispatch({ type: DATA_LOADED_SCHOOLYEAR, payload: response.data });
      }
    });
  };
};

export const getModule = () => {
  return (dispatch) => {
    crudApp.get(`modules`).then((response) => {
      if (response) {
        dispatch({ type: DATA_LOADED_MODULE, payload: response.data });
      }
    });
  };
};

export const updateProfilPhoto = (userProfile, file, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`users/photo`, file)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          let newData = {  photo: data.data.photo };
          dispatch({
            type: UPDATE_PHOTO,
            payload: newData,
          });
          if (callbackFun) callbackFun(newData);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};
export const updateProfil = (userProfile) => {
  return (dispatch) => {
    if (!userProfile.email) dispatch(fetchError(requiredEmail));
    else if (!isValidEmail(userProfile.email)) {
      dispatch(fetchError(emailNotValid));
    } else if (!userProfile.phone) {
      dispatch(fetchError(requiredPhone));
    } else if (!isValidphoneNumber(userProfile.phone))
      dispatch(fetchError(phoneInvalid));
    else {
      dispatch(fetchStart());
      crudApp
        .patch(`users/update-profile`, userProfile)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            dispatch({ type: ACCESS_DATA, payload: false });
            dispatch(
              fetchSuccess(
                <IntlMessages id="app.module.edit.infos.fetch.success" />
              )
            );
            dispatch({
              type: UPDATE_PROFIL,
              payload: res.data,
            });
          } else {
            dispatch(
              fetchError(
                <IntlMessages id="app.module.get.problem.fetchError" />
              )
            );
          }
        })
        .catch((error) => {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        });
    }
  };
};
