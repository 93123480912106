import {
  ADD_HARASSEMENT,
  EDIT_HARASSEMENT,
  GET_HARASSEMENT,
  EDIT_REPLY_HARASSEMENT,
  SET_HARASSEMENT_DETAILS
} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  harassement: [ ],
  currentHarassement: null,
};
const Harassement =  (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HARASSEMENT: {
      return {
        ...state,
        harassement: action.payload
      }
    }
    case SET_HARASSEMENT_DETAILS: {
      return {
        ...state,
        currentHarassement: action.payload
      }
    }
    case ADD_HARASSEMENT: {
      return {
        ...state,
        harassement: [action.payload, ...state.harassement]
      }
    }
    case EDIT_REPLY_HARASSEMENT:{
       
      return {
        ...state,
        harassement: state.harassement.map(
          HARASSEMENT =>
            HARASSEMENT.id === action.payload.id ? action.payload : HARASSEMENT
        )
      };
    }
    case EDIT_HARASSEMENT: {
      return {
        ...state,
        harassement: state.currentHarassement.map(
          item => (item.id === action.payload.id ? action.payload : item)
        )
      };
    }
    default:
      return state;
  }
}
export default Harassement