import { CONNECT_TO_CANAL } from "@jumbo/constants/ActionTypes";
import { socketIOURL } from "Config";
import { io } from "socket.io-client";

export const connectToCanal = (token) => (dispatch) => {
  const socket = io(`${socketIOURL}?token=${token}`, {
    transports: ["websocket"],
  });

  socket.connect();

  dispatch({
    type: CONNECT_TO_CANAL,
    payload: socket,
  });
};
