import { ADD_NOTIFICATION, EDIT_NOTIFICATION, GET_NOTIFICATION } from "@jumbo/constants/ActionTypes.js";

const INIT_STATE = {
  notifications: [],
};

const NotificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION: {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    case EDIT_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    }
    case ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    }
    default:
      return state;
  }
};
export default NotificationReducer