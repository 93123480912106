import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import axios from "axios";
import {
  ADD_SUBJECTMODULE,
  DELETE_SUBJECTMODULE,
  EDIT_SUBJECTMODULE,
  GET_SUBJECTMODULES,
  SET_SUBJECTMODULE_DETAILS,
} from "../../@jumbo/constants/ActionTypes";
import { crudApp } from "../../services/crudApp";
import IntlMessages from "@jumbo/utils/IntlMessages";

export const getSubjectModules = (
  subEstablishmentId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`subject-modules?subEstablishmentId=${subEstablishmentId}`, {
        params: { filterOptions, searchTerm },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SUBJECTMODULES, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
      });
  };
};

export const setCurrentSubjectModule = (subjectModule) => {
  return (dispatch) => {
    dispatch({ type: SET_SUBJECTMODULE_DETAILS, payload: subjectModule });
  };
};

export const addNewSubjectModule = (subjectModule, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("subject-modules", subjectModule)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess(<IntlMessages id= "app.module.subjectModule.add.fetchsuccess"/>));
          dispatch({ type: ADD_SUBJECTMODULE, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
      });
  };
};

export const sentMailToSubjectModule = () => {
  return (dispatch) => {
    dispatch(fetchSuccess(<IntlMessages id = "app.module.subjectModule.sentEmail.fetchSuccess"/>));
  };
};

export const updateSubjectModule = (
  subjectModule,
  subjectModuleId,
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`subject-modules/${subjectModuleId}`, subjectModule)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess(<IntlMessages id = "appModule.edit.msg.success"/>));
          dispatch({ type: EDIT_SUBJECTMODULE, payload: subjectModule });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
      });
  };
};

export const updateSubjectModuleStatus = (data, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .put("/subjectModules/update-status", data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            fetchSuccess("subjectModule status was updated successfully.")
          );
          dispatch({ type: EDIT_SUBJECTMODULE, payload: response.data });
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
      });
  };
};

export const deleteSubjectModule = (subjectModuleId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart);
    crudApp
      .remove(`subject-modules/${subjectModuleId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(<IntlMessages id="appModule.subjectModule.delete" />)
          );
          dispatch({ type: DELETE_SUBJECTMODULE, payload: subjectModuleId });
          if (callbackFun) callbackFun();
        } else if (data.status === 409) {
          dispatch(
            fetchError(
              <IntlMessages id="appModule.subjectModule.delete.error.affectation" />
            )
          );
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>, error));
      });
  };
};
