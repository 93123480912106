import {
  ADD_SERVICESMANAGEMENT,
  DELETE_SERVICESMANAGEMENT,
  EDIT_SERVICESMANAGEMENT,

  GET_SERVICESMANAGEMENTS,
  SET_SERVICESMANAGEMENT_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {

  servicesManagements: [],
  currentServicesManagement: null
};

const ServicesManagements = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICESMANAGEMENTS: {
      return {
        ...state,
        servicesManagements: action.payload
      };
    }
    case SET_SERVICESMANAGEMENT_DETAILS: {
      return {
        ...state,
        currentServicesManagement: action.payload
      };
    }
    case ADD_SERVICESMANAGEMENT: {
      return {
        ...state,
        servicesManagements: [ action.payload, ...state.servicesManagements ]
      };
    }
    case EDIT_SERVICESMANAGEMENT: {
    
      return {
        ...state,
        servicesManagements: state.servicesManagements.map(
          servicesManagement =>
          servicesManagement.id === action.payload.id ? action.payload : servicesManagement
        )
      };
      
    }
    
    case DELETE_SERVICESMANAGEMENT: {
      return {
        ...state,
        servicesManagements: state.servicesManagements.filter(
          servicesManagement => servicesManagement.id !== action.payload
        )
      };
    }

    default:
      return state;
  }
};
export default ServicesManagements