import {
  COMPOSE_MAIL,
  GET_LABELS_LIST,
  GET_MAIL_COUNTS,
  GET_MAILS_LIST,
  GET_SELECTED_MAIL,
  NULLIFY_SELECTED_MAIL,
  REPLY_TO_MAIL,
  SET_FILTER_TYPE,
  TOGGLE_SIDEBAR_COLLAPSED,
  UPDATE_FAVORITE_STATUS,
  UPDATE_IMPORTANT_STATUS,
  UPDATE_MAIL_FOLDER,
  UPDATE_MAIL_LABEL,
  UPDATE_READ_STATUS,
  UPDATE_SELECTED_MAIL,
  GET_ETAB_EMAILS,
  SET_FILTREING_ROLE_ID,
  GET_USER_EMAILS,
  FAVORIT_MAIL,
  IMPORTANT_MAILS,
  DELETED_MAILS,
  MARK_AS_READ,
  LIST_SAL,
  OPERATOR_LIST,
  ADL_LIST,
  LIST_PROF,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  isSideBarCollapsed: false,
  connectionsList: [],
  mailsList: [],
  filterType: {
    selectedFolder: "inbox",
    selectedFilter: "",
    selectedLabel: "",
    searchText: "",
    page: 0,
    selectedRoleId: null,
  },
  selectedMail: null,
  counter: null,
  totalMailCount: null,
  mailsUserList: null,
  // mailsUserListSent: null,
  // mailsUserListReceived: null,
  paginationMeta: null,
  totalInbox: null,
  totalMailuserCount: null,
  totalMailUserCount: null,
  totalMailUserCurrentPage: null,
  totalMailUserPages: null,
  etabEmailsList: null,
  favoritEmailsList: [],
  importantEmailsList: [],
  deletedMails: [],
  listSAL: [],
  listADL: [],
  listOperators: [],
  listProf: [],
  sentMail: [],
};

const MailApp = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSideBarCollapsed: action.payload
          ? action.payload
          : !state.isSideBarCollapsed,
      };
    }

    case GET_MAIL_COUNTS: {
      return {
        ...state,
        counter: action.payload,
      };
    }

    case SET_FILTER_TYPE: {
      return {
        ...state,
        filterType: action.payload,
        selectedMail: null,
      };
    }

    case GET_LABELS_LIST: {
      return { ...state, labelsList: action.payload };
    }

    case GET_MAILS_LIST: {
      return {
        ...state,
        mailsList: action.payload.list,
        paginationMeta: action.payload?.meta,
        totalMailCount: action.payload.total,
        selectedMail: null,
      };
    }

    case GET_USER_EMAILS: {
      return {
        ...state,
        mailsUserList: action.payload?.data,
        paginationMeta: action.payload?.meta,
        totalInbox: action.payload.meta.itemCount,
        totalMailUserCount: action.payload.meta.totalItems,
        totalMailUserCurrentPage: action.payload.meta.currentPage,
        totalMailUserPages: action.payload.meta.totalPages,
        favoritEmailsList: [],
      };
    }

    case GET_ETAB_EMAILS: {
      return {
        ...state,
        etabEmailsList: action.payload?.email,
      };
    }

    case UPDATE_MAIL_FOLDER: {
      const updatedList = state.mailsList.filter(
        (mail) => !action.payload.includes(mail.id)
      );
      return {
        ...state,
        mailsList: updatedList,
        totalMailCount: state.totalMailCount - action.payload.length,
      };
    }

    case UPDATE_MAIL_LABEL: {
      let mailIds = action.payload.map((mail) => mail.id);
      const updatedList = state.mailsList.map((mail) => {
        if (mailIds.includes(mail.id)) {
          return action.payload.find(
            (selectedMail) => selectedMail.id === mail.id
          );
        } else {
          return mail;
        }
      });
      return {
        ...state,
        mailsList: updatedList,
      };
    }

    case UPDATE_FAVORITE_STATUS: {
      const { mailIds, status } = action.payload;
      const updatedList = state.mailsList.map((mail) => {
        if (mailIds.includes(mail.id)) {
          mail.favorite = status;
          return mail;
        }
        return mail;
      });
      return {
        ...state,
        mailsList: updatedList,
      };
    }

    case UPDATE_READ_STATUS: {
      const { mailIds, status } = action.payload;
      const updatedList = state.mailsList.map((mail) => {
        if (mailIds.includes(mail.id)) {
          mail.read = status;
          return mail;
        }
        return mail;
      });
      return {
        ...state,
        mailsList: updatedList,
      };
    }

    case UPDATE_IMPORTANT_STATUS: {
      const { mailIds, status } = action.payload;
      const updatedList = state.mailsList.map((mail) => {
        if (mailIds.includes(mail.id)) {
          mail.important = status;
          return mail;
        }
        return mail;
      });
      return {
        ...state,
        mailsList: updatedList,
      };
    }

    case COMPOSE_MAIL: {
      if (state.filterType.selectedFolder === "sent") {
        return {
          ...state,
          sentMail: action?.payload,
          mailsUserList: [action?.payload, ...state.mailsUserList],
        };
      } else {
        return {
          ...state,
          sentMail: action?.payload,
        };
      }
    }

    case GET_SELECTED_MAIL: {
      return {
        ...state,
        selectedMail: action.payload,
      };
    }

    case NULLIFY_SELECTED_MAIL: {
      return {
        ...state,
        selectedMail: null,
      };
    }

    case UPDATE_SELECTED_MAIL: {
      let updatedMail =
        state.selectedMail.folder === action.payload.folder
          ? action.payload
          : null;
      return {
        ...state,
        selectedMail: updatedMail,
      };
    }

    case REPLY_TO_MAIL: {
      return {
        ...state,
        selectedMail: {
          ...state.selectedMail,
          mailFullDetails: {
            ...state.selectedMail.mailFullDetails,
            BoxMails: action?.payload.BoxMails,
            paramMail: {
              ...state.selectedMail.mailFullDetails.paramMail,
              as_read: true,
            },
          },
        },
      };
    }

    case SET_FILTREING_ROLE_ID: {
      return {
        ...state,
        selectedRoleId: action.payload,
      };
    }

    case FAVORIT_MAIL: {
      let paramMails = action.payload.map((element) => element?.paramMail);
      let mailList = [];
      paramMails.map(
        (param) =>
          (mailList = state.mailsUserList.map((element) =>
            element.paramMail.id === param.id
              ? {
                  ...element,
                  paramMail: {
                    ...element.paramMail,
                    favorite: param.favorite,
                    important: param.important,
                  },
                }
              : element
          ))
      );

      let unFav = [];
      if (!action.body.favorite && state.filterType.selectedFilter === "favoris") {
        paramMails.map(
          (param) =>
            (unFav = state.mailsUserList.filter(
              (mail) => mail.paramMail.id !== param.id
            ))
        );
        return {
          ...state,
          mailsUserList: unFav,
        };
      }

      if (state.selectedMail !== null && action.body.favorite)
        return {
          ...state,
          mailsUserList: mailList,
          selectedMail: {
            ...state.selectedMail,
            mailFullDetails: action.payload[0],
          },
        };
      else
        return {
          ...state,
          mailsUserList: mailList,
        };
    }
    case IMPORTANT_MAILS: {
      let paramMails = action.payload.map((element) => element?.paramMail);
      let mailList = [];
      paramMails.map(
        (param) =>
          (mailList = state.mailsUserList.map((element) =>
            element.paramMail.id === param.id
              ? {
                  ...element,
                  paramMail: {
                    ...element.paramMail,
                    favorite: param.favorite,
                    important: param.important,
                  },
                }
              : element
          ))
      );

      let unFav = [];
      if (!action.body.important && state.filterType.selectedFilter === "important") {
        paramMails.map(
          (param) =>
            (unFav = state.mailsUserList.filter(
              (mail) => mail.paramMail.id !== param.id
            ))
        );
        return {
          ...state,
          mailsUserList: unFav,
        };
      }

      if (state.selectedMail !== null && action.body.important)
        return {
          ...state,
          mailsUserList: mailList,
          selectedMail: {
            ...state.selectedMail,
            mailFullDetails: action.payload[0],
          },
        };
      else
        return {
          ...state,
          mailsUserList: mailList,
        };
    }

    case DELETED_MAILS: {
      let deletedMailId = action?.payload.map(
        (deleted) => deleted?.paramMail?.id
      );

      let mails = state?.mailsUserList?.filter(
        (element) => !deletedMailId?.includes(element?.paramMail?.id)
      );

      return {
        ...state,
        deletedMails: action?.payload,
        mailsUserList: mails,
      };
    }

    case MARK_AS_READ: {
      state.mailsUserList.forEach((row) => {
        action.payload.body.param_id.forEach((read) => {
          if (row?.paramMail?.id === read) {
            row.paramMail.as_read = true;
          }
        });
      });

      return {
        ...state,
        // deletedMailList: action?.payload,
      };
    }

    case LIST_SAL: {
      return {
        ...state,
        listSAL: action?.payload,
      };
    }

    case OPERATOR_LIST: {
      return {
        ...state,
        listOperators: action?.payload,
      };
    }

    case ADL_LIST: {
      return {
        ...state,
        listADL: action?.payload,
      };
    }
    case LIST_PROF: {
      return {
        ...state,
        listProf: action?.payload,
      };
    }

    default:
      return state;
  }
};
export default MailApp;
