import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";

import {
  ADD_SUBJECT,
  EDIT_SUBJECT,
  GET_SUBJECTS,
  SET_SUBJECT_DETAILS,
  DELETE_SUBJECT,
} from "../../@jumbo/constants/ActionTypes";
import IntlMessages from "@jumbo/utils/IntlMessages";

export const getSubjects = (
  subEstablishmentId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`subjects?subEstablishmentId=${subEstablishmentId}`, {
        params: { filterOptions, searchTerm },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SUBJECTS, payload: data.data.reverse() });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
      });
  };
};

export const setCurrentSubject = (license) => {
  return (dispatch) => {
    dispatch({ type: SET_SUBJECT_DETAILS, payload: license });
  };
};

export const addNewSubject = (license, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("subjects", license)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(<IntlMessages id= "app.module.subject.addNewSubject.fetchSuccess"/>)
          );
          dispatch({ type: ADD_SUBJECT, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
      });
  };
};

export const updateSubject = (subject, subjectId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp.put(`subjects/${subjectId}`, subject).then((data) => {
      if (data.status === 200) {
        dispatch(fetchSuccess(<IntlMessages id = "appModule.edit.msg.success"/>));
        dispatch({ type: EDIT_SUBJECT, payload: data.data });
        if (callbackFun) callbackFun(subject);
      } else {
        dispatch(fetchError(<IntlMessages id= "app.module.get.problem.fetchError"/>));
      }
    });
  };
};

export const deleteSubject = (subjectId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart);
    crudApp
      .deleteEndpoint(`subjects/${subjectId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(<IntlMessages id="appModule.subject.delete" />)
          );
          dispatch({ type: DELETE_SUBJECT, payload: subjectId });
          if (callbackFun) callbackFun();
        }  else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>,error));
      });
  };
};

