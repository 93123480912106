import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";
import {
  ADD_VACATION,
  EDIT_VACATION,
  GET_VACATIONS,
  SET_VACATION_DETAILS,
} from "../../@jumbo/constants/ActionTypes";

import { isOrderByDesc } from "@jumbo/utils/commonHelper";
import IntlMessages from "@jumbo/utils/IntlMessages";
import moment from "moment";

export const getVacations = (
  subEstablishmentId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`school-vacations?subEstablishmentId=${subEstablishmentId}`, {
        params: { filterOptions, searchTerm },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_VACATIONS,
            payload: isOrderByDesc(data.data, "id")?.map((element) => {
              let start = moment(element.startDate)
                .set("hour", 6)
                .set("minutes", 0)
                .format();
              let end = moment(element.endDate)
                .set("hour", 23)
                .set("minutes", 0)
                .format();
              return {
                ...element,
                start: new Date(start),
                end: new Date(end),
                vacation: element.vacationName,
              };
            }),
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};

export const setCurrentVacation = (vacation) => {
  return (dispatch) => {
    dispatch({ type: SET_VACATION_DETAILS, payload: vacation });
  };
};

export const addNewVacation = (vacation, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("school-vacations", vacation)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.vacation.add.fetchSuccess" />
            )
          );
          let res = data.data;

          dispatch({
            type: ADD_VACATION,
            payload: {
              ...res,
              start: new Date(
                moment(res.startDate)
                  .set("hour", 6)
                  .set("minutes", 0)
                  .format()
              ),
              end: new Date(
                moment(res.endDate)
                  .set("hour", 23)
                  .set("minutes", 0)
                  .format()
              ),
              vacation: res.vacationName,
            },
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};

export const updateVacation = (vacation, vacationId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`school-vacations/${vacationId}`, vacation)
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />)
          );
          let res = data.data;
          dispatch({
            type: EDIT_VACATION,
            payload: {
              ...res,
              start: new Date(
                moment(res.startDate)
                  .set("hour", 6)
                  .set("minutes", 0)
                  .format()
              ),
              end: new Date(
                moment(res.endDate)
                  .set("hour", 23)
                  .set("minutes", 0)
                  .format()
              ),
              vacation: res.vacationName,
            },
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};
