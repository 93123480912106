import {
  ADD_GROUP_CLASS,
  DELETE_GROUP_CLASS,
  EDIT_GROUP_CLASS,
  GET_GROUP_CLASS,
  SET_GROUP_CLASS_DETAILS,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  group: [],
  currentGroup: null,
};
const Group = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GROUP_CLASS: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case SET_GROUP_CLASS_DETAILS: {
      return {
        ...state,
        currentGroup: action.payload,
      };
    }
    case ADD_GROUP_CLASS: {
      return {
        ...state,
        group: [...action.payload, ...state.group],
      };
    }
    case EDIT_GROUP_CLASS: {
      return {
        ...state,
        group: state.group.map((group) =>
          group.id === action.payload.id ? action.payload : group
        ),
      };
    }

    case DELETE_GROUP_CLASS: {
      return {
        ...state,
        group: state.group.filter(
          (GROUP_CLASS) => GROUP_CLASS.id !== action.payload
        ),
      };
    }

    default:
      return state;
  }
};
export default Group;
