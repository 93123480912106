import React, { useState } from "react";
import PersonIcon from "@material-ui/icons/Person";
import IntlMessages from "@jumbo/utils/IntlMessages";
import useStyles from "../../CommonComponents/Style/TypoModelStyle";
import { Box, Grid } from "@material-ui/core";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import GridContainer from "@jumbo/components/GridContainer";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import { Countries } from "Config/countriesList";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidphoneNumber } from "@jumbo/utils/commonHelper";
import { phoneInvalid } from "@jumbo/constants/ErrorMessages";

export default function GeneralInformation({
  getRootProps,
  getInputProps,
  logoUrl,
  disabled,
  listCity,
  setListCity,
  establishmentData,
  establishmentValidation,
  setEstablishmentData,
  setEstablishmentValidation,
  setListBanks,
  setLegalInformation,
  setCountry,
  country,
}) {
  const classes = useStyles();
  const [phoneValidation, setPhoneNumberValidation] = useState(true);

  return (
    <>
      <h5 className={classes.sectionTitle}>
        <PersonIcon className={classes.iconSectionTitle} />

        <IntlMessages id="appModule.information.general" />
      </h5>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="center"
        mb={{ xs: 6, md: 5 }}
      >
        <Box
          {...getRootProps()}
          mr={{ xs: 0, md: 5 }}
          mb={{ xs: 3, md: 0 }}
          className="pointer"
        >
          <input
            aria-label=""
            {...getInputProps()}
            id="add-photo"
            disabled={disabled}
          />

          <CmtAvatar
            size={70}
            src={logoUrl}
            style={{ color: "white", backgroundColor: "#b8b1f3" }}
          />
        </Box>
        <GridContainer>
          <Grid item xs={12} sm={12}>
            <AppTextInput
              className={classes.inputBackground}
              fullWidth
              variant="outlined"
              required
              disabled={disabled}
              label={<IntlMessages id="appModule.establishment.name" />}
              value={establishmentData.name}
              id="establishmentName"
              helperText={establishmentValidation.name}
              onChange={(e) => {
                setEstablishmentData({
                  ...establishmentData,
                  name:
                    e.target.value
                      .trim()
                      .charAt(0)
                      .toUpperCase() + e.target.value.substring(1),
                });
                setEstablishmentValidation({
                  ...establishmentValidation,
                  name: undefined,
                });
              }}
            />
          </Grid>
        </GridContainer>
      </Box>
      {/*--------------------------------- ligne 1  ------------------*/}
      <GridContainer>
        <Grid item xs={12} sm={4}>
          <AppSelectBox
            data={Countries}
            fullWidth
            required
            disabled={disabled}
            variant="outlined"
            valueKey="slug"
            label={<IntlMessages id="app.Module.information.country" />}
            id="countries"
            value={establishmentData.country}
            labelKey="slug"
            onChange={(e) => {
              setListCity(
                Countries.find((ele) => ele.slug === e.target.value)
                  ?.citylist ?? []
              );
              setListBanks(
                Countries.find((ele) => ele.slug === e.target.value)?.banks ??
                  []
              );
              setLegalInformation(
                Countries.find((ele) => ele.slug === e.target.value)
                  ?.legalInformation
              );
              setCountry(
                Countries.find((ele) => ele.slug === e.target.value)?.country
              );
              setEstablishmentData({
                ...establishmentData,
                country: e.target.value,
                phone: "",
                bankName: "",
                city: "",
              });
              setPhoneNumberValidation(true);
              setEstablishmentValidation({
                ...establishmentValidation,
                country: undefined,
              });
            }}
            icons={{ color: "red" }}
            helperText={establishmentValidation.country}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <AppSelectBox
            fullWidth
            required
            disabled={disabled}
            data={listCity}
            label={<IntlMessages id="app.Module.information.city" />}
            valueKey="slug"
            value={establishmentData.city}
            id="city"
            variant="outlined"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                city: e.target.value,
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                city: undefined,
              });
            }}
            icons={{ color: "red" }}
            labelKey="slug"
            helperText={establishmentValidation.city}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AppTextInput
            fullWidth
            disabled={disabled}
            variant="outlined"
            required
            label={<IntlMessages id="app.Module.information.delegation" />}
            value={establishmentData.delegation}
            id="delegation"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                delegation:
                  e.target.value
                    .trim()
                    .charAt(0)
                    .toUpperCase() + e.target.value.substring(1),
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                delegation: undefined,
              });
            }}
            helperText={establishmentValidation.delegation}
          />
        </Grid>
      </GridContainer>
      {/*--------------------------------- ligne 2  ------------------*/}
      <GridContainer>
        <Grid item xs={12} sm={4}>
          <AppTextInput
            fullWidth
            disabled={disabled}
            variant="outlined"
            required
            label={<IntlMessages id="appModule.information.street.number" />}
            value={establishmentData.roadNumber}
            id="roadNumber"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                roadNumber:
                  e.target.value
                    .trim()
                    .charAt(0)
                    .toUpperCase() + e.target.value.substring(1),
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                roadNumber: undefined,
              });
            }}
            helperText={establishmentValidation.roadNumber}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AppTextInput
            fullWidth
            variant="outlined"
            required
            disabled={disabled}
            label={<IntlMessages id="appModule.information.street.adress" />}
            value={establishmentData.roadAddress}
            id="roadAddress"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                roadAddress:
                  e.target.value
                    .trim()
                    .charAt(0)
                    .toUpperCase() + e.target.value.substring(1),
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                roadAddress: undefined,
              });
            }}
            helperText={establishmentValidation.roadAddress}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AppTextInput
            fullWidth
            disabled={disabled}
            variant="outlined"
            required
            label={<IntlMessages id="appModule.information.zipCode" />}
            value={establishmentData.zipCode}
            id="zipCode"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                zipCode:
                  e.target.value
                    .trim()
                    .charAt(0)
                    .toUpperCase() + e.target.value.substring(1),
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                zipCode: undefined,
              });
            }}
            helperText={establishmentValidation.zipCode}
          />
        </Grid>
      </GridContainer>
      {/*--------------------------------- ligne  3  ------------------*/}
      <GridContainer>
        <Grid item xs={12} sm={4}>
          <MuiPhoneNumber
            size="small"
            variant="outlined"
            id="phone"
            name="phone"
            required
            disabled={disabled}
            value={establishmentData.phone}
            onChange={(number, country) => {
              if (number.substring(1).length < country.dialCode.length + 1) {
                setPhoneNumberValidation(true);
              } else {
                setPhoneNumberValidation(isValidphoneNumber(number.toString()));
              }
              setEstablishmentData({
                ...establishmentData,
                phone: number.length > 5 ? number : null,
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                phone: null,
              });
            }}
            onFocus={() => {
              setEstablishmentValidation({
                ...establishmentValidation,
                phone: null,
              });
            }}
            autoFormat={false}
            fullWidth={true}
            placeholder="(+XXX) XXX XXX XXX"
            label={<IntlMessages id="user.phone" />}
            defaultCountry={country}
            helperText={
              <div style={{ color: "red" }}>
                {establishmentValidation.phone}
                {!phoneValidation ? phoneInvalid : ""}
              </div>
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AppTextInput
            fullWidth
            disabled={disabled}
            type="email"
            variant="outlined"
            required
            label={<IntlMessages id="appModule.information.email" />}
            value={establishmentData.email}
            id="email"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                email: e.target.value,
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                email: undefined,
              });
            }}
            helperText={establishmentValidation.email}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AppTextInput
            fullWidth
            disabled={disabled}
            variant="outlined"
            type="url"
            placeholder="https://example.com"
            pattern="https://.*"
            label={<IntlMessages id="appModule.information.website" />}
            value={establishmentData.webSite}
            id="establishmentData"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                webSite: e.target.value.trim(),
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                webSite: undefined,
              });
            }}
            helperText={establishmentValidation.webSite}
          />
        </Grid>
      </GridContainer>
    </>
  );
}
