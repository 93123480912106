import {
  GET_SCHOOL_REPORTS_CARDS,
   EDIT_SCHOOL_REPORTS_CARDS,
  SET_SCHOOL_REPORTS_CARDS,
  DELETE_SCHOOL_REPORTS_CARDS,
  Details_SCHOOL_REPORT_CARD,
} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  SchoolReportsCards: [],
  currentSchoolReportsCards: null,
  detailSchoolReport: {},
};
const SchoolReportsCards = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCHOOL_REPORTS_CARDS: {
      return {
        ...state,
        SchoolReportsCards: action.payload,
      };
    }
    case SET_SCHOOL_REPORTS_CARDS: {
      return {
        ...state,
        currentSchoolReportsCards: action.payload,
      };
    }
    case Details_SCHOOL_REPORT_CARD: {
      return {
        ...state,
        detailSchoolReport: action.payload.data.filter(
          (row) => row.student.id === parseInt(action.payload.studentId)
        )[0],
      };
    }

    case EDIT_SCHOOL_REPORTS_CARDS: {
      return {
        ...state,
        SchoolReportsCards: [
          action.payload,
          ...state.SchoolReportsCards.filter(
            (card) => card.id !== action.payload.id
          ),
        ],
      };
    }
    case DELETE_SCHOOL_REPORTS_CARDS: {
      return {
        ...state,
        SchoolReportsCards: state.SchoolReportsCards.map((card) => {
          let cardReport = {
            ...card,
            reportCardDetails: card.reportCardDetails?.filter(
              (card) => card.id !== action.payload
            ),
          };
          return cardReport;
        }),
      };
    }

    default:
      return state;
  }
};
export default SchoolReportsCards;
