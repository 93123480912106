const rules = {
  // rules super admin globale SAG
  1: {
    static: [
      "module-nav-superadministration",
      "module-nav-establishment-management",
      "module-nav-license-management",
      "module-nav-account-management",
      "module-nav/login-as",
    ],
    dynamic: {
      "module-nav-access": ({ mod, moduleList }) => {
        if (!moduleList || moduleList.length === 0) {
          return false;
        } else {
          const found = moduleList.find((element) => element?.pathName === mod);
          if (typeof found != "undefined") {
            return true;
          }
        }
      },
      "subModule-nav-access": ({ submoduleName, subModuleList }) => {
        if (!subModuleList || subModuleList.length === 0) {
          return false;
        } else {
          const found = subModuleList.find(
            (element) => element?.pathName === submoduleName
          );
          if (typeof found != "undefined") {
            return true;
          }
        }
      },
    },
  },
  // rules super admin local SAL

  2: {
    static: [
      "module-nav-/board-table",
      "module-nav-/administration",
      "module-nav-/canteen",
      "module-nav-/School-life",
      "module-nav-/e-library",
      "module-nav-/evalution",
      "module-nav-/financial-monitoring",
      "module-nav-/monitoring-hr",
      "module-nav-/medical-monitoring",
      "module-nav-/messaging",
      "module-nav-/discussion-group",
      "module-nav-/clubs",
      "module-nav-/news",
      "module-nav-/complaints",
      "module-nav-/harassment-alert",
      "module-nav-/edu-drive",
      "module-nav-/bus-tracking",
      "module-nav-/reports",
    ],
    dynamic: {
      "module-nav-access": ({ mod, moduleList }) => {
        if (!moduleList || moduleList.length === 0) {
          return false;
        } else {
          const found = moduleList.find((element) => element?.pathName === mod);

          if (typeof found != "undefined") {
            return true;
          }
        }
      },
      "subModule-nav-access": ({ submoduleName, subModuleList }) => {
        if (!subModuleList || subModuleList.length === 0) {
          return false;
        } else {
          const found = subModuleList.find(
            (element) => element?.pathName === submoduleName
          );
          if (typeof found != "undefined") {
            return true;
          }
        }
      },
    },
  },
  // rules super admin local ADL
  3: {
    static: [
      "module-nav-/board-table",
      "module-nav-/administration",
      "module-nav-/canteen",
      "module-nav-/School-life",
      "module-nav-/e-library",
      "module-nav-/evalution",
      "module-nav-/financial-monitoring",
      "module-nav-/monitoring-hr",
      "module-nav-/medical-monitoring",
      "module-nav-/messaging",
      "module-nav-/discussion-group",
      "module-nav-/clubs",
      "module-nav-/news",
      "module-nav-/complaints",
       "module-nav-/bus-tracking",
      "module-nav-/harassment-alert", 
      "module-nav-/edu-drive",
      "module-nav-/reports",
    ],
    dynamic: {
      "module-nav-access": ({ mod, moduleList }) => {
        if (!moduleList || moduleList.length === 0) {
          return false;
        } else {
          const found = moduleList.find((element) => element?.pathName === mod);

          if (typeof found != "undefined") {
            return true;
          }
        }
      },
      "subModule-nav-access": ({ submoduleName, subModuleList }) => {
        if (!subModuleList || subModuleList.length === 0) {
          return false;
        } else {
          if (submoduleName !== "/login-as") {
            const found = subModuleList.find(
              (element) => element?.pathName === submoduleName
            );
            if (typeof found != "undefined") {
              return true;
            }
          }
        }
      },
    },
  },

  // rules operator
  4: {
    static: [
      "module-nav-/board-table",
      "module-nav-/administration",
      "module-nav-/canteen",
      "module-nav-/School-life",
      "module-nav-/e-library",
      "module-nav-/evalution",
      "module-nav-/financial-monitoring",
      "module-nav-/monitoring-hr",
      "module-nav-/medical-monitoring",
      "module-nav-/messaging",
      "module-nav-/discussion-group",
      "module-nav-/clubs",
      "module-nav-/news",
      "module-nav-/complaints",
      "module-nav-/harassment-alert",
      "module-nav-/bus-tracking",
    ],
    dynamic: {
      "module-nav-access": ({ mod, moduleList }) => {
        if (!moduleList || moduleList.length === 0) {
          return false;
        } else {
          const found = moduleList.find((element) => element?.pathName === mod);

          if (typeof found != "undefined") {
            return true;
          }
        }
      },
      "subModule-nav-access": ({ submoduleName, subModuleList }) => {
        if (!subModuleList || subModuleList.length === 0) {
          return false;
        } else {
          if (submoduleName !== "/login-as") {
            const found = subModuleList.find(
              (element) => element?.pathName === submoduleName
            );
            if (typeof found != "undefined") {
              return true;
            }
          }
        }
      },
    },
  },
};
export default rules;
