import {
  ADD_PARENTS,
  EDIT_PARENTS,
  GET_PARENTS,
  GET_PARENTS_LIST,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  parents: [],
};

const Parents = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_PARENTS: {
      return {
        ...state,
        parents: [...action.payload, ...state.parents],
      };
    }
    case EDIT_PARENTS: {
      return {
        ...state,
        parents: state.parents.map(parent => (parent.id === action.payload.id ? action.payload : parent)),

      };
    }
    case GET_PARENTS: {
      return {
        ...state,
        parents: action.payload,
      };
    }

    case GET_PARENTS_LIST: {
      return {
        ...state,
        parents: action.payload,
      };
    }

    default:
      return state;
  }
};
export default Parents;
