import React from "react";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
export const requiredMessage = <IntlMessages id="appModule.err.required" />;
export const birthdayAfterMin = (
  <IntlMessages id="app.module.addUser.birthdateLesserThanMin" />
);
export const emailNotValid = <IntlMessages id="appModule.err.required.email" />;
export const passwordMisMatch = (
  <IntlMessages id="appModule.err.required.password" />
);
export const passwordRulesMisMatch = (
  <IntlMessages id="appModule.err.required.password.rules" />
);
export const invalidPasswordRules = <IntlMessages id="invalid.password" />;
export const confirmPasswordNotMatch = (
  <IntlMessages id="confirm.password.error" />
);
export const passwordLength = <IntlMessages id="app.module.new.password" />;
export const phoneOnlyNumericals = (
  <IntlMessages id="appModule.err.required.password.isNotValide" />
);
export const requiredEmail = <IntlMessages id="email.required" />;
export const requiredPhone = <IntlMessages id="phone.required" />;
export const noSpaceMessage = (
  <IntlMessages id="appModule.err.noSpaceMessage" />
);
export const duplicateName = <IntlMessages id="duplicate.name" />;
export const taxMemberDuplicated = (
  <IntlMessages id="duplicate.taxMemberDuplicated" />
);
export const invalidePassword = <IntlMessages id="invalide.password" />;
export const invalidCapacity = <IntlMessages id="invalid.capacity" />;
export const invalid_Date = <IntlMessages id="invalid.date" />;
export const invalidRib = <IntlMessages id="invalid.rib" />;
export const invalidCIN = <IntlMessages id="cin.invalid" />;
export const phoneInvalid = <IntlMessages id="error.user.message.phone" />;
export const invalidEndDate = <IntlMessages id="endDate.invalid" />;
export const requiredDateMessage = (
  <IntlMessages id="appModule.err.date.required" />
);
export const requiredFiled = <IntlMessages id="appModule.file.err.required" />;
export const invalidDate = <IntlMessages id="appModule.invalidDate" />;
export const invalidDay = <IntlMessages id="appModule.invalidDay" />;
export const negativNubmer = <IntlMessages id="appModule.isNegativeNumer" />;
export const isGreateThenToday = (
  <IntlMessages id="appModule.startDate.isgreater.thenToday" />
);
export const invalidInput = <IntlMessages id="invalid.input" />;
export const isEmptyList = <IntlMessages id="appModule.isEmptyList" />;
export const invalidSecondDate = (
  <IntlMessages id="appModule.invalidSecondDate" />
);
export const invalidTime = <IntlMessages id="time.invalid" />;
export const duplicateCIN = <IntlMessages id="duplicate.cin" />;
export const isInvalidFormatUrl = (
  <IntlMessages id="appModule.isInvalidFormatUrl" />
);
export const invalidPublicationDate = (
  <IntlMessages id="appModule.err.date.publication.required" />
);
export const negativeGradeMessage = (
  <IntlMessages id="appModule.isNegativeNumer" />
);
export const noProfMessage = <IntlMessages id="appModule.err.noProf" />;
export const overTwentyMessageGrade = (
  <IntlMessages id="appModule.err.overTwentyGrade" />
);
export const overTwentyMessageAvg = (
  <IntlMessages id="appModule.err.overTwentyAvg" />
);
export const isGreateOrEqualToToday = (
  <IntlMessages id="appModule.startDate.isgreaterOrEqual.toToday" />
);
export const isGreateOrEqualToHour = (
  <IntlMessages id="appModule.startDate.isgreaterOrEqual.toHour" />
);
export const sessionYetToStart = (
  <IntlMessages id="app.module.sessionDidNotStartYet" />
);
export const sessionAlreadyEnded = (
  <IntlMessages id="app.module.sessionAlreadyEnded" />
);
export const invalid_date_format = (
  <IntlMessages id="app.module.SAL.component.add.admin.invalid.date" />
);
export const isLessOrEqual = (
  <IntlMessages id="appModule.startDate.isLessOrEqual.toHour" />
);

export const duplicateMatricule = <IntlMessages id="duplicate.matricule" />;
