export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const LOADING = "loading";
export const UPDATE_AUTH_USER = "update_auth_user";
export const UPDATE_AUTH_SUBESTABLIDHMENT = "UPDATE_AUTH_SUBESTABLIDHMENT";
export const UPDATE_LOAD_USER = "update_load_user";
export const SEND_FORGET_PASSWORD_EMAIL = "send_forget_password_email";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const PASSWORD_CHANGED = "password_changed";
export const SET_DASHBOARD_DATA = "set_dashboard_data";
export const SIGNIN_AS_USER_SUCCESS = "SIGNIN_AS_USER_SUCCESS";
export const GET_USER_AS_INFO = "GET_USER_AS_INFO";
export const SET_USER_ETQ = "SET_USER_ETQ";
export const SELECT_SCHOOL_YEAR = "SELECT_SCHOOL_YEAR";
export const LOGIN_ERROR_BLOCKED = "LOGIN_ERROR_BLOCKED";
export const ACCESS_DATA = "ACCESS_DATA";

export const SET_TASK_CURRENT_USER = "set_task_current_user";
export const SET_TASKS_DATA = "set_tasks_data";
export const SET_TASK_LIST_DATA = "set_task_list_data";
export const ADD_TASK = "add_task";
export const DELETE_TASK = "delete_task";
export const UPDATE_TASK = "update_task";
export const SET_FILTER_DATA = "set_filter_data";
export const ADD_TASK_LIST = "add_task_list";
export const UPDATE_TASK_LIST = "update_task_list";
export const DELETE_TASK_LIST = "delete_task_list";
export const SET_TASK_DETAIL = "set_task_detail";
export const SEND_MESSAGE = "send_message";
export const TOGGLE_SIDEBAR_COLLAPSED = "toggle_sidebar_collapsed";
export const GET_TASKS_COUNTS = "get_tasks_counts";

//mail app
export const GET_LABELS_LIST = "get_labels_list";
export const GET_MAILS_LIST = "get_mails_list";
export const UPDATE_MAIL_FOLDER = "update_mail_folder";
export const UPDATE_MAIL_LABEL = "upade_mail_label";
export const UPDATE_FAVORITE_STATUS = "update_favorite_status";
export const UPDATE_READ_STATUS = "update_read_status";
export const UPDATE_IMPORTANT_STATUS = "update_important_status";
export const COMPOSE_MAIL = "compose_mail";
export const SET_FILTER_TYPE = "set_filter_type";
export const GET_SELECTED_MAIL = "GET_SELECTED_MAIL";
export const UPDATE_SELECTED_MAIL = "update_selected_mail";
export const NULLIFY_SELECTED_MAIL = "nullify_selected_mail";
export const REPLY_TO_MAIL = "reply_to_mail";
export const GET_MAIL_COUNTS = "get_mail_count";
export const REMOVE_CONNECTION = "remove_connection";
export const FAVORIT_MAIL = "FAVORIT_MAIL";
export const IMPORTANT_MAILS = "IMPORTANT_MAILS";
export const DELETED_MAILS = "DELETED_MAILS";
export const MARK_AS_READ = "MARK_AS_READ";
export const LIST_SAL = "LIST_SAL";
export const OPERATOR_LIST = "OPERATOR_LIST";
export const ADL_LIST = "ADL_LIST";
export const LIST_PROF = "LIST_PROF";

export const SET_CHAT_USERS = "set_chat_users";
export const SET_CONTACT_USERS = "set_contact_users";
export const SET_CURRENT_USER = "set_current_user";
export const SET_CONVERSATION_DATA = "set_conversation_data";
export const SEND_NEW_CHAT_MESSAGE = "send_new_chat_message";
export const SEND_NEW_MEDIA_MESSAGE = "send_new_media_message";

//Contact App
export const GET_CONTACTS_LIST = "get_contacts_list";
export const SET_CURRENT_CONTACT = "set_current_contact";
export const CREATE_CONTACT = "create_contact";
export const UPDATE_STARRED_STATUS = "update_starred_status";
export const DELETE_CONTACT = "delete_contact";
export const UPDATE_CONTACT_LABEL = "update_contact_label";
export const UPDATE_CONTACT = "update_contact";
export const GET_CONTACT_COUNTS = "get_contact_counts";
export const UPDATE_LABEL_ITEM = "update_label_item";
export const DELETE_LABEL_ITEM = "delete_label_item";

export const GET_USER_DETAIL = "get_user_detail";
export const GET_FEED_POSTS = "get_feed_posts";
export const CREATE_POST = "create_post";
export const UPDATE_POST = "update_post";

// Users Module
export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_BULK_USERS = "DELETE_BULK_USERS";

//OPERATOR
export const GET_OPERATORS = "GET_OPERATORS";
export const ADD_OPERATOR = "ADD_OPERATOR";
export const SET_OPERATOR_DETAILS = "SET_OPERATOR_DETAILS";
export const EDIT_OPERATOR = "EDIT_OPERATOR";
export const DELETE_OPERATOR = "DELETE_OPERATOR";

// PROFESSOR

export const GET_PROFESSORS = "GET_PROFESSORS";
export const ADD_PROFESSOR = "ADD_PROFESSOR";
export const SET_PROFESSOR_DETAILS = "SET_PROFESSOR_DETAILS";
export const EDIT_PROFESSOR = "EDIT_PROFESSOR";
export const DELETE_PROFESSOR = "DELETE_PROFESSOR";

// Establishment Module
export const GET_ESTABLISHMENTS = "GET_ESTABLISHMENTS";
export const GET_SUBESTABLISHMENT = "GET_SUBESTABLISHMENT";
export const ADD_ESTABLISHMENT = "ADD_ESTABLISHMENT";
export const SET_ESTABLISHMENT_DETAILS = "SET_ESTABLISHMENT_DETAILS";
export const EDIT_ESTABLISHMENT = "EDIT_ESTABLISHMENT";
export const DELETE_ESTABLISHMENT = "DELETE_ESTABLISHMENT";
export const GET_LOGO = "GET_LOGO";
export const GET_ACTION = "GET_ACTION";
//License Module
export const GET_LICENSES = "GET_LICENSES";
export const ADD_LICENSE = "ADD_LICENSE";
export const SET_LICENSE_DETAILS = "SET_LICENSE_DETAILS";
export const EDIT_LICENSE = "EDIT_LICENSE";
export const DELETE_LICENSE = "DELETE_LICENSE";

//Level Module
export const GET_LEVELS = "GET_LEVELS";
export const ADD_LEVEL = "ADD_LEVEL";
export const SET_LEVEL_DETAILS = "SET_LEVEL_DETAILS";
export const EDIT_LEVEL = "EDIT_LEVEL";
export const DELETE_LEVEL = "DELETE_LEVEL";

//SUBJECT Module
export const GET_SUBJECTMODULES = "GET_SUBJECTMODULES";
export const ADD_SUBJECTMODULE = "ADD_SUBJECTMODULE";
export const SET_SUBJECTMODULE_DETAILS = "SET_SUBJECTMODULE_DETAILS";
export const EDIT_SUBJECTMODULE = "EDIT_SUBJECTMODULE";
export const DELETE_SUBJECTMODULE = "DELETE_SUBJECTMODULE";

//Subject
export const ADD_SUBJECT = "ADD_SUBJECT";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const EDIT_SUBJECT = "EDIT_SUBJECT";
export const GET_SUBJECTS = "GET_SUBJECTS";
export const SET_SUBJECT_DETAILS = "SET_SUBJECT_DETAILS";
//Classroom
export const GET_CLASSROOMS = "GET_CLASSROOMS";
export const ADD_CLASSROOM = "ADD_CLASSROOM";
export const EDIT_CLASSROOM = "EDIT_CLASSROOM";
export const SET_CLASSROOM_DETAILS = "SET_CLASSROOM_DETAILS";
export const DELETE_CLASSROOM = "DELETE_CLASSROOM";

//Bus
export const GET_BUS = "GET_BUS";
export const ADD_BUS = "ADD_BUS";
export const EDIT_BUS = "EDIT_BUS";
export const SET_BUS_DETAILS = "SET_BUS_DETAILS";
export const DELETE_BUS = "DELETE_BUS";

//Itineraire
export const GET_ITINERAIRE = "GET_ITINERAIRE";
export const ADD_ITINERAIRE = "ADD_ITINERAIRE";
export const EDIT_ITINERAIRE = "EDIT_ITINERAIRE";
export const SET_ITINERAIRE_DETAILS = "SET_ITINERAIRE_DETAILS";
export const DELETE_ITINERAIRE = "DELETE_ITINERAIRE";

// GROUP_CLASS$GROUP
export const GET_GROUP_CLASS = "GET_GROUP_CLASS";
export const ADD_GROUP_CLASS = "ADD_GROUP_CLASS";
export const EDIT_GROUP_CLASS = "EDIT_GROUP_CLASS0;";
export const SET_GROUP_CLASS_DETAILS = "SET_GROUP_CLASS_DETAILS";
export const DELETE_GROUP_CLASS = "DELETE_GROUP_CLASS";

// SchoolYear
export const DATA_LOADED_SCHOOLYEAR = "DATA_LOADED_SCHOOLYEAR";

// Module
export const DATA_LOADED_MODULE = "DATA_LOADED_MODULE";
//Materials
export const GET_MATERIALS = "GET_MATERIALS";
export const ADD_MATERIAL = "ADD_MATERIAL";
export const EDIT_MATERIAL = "EDIT_MATERIAL";
export const SET_MATERIAL_DETAILS = "SET_MATERIAL_DETAILS";
export const DELETE_MATERIAL = "DELETE_MATERIAL";

//ScoolSession
export const GET_SESSIONS = "GET_SESSIONS";
export const ADD_SESSION = "ADD_SESSION";
export const EDIT_SESSION = "EDIT_SESSION";
export const SET_SESSION_DETAILS = "SET_SESSION_DETAILS";
export const DELETE_SESSION = "DELETE_SESSION";

// ADMINISTRATIVE DOCUMENTS
export const GET_ADMINISTRATIVE_DOCUMENTS = "GET_ADMINISTRATIVE_DOCUMENTS";
export const POST_ADMINISTRATIVE_DOCUMENTS = "POST_ADMINISTRATIVE_DOCUMENTS";
export const EDIT_ADMINISTRATIVE_DOCUMENTS = "EDIT_ADMINISTRATIVE_DOCUMENTS";
export const DELETE_ADMINISTRATIVE_DOCUMENTS =
  "DELETE_ADMINISTRATIVE_DOCUMENTS";
export const SET_ADMINISTRATIVE_DOCUMENTS_DETAILS =
  "SET_ADMINISTRATIVE_DOCUMENTS_DETAILS";
//Holiday
export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const EDIT_HOLIDAY = "EDIT_HOLIDAY";
export const SET_HOLIDAY_DETAILS = "SET_HOLIDAY_DETAILS";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";

//Vaccination
export const GET_VACATIONS = "GET_VACATIONS";
export const ADD_VACATION = "ADD_VACATION";
export const EDIT_VACATION = "EDIT_VACATION";
export const SET_VACATION_DETAILS = "SET_VACATION_DETAILS";
export const DELETE_VACATION = "DELETE_VACATION";

//ROOM
export const ADD_ROOM = "ADD_ROOM";
export const DELETE_ROOM = "DELETE_ROOM";
export const EDIT_ROOM = "EDIT_ROOM";
export const GET_ROOMS = "GET_ROOMS";
export const SET_ROOM_DETAILS = "SET_ROOM_DETAILS";

//MULTIMEDIA
export const ADD_MULTIMEDIA = "ADD_MULTIMEDIA";
export const DELETE_MULTIMEDIA = "DELETE_MULTIMEDIA";
export const EDIT_MULTIMEDIA = "EDIT_MULTIMEDIA";
export const GET_MULTIMEDIAS = "GET_MULTIMEDIAS";
export const SET_MULTIMEDIA_DETAILS = "SET_MULTIMEDIA_DETAILS";
export const Details_Multimedia_SUPPORT = "Details_Multimedia_SUPPORT";
//ADD_ADMIN_LOCAL
export const ADD_ADMIN_LOCAL = "ADD_ADMIN_LOCAL";
export const GET_ADMINS_LOCAL = "GET_ADMINS_LOCAL";
export const SET_ADMIN_LOCAL_DETAILS = "SET_ADMIN_LOCAL_DETAILS";
export const EDIT_ADMIN_LOCAL = "EDIT_ADMIN_LOCAL";
export const DELETE_ADMIN_LOCAL = "DELETE_ADMIN_LOCAL";

//CANTEEN Module
export const GET_DISHES = "GET_DISHES";
export const GET_DISH = "GET_DISH";
export const ADD_DISHE = "ADD_DISHE";
export const EDIT_DISH = "EDIT_DISH";
export const GET_PHOTOS_DISHE = "GET_PHOTOS_DISHE";
export const DELETE_DISH = "DELETE_DISH ";
export const CLEAR_DISH = "CLEAR_DISH ";
export const SET_DISH_DETAILS = "SET_DISH_DETAILS";
export const GET_MENUS = "GET_MENUS";
export const ADD_MENU = "ADD_MENU";
export const EDIT_MENU = "EDIT_MENU";
export const SET_MENU_DETAILS = "SET_MENU";
export const DELETE_MENU = "DELETE_MENU";

//student
export const ADD_STUDENT = "ADD_STUDENT";
export const EDIT_STUDENT = "EDIT_STUDENT";
export const GET_STUDENTS = "GET_STUDENTS";

// Parent
export const ADD_PARENT = "ADD_PARENT";

//Suggestion Chef Module
export const ADD_SUGGESTIONCHEF = "ADD_SUGGESTIONCHEF";
export const GET_SUGGESTIONCHEFS = "GET_SUGGESTIONCHEFS";
export const EDIT_SUGGESTIONCHEF = "EDIT_SUGGESTIONCHEF";
export const DELETE_SUGGESTIONCHEF = "DELETE_SUGGESTIONCHEF ";
export const SET_SUGGESTIONCHEF_DETAILS = "SET_SUGGESTIONCHEF_DETAILS";

//GET_EDUCATIONALSUPPORT
export const GET_EDUCATIONALSUPPORT = "GET_EDUCATIONALSUPPORT";
export const ADD_EDUCATIONALSUPPORT = "ADD_EDUCATIONALSUPPORT";
export const EDIT_EDUCATIONALSUPPORT = "EDIT_EDUCATIONALSUPPORT";
export const SET_EDUCATIONALSUPPORT = "SET_EDUCATIONALSUPPORT";
export const DELETE_EDUCATIONALSUPPORT = "DELETE_EDUCATIONALSUPPORT";
export const COUNT_DATA = "COUNT_DATA";
export const ADD_COUNT = "ADD_COUNT";
export const DETAILS_EDUCATIONALSUPPORT = "DETAILS_EDUCATIONALSUPPORT";
export const Details_PAYSLIP = "Details_PAYSLIP";
//TimeTable
export const ADD_EVENT = "ADD_EVENT";
export const GET_EVENTS = "GET_EVENTS";
export const SET_EVENT_DETAILS = "SET_EVENT_DETAILS";
export const SET_EMPTY_LIST = "SET_EMPTY_LIST";
export const DELETE_EVENT = "DELETE_EVENT";
export const GET_VACATION_EVENTS = "GET_VACATION_EVENTS";

//Dashboard
export const GET_WEATHER = "GET_WEATHER";
//Modules
export const GET_MODULES = "GET_MODULES";
export const GET_SUB_MODULES = "GET_SUB_MODULES";
//Complaint
export const GET_COMPLAINTS = "GET_COMPLAINTS";
export const ADD_COMPLAINT = "ADD_COMPLAINT";
export const EDIT_COMPLAINT = "EDIT_COMPLAINT";
export const EDIT_REPLY_COMPLAINT = "EDIT_REPLY_COMPLAINT";
export const SET_COMPLAINT_DETAILS = "SET_COMPLAINT_DETAILS";
export const DELETE_COMPLAINT = "DELETE_COMPLAINT";

//Vaccination

export const ADD_VACCINATION = "ADD_VACCINATION";
export const DELETE_VACCINATION = "DELETE_VACCINATION";
export const EDIT_VACCINATION = "EDIT_VACCINATION";
export const GET_VACCINATIONS = "GET_VACCINATIONS";
export const SET_VACCINATION_DETAILS = "SET_VACCINATION_DETAILS";
export const SET_VACCINATION_EDIT = "SET_VACCINATION_EDIT";

//Medical History
export const ADD_MEDICAL_HISTORY = "ADD_MEDICAL_HISTORY";
export const DELETE_MEDICAL_HISTORY = "DELETE_MEDICAL_HISTORY";
export const EDIT_MEDICAL_HISTORY = "EDIT_MEDICAL_HISTORY";
export const GET_MEDICAL_HISTORY = "GET_MEDICAL_HISTORY";
export const SET_MEDICAL_HISTORY_DETAILS = "SET_MEDICAL_HISTORY_DETAILS";

//schoolSupplies

export const ADD_SCHOOLSUPPLIES = "ADD_SCHOOLSUPPLIES";
export const DELETE_SCHOOLSUPPLIES = "DELETE_SCHOOLSUPPLIES";
export const EDIT_SCHOOLSUPPLIES = "EDIT_SCHOOLSUPPLIES";
export const GET_SCHOOLSUPPLIES = "GET_SCHOOLSUPPLIES";
export const SET_SCHOOLSUPPLIES = "SET_SCHOOLSUPPLIES";
export const Details_SCHOOL_SUPPLIES = "Details_SCHOOL_SUPPLIES";
//CLUB
export const GET_CLUBS = "GET_CLUBS";
export const ADD_CLUB = "ADD_CLUB";
export const EDIT_CLUB = "EDIT_CLUB";
export const EDIT_CLUB_POST = "EDIT_CLUB_POST";
export const SET_CLUB_DETAILS = "SET_CLUB_DETAILS";
export const DELETE_CLUB = "DELETE_CLUB";
export const GET_CLUBS_INFOS = "GET_CLUBS_INFOS";
export const ADD_PUBLICATION_CLUB = "ADD_PUBLICATION_CLUB";
export const GET_PUBLICATIONS = "GET_PUBLICATIONS";
export const ADD_COMMENT = "ADD_COMMENT";
export const GET_COUNT = "GET_COUNT";
export const LIKE_POST = "LIKE_POST";
export const DISLIKE_POST = "DISLIKE_POST";
export const LIKE_COMMENT = "LIKE_COMMENT";
export const DISLIKE_COMMENT = "DISLIKE_COMMENT";
export const LIKE_POST_FROM_MODAL = "LIKE_POST_FROM_MODAL";
export const DISLIKE_POST_FROM_MODAL = "DISLIKE_POST_FROM_MODAL";
export const LIKE_COMMENT_FROM_MODAL = "LIKE_COMMENT_FROM_MODAL";
export const DISLIKE_COMMENT_FROM_MODAL = "DISLIKE_COMMENT_FROM_MODAL";
export const GET_COMMENTS = "GET_COMMENTS";
export const DELETE_CLUB_PUB = "DELETE_CLUB_PUB";
export const GET_COMMENTS_OF_POST = "GET_COMMENTS_OF_POST";
//news
export const ADD_NEWS = "ADD_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const EDIT_NEWS = "EDIT_NEWS";
export const GET_NEWS = "GET_NEWS";
export const SET_NEWS_DETAILS = "SET_NEWS_DETAILS";
//payslips

export const ADD_PAYSLIPS = "ADD_PAYSLIPS";
export const GET_PAYSLIPS = "GET_PAYSLIPS";
export const DELETE_PAYSLIPS = "DELETE_PAYSLIPS";
export const EDIT_PAYSLIPS = "EDIT_PAYSLIPS";
export const SET_PAYSLIPS = "SET_PAYSLIPS";

//Discussion Groups
export const GET_DISCUSSION_GROUPS = "GET_DISCUSSION_GROUPS";
export const EDIT_DISCUSSION_GROUPS = "EDIT_DISCUSSION_GROUPS";
export const SET_DISCUSSION_GROUP = "GET_DISCUSSION_GROUP";

//schoolSupplies

export const ADD_SCHOOLREPORTS = "ADD_SCHOOLREPORTS";
export const DELETE_SCHOOLREPORTS = "DELETE_SCHOOLREPORTS";
export const EDIT_SCHOOLREPORTS = "EDIT_SCHOOLREPORTS";
export const GET_SCHOOLREPORTS = "GET_SCHOOLREPORTS";
export const SET_SCHOOLREPORTS = "SET_SCHOOLREPORTS";
//SERVICES MANAHEMENTS
export const ADD_SERVICESMANAGEMENT = "ADD_SERVICESMANAGEMENT";
export const DELETE_SERVICESMANAGEMENT = "DELETE_SERVICESMANAGEMENT";
export const EDIT_SERVICESMANAGEMENT = "EDIT_SERVICESMANAGEMENT";
export const GET_SERVICESMANAGEMENTS = "GET_SERVICESMANAGEMENTS";
export const SET_SERVICESMANAGEMENT_DETAILS = "SET_SERVICESMANAGEMENT_DETAILS";

//ASSIGNMENT SERVICES

export const ADD_ASSIGNMENTSERVICES = "ADD_ASSIGNMENTSERVICES";
export const GET_ASSIGNMENTSERVICES = "GET_ASSIGNMENTSERVICES";
export const EDIT_ASSIGNMENTSERVICES = "EDIT_ASSIGNMENTSERVICES";
export const SET_ASSIGNMENTSERVICES_DETAILS = "SET_ASSIGNMENTSERVICES_DETAILS";
export const DELETE_ASSIGNMENTSERVICES = "DELETE_ASSIGNMENTSERVICES";

//Financial Monitoring
export const GET_DEDLINES_LIST = "GET_DEDLINES_LIST";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const SET_LIST_DETAILS = "SET_LIST_DETAILS";

//Sanctions

export const ADD_SANCTION = "ADD_SANCTION";
export const DELETE_SANCTION = "DELETE_SANCTION";
export const EDIT_SANCTION = "EDIT_SANCTION";
export const GET_SANCTIONS = "GET_SANCTIONS";
export const SET_SANCTION_DETAILS = "SET_SANCTION_DETAILS";
//Grades

export const ADD_SCHOOLGRADES = "ADD_SCHOOLGRADES";
export const GET_SCHOOLGRADES = "GET_SCHOOLGRADES";
export const EDIT_SCHOOLGRADES = "EDIT_SCHOOLGRADES";
export const SET_SCHOOLGRADES = "SET_SCHOOLGRADES";
export const DELETE_SCHOOLGRADES = "DELETE_SCHOOLGRADES";

// Assiduity
export const GET_ASSIDUITY = "GET_ASSIDUITY";
export const SET_ASSIDUITY_DETAILS = "SET_ASSIDUITY_DETAILS";
export const EDIT_ASSIDUITY = "EDIT_ASSIDUITY";
export const DELETE_ASSIDUITY = "DELETE_ASSIDUITY";

// PRESENCE
export const GET_PRESENCE = "GET_PRESENCE";
export const SET_PRESENCE_DETAILS = "SET_PRESENCE_DETAILS";
export const EDIT_PRESENCE = "EDIT_PRESENCE";
export const DELETE_PRESENCE = "DELETE_PRESENCE";

//Section Module
export const GET_SECTIONS = "GET_SECTIONS";
export const ADD_SECTION = "ADD_SECTION";
export const SET_SECTION_DETAILS = "SET_SECTION_DETAILS";
export const EDIT_SECTION = "EDIT_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
//Harassement

export const ADD_HARASSEMENT = "ADD_HARASSEMENT";
export const GET_HARASSEMENT = "GET_HARASSEMENT";
export const EDIT_HARASSEMENT = "EDIT_HARASSEMENT";
export const SET_HARASSEMENT_DETAILS = "SET_HARASSEMENT_DETAILS";
export const EDIT_REPLY_HARASSEMENT = "EDIT_REPLY_HARASSEMENT";

//Collaborator Monitoring
export const ADD_COLLABORATOR_ABSCENCE = "ADD_COLLABORATOR_ABSCENCE";
export const GET_COLLABORATOR_ABSCENCE = "GET_COLLABORATOR_ABSCENCE";
export const EDIT_COLLABORATOR_ABSCENCE = "EDIT_COLLABORATOR_ABSCENCE";
export const SET_COLLABORATOR_ABSCENCE_DETAILS =
  "SET_COLLABORATOR_ABSCENCE_DETAILS";
export const DELETE_COLLABORATOR_ABSCENCE = "DELETE_COLLABORATOR_ABSCENCE";
export const SET_ACCOUNTING_SETTING = "SET_ACCOUNTING_SETTING";
export const GET_COLLABORATOR_EXPORT_FILE = "GET_COLLABORATOR_EXPORT_FILE";
// Operator Permission Manager

export const GET_OPERATOR_PERMISSION = "GET_OPERATOR_PERMISSION";
export const UPDATE_OPERATOR_PERMISSION = "UPDATE_OPERATOR_PERMISSION";

export const UPDATE_PROFIL = "UPDATE_PROFIL";
export const UPDATE_PHOTO = "UPDATE_PHOTO";

//School Reports Card

export const GET_SCHOOL_REPORTS_CARDS = "GET_SCHOOL_REPORTS_CARDS ";
export const EDIT_SCHOOL_REPORTS_CARDS = "EDIT_SCHOOL_REPORTS_CARDS";
export const SET_SCHOOL_REPORTS_CARDS = "SET_SCHOOL_REPORTS_CARDS";
export const DELETE_SCHOOL_REPORTS_CARDS = "DELETE_SCHOOL_REPORTS_CARDS";
export const Details_SCHOOL_REPORT_CARD = "Details_SCHOOL_REPORT_CARD";
export const HISTORY_IMPORT = "HISTORY_IMPORT";

export const SET_MATERIALS_DETAILS = "SET_MATERIALS_DETAILS";

// --- User emails
export const GET_USER_SENT_EMAILS = "GET_USER_SENT_EMAILS";
export const GET_USER_RECEIVED_EMAILS = "GET_USER_RECEIVED_EMAILS";
export const GET_ETAB_EMAILS = "GET_ETAB_EMAILS";
export const SET_FILTREING_ROLE_ID = "SET_FILTREING_ROLE_ID";

// Socket IO
export const CONNECT_TO_CANAL = "CONNECT_TO_CANAL";
export const DISCONNECT_FROM_CANAL = "DISCONNECT_FROM_CANAL";
export const EMIT_MESSAGE = "EMIT_MESSAGE";
export const LISTEN_FOR_MESSAGE = "LISTEN_FOR_MESSAGE";
export const GET_USER_EMAILS = "GET_USER_EMAILS";

// Notifications
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

//LANG
export const SET_LANG = "SET_LANG";

// Parents
export const GET_PARENTS = "GET_PARENTS";
export const GET_PARENTS_LIST = "GET_PARENTS_LIST";
export const EDIT_PARENTS = "EDIT_PARENTS";
export const ADD_PARENTS = "ADD_PARENTS";

// Dashboard
export const DASHBOARD_STATISTIC = "DASHBOARD_STATISTIC";

//=========== EDU DRIVE =============
export const GET_FILES = "GET_FILES";
export const GET_STORAGE = "GET_STORAGE";
export const GET_TRACKING_STORAGE = "GET_TRACKING_STORAGE";

// ------ reports
export const GET_REPORTS_RECRUITEMENT = "GET_REPORTS_RECRUITEMENT";
export const GET_REPORTS_ASSIDUITY = "GET_REPORTS_ASSIDUITY";
export const GET_REPORTS_REACLAMATION = "GET_REPORTS_REACLAMATION";
export const LOAD_REPORT_REACLAMATION = "LOAD_REPORT_REACLAMATION";
export const STOP_LOAD_REPORT_REACLAMATION = "STOP_LOAD_REPORT_REACLAMATION";
export const GET_REPORTS_HARASSEMENT = "GET_REPORTS_HARASSEMENT";
export const GET_REPORTS_SANCTIONS = "GET_REPORTS_SANCTIONS";
export const GET_REPORTS_CLUBS = "GET_REPORTS_CLUBS";
export const GET_REPORTS_ASSIDUITY_PROF = "GET_REPORTS_ASSIDUITY_PROF";
export const GET_REPORTS_NUMBER_CLASSROOMS = "GET_REPORTS_NUMBER_CLASSROOMS";
export const GET_REPORTS_NUMBER_CLASSES = "GET_REPORTS_NUMBER_CLASSES";

// --- First connection
export const IS_FIRST_CONNECTION = "IS_FIRST_CONNECTION";
export const GET_SKILLS = "GET_SKILLS";
export const GET_PRE_INSCIRPTION_USERS ="GET_PRE_INSCIRPTION_USERS"
