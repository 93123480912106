import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import ListRow from "../CommonComponents/TableComponent/ListRow";
import TableHead from "../CommonComponents/TableComponent/TableHead";
import { getComparator, stableSort } from "../../@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, setCurrentUser } from "../../redux/actions/Users";
import AddEditUser from "./AddEditUser";
import DetailUser from "./DetailUser";
import {
  useDebounce,
  searchUserWithFistNameLastName,
} from "../../@jumbo/utils/commonHelper";
import NoRecordFound from "../CommonComponents/NoRecordFound";
import useStyles from "../CommonComponents/Style/TableStyle";
import TableToolbar from "../CommonComponents/TableComponent/TableToolbar";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import { getEstablishments } from "../../redux/actions/Establishments";
import { useIntl } from "react-intl";

const headCells = [
  {
    id: "lastName",
    numeric: false,
    withOutSort: true,
    disablePadding: true,
    label: <IntlMessages id="appModule.LastName" />,
  },
  {
    id: "firstName",
    numeric: false,
    withOutSort: true,
    disablePadding: false,
    label: <IntlMessages id="appModule.FirstName" />,
  },
  {
    id: "userName",
    numeric: false,
    withOutSort: true,
    disablePadding: false,
    label: <IntlMessages id="appModule.userName" />,
  },
  {
    id: "establismentParent",
    numeric: false,
    withOutSort: true,
    disablePadding: false,
    label: <IntlMessages id="establisment.parent" />,
  },
  {
    id: "email",
    numeric: false,
    withOutSort: true,
    disablePadding: false,
    label: <IntlMessages id="appModule.information.email" />,
  },
  {
    id: "phone",
    numeric: true,
    withOutSort: true,
    disablePadding: false,
    label: <IntlMessages id="appModule.phone" />,
  },
];
const hedearToolbar = {
  title: <IntlMessages id="sag.accounts" />,
  action: <IntlMessages id="add.new.sal" />,
};
const AccountManagement = () => {
  const classes = useStyles();
  const { users } = useSelector(({ usersReducer }) => usersReducer);
  const { establishments } = useSelector(
    ({ EstablishmentsReducer }) => EstablishmentsReducer
  );

  const [orderBy, setOrderBy] = React.useState("lastName");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openDetailUserDialog, setOpenDetailUserDialog] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  let establishmentsList = [];

  establishments.map((element) => {
    if (element.establishmentType === "complex") {
      establishmentsList.push(element);
    } else if (element.name === element?.parentEstablishment) {
      establishmentsList.push({ ...element, id: element.establishmentId });
    }
    return element;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUsers(filterOptions, debouncedSearchTerm, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
      })
    );
    dispatch(getEstablishments());
  }, [dispatch, filterOptions, debouncedSearchTerm]);

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
    dispatch(setCurrentUser(null));
  };

  const handleCloseDetailUserDialog = () => {
    setOpenDetailUserDialog(null);
  };
  const handleUserDetail = (user) => {
    dispatch(setCurrentUser(user));
    setOpenDetailUserDialog(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserEdit = (user) => {
    dispatch(setCurrentUser(user));
    setOpenUserDialog(true);
  };

  const handleUserDelete = (user) => {};

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const onSearchChipDelete = () => {
    setSearchTerm("");
  };
  const usersListFiltred = searchUserWithFistNameLastName(
    users,
    searchTerm,
    "firstName",
    "lastName"
  );
  const intl = useIntl();

  const noRecordFoundMessage = intl.formatMessage({ id: 'NoRecordFound' });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          selected={selected}
          setSelected={setSelected}
          onAdd={setOpenUserDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleSearch={handleSearch}
          onSearchChipDelete={onSearchChipDelete}
          hedearToolbar={hedearToolbar}
          withOutSelectedFilter={true}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <TableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {!!usersListFiltred.length ? (
                stableSort(usersListFiltred, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <ListRow
                      key={index}
                      row={row}
                      onUserEdit={handleUserEdit}
                      onUserDelete={handleUserDelete}
                      onUserView={handleUserDetail}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    {isFilterApplied ? (
                      <NoRecordFound>
                        <IntlMessages id="NoRecordFound.with.filter" />
                      </NoRecordFound>
                    ) : (
                      <NoRecordFound>
                        <IntlMessages id="NoRecordFound" />
                      </NoRecordFound>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={usersListFiltred.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage={
            <IntlMessages id="app.module.pagination.numberOfLines" />
          }
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${noRecordFoundMessage} ${count}`
          }
        />
      </Paper>
      {openUserDialog && (
        <AddEditUser
          open={openUserDialog}
          onCloseDialog={handleCloseUserDialog}
          establishmentsList={establishmentsList}
        />
      )}
      {openDetailUserDialog && (
        <DetailUser
          open={openDetailUserDialog}
          establishmentsList={establishmentsList}
          onCloseDialog={handleCloseDetailUserDialog}
        />
      )}
    </div>
  );
};

export default AccountManagement;
