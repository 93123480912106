import moment from "moment";
import {
  ADD_SESSION,
  DELETE_SESSION,
  EDIT_SESSION,
  GET_SESSIONS,
  SET_SESSION_DETAILS,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  sessions: [],
  currentSession: null,
  maxDate: moment().format(), minDate: moment().format(),
};

const Session = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SESSIONS: {
      return {
        ...state,
        sessions: action.payload,
        maxDate:action.payload.length&& moment(
          action.payload[action.payload?.length - 1].endDate
        ).format(),
        minDate: action.payload.length&&moment(action.payload[0].startDate).format(),
      };
    }
    case SET_SESSION_DETAILS: {
      return {
        ...state,
        currentSession: action.payload,
      };
    }
    case ADD_SESSION: {
      return {
        ...state,
        sessions: [action.payload, ...state.sessions],
      };
    }
    case EDIT_SESSION: {
      return {
        ...state,
        sessions: state.sessions.map((SESSION) =>
          SESSION.id === action.payload.id ? action.payload : SESSION
        ),
      };
    }
    case DELETE_SESSION: {
      return {
        ...state,
        sessions: state.sessions.filter(
          (SESSION) => SESSION.id !== action.payload
        ),
      };
    }

    default:
      return state;
  }
};
export default Session;
