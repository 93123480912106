import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AuhMethods } from "services/auth";

export default function Callback() {
  const dispatch = useDispatch();

  useEffect(() => {
     
    if (window.location.hash) {
      var hash = window.location.hash.substring(1);
      let token = hash.toString();
      let decoded = token.match("access_token=(.*)");
      window.localStorage.setItem("first478loi52", decoded[1]);
      if (decoded[1]) {
        dispatch(AuhMethods["jwtAuth"].getDataToken(decoded[1]));
      }
    }
  }, [dispatch]);

  return <div> </div>;
}
