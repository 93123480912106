import {
    ADD_ADMIN_LOCAL,
    GET_ADMINS_LOCAL,
    SET_ADMIN_LOCAL_DETAILS,
    EDIT_ADMIN_LOCAL,
    DELETE_ADMIN_LOCAL
  } from '../../@jumbo/constants/ActionTypes';


  const INIT_STATE = {
    adminsLocal: [],
    currentAdminLocal: null,
  };
  
const AdminLocal = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ADMINS_LOCAL: {
        return {
          ...state,
          adminsLocal: action.payload,
        };
      }
      case SET_ADMIN_LOCAL_DETAILS: {
        return {
          ...state,
          currentAdminLocal: action.payload,
        };
      }
      case ADD_ADMIN_LOCAL: {
        return {
          ...state,
          adminsLocal: [action.payload, ...state.adminsLocal],
        };
      }
      case EDIT_ADMIN_LOCAL: {
        return {
          ...state,
          adminsLocal: state.adminsLocal.map(user => (user.id === action.payload.id ? action.payload : user)),
        };
      }
      case DELETE_ADMIN_LOCAL: {
        return {
          ...state,
          adminsLocal: state.adminsLocal.filter(user => user.id !== action.payload),
        };
      }
     
      default:
        return state;
    }
  };
  
export default AdminLocal