import React from "react";
// import ReactDOM from 'react-dom';
import * as serviceworker from "./serviceworker";
import App from "./App";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// ReactDOM.render(<App />, document.getElementById('root'));
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
 root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceworker.register();
