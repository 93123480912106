import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Box,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
} from "@material-ui/core";
import {
  emailNotValid,
  requiredMessage,
  invalidRib,
  isInvalidFormatUrl,
} from "@jumbo/constants/ErrorMessages";
import PropTypes from "prop-types";
import useStyles from "../../CommonComponents/Style/TypoModelStyle";
import { isValidEmail } from "@jumbo/utils/commonHelper";
import { useDropzone } from "react-dropzone";
import {
  addNewEstablishment,
  updateEstablishment,
} from "../../../redux/actions/Establishments";
import IntlMessages from "@jumbo/utils/IntlMessages";
import "moment/locale/fr";
import close from "../../../assets/images/close.svg";
import { isValidUrl } from "@jumbo/utils/commonHelper";
import GeneralInformation from "./GeneralInformation";
import LegalInformation from "./LegalInformation";
import BankInformation from "./BankInformation";
import ContactInformation from "./ContactInformation";
import CategoryInformation from "./CategoryInformation";
import { Countries } from "Config/countriesList";

let defaultData = {
  name: "",
  phone: "",
  email: "",
  webSite: "",
  roadNumber: "",
  roadAddress: "",
  zipCode: "",
  delegation: "",
  city: "",
  country: "",
  taxMember: "",
  taxPatent: "registre de commerce.pdf",
  tradeRegister: "patente.pdf",
  signedAgreement: "convention.pdf",
  agreementSignatureDate: new Date(),
  agreementEndDate: new Date(),
  bankName: "",
  rib: "",
  bic: "",
  firstNameContact: "",
  lastNameContact: "",
  phoneContact: "",
  emailContact: "",
  educationType: undefined,
  establishmentType: "complex",
  educationSystem: "",
  videoConferenceType: "",
};
const AddEditEstablishment = ({ open, onCloseDialog, disabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { establishments, currentEstablishment } = useSelector(
    ({ EstablishmentsReducer }) => EstablishmentsReducer
  );

  const [establishmentData, setEstablishmentData] = useState(defaultData);
  const [establishmentValidation, setEstablishmentValidation] = useState({});
  const [logo, setLogo] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [listCity, setListCity] = useState("");
  const [taxPatentName, setTaxPatentName] = useState("");
  const [tradeRegisterName, setTradeRegistertName] = useState("");
  const [signedAgreementName, setSignedAgreementName] = useState("");
  const [listBanks, setListBanks] = useState([]);
  const [legalInformation, setLegalInformation] = useState("SIREN / SIRET");
  const [country, setCountry] = useState(`fr`);

  //--------------------------------------------------------------------------
  useEffect(() => {
    if (currentEstablishment) {
      currentEstablishment.logo && setLogo(currentEstablishment.logo);
      setLegalInformation(
        Countries.find((ele) => ele.slug === currentEstablishment.country)
          ?.legalInformation
      );
      setListCity(
        Countries.find((ele) => ele.slug === currentEstablishment.country)
          ?.citylist ?? []
      );
      setListBanks(
        Countries.find((ele) => ele.slug === currentEstablishment.country)
          ?.banks ?? []
      );
      setEstablishmentData({
        ...currentEstablishment,
        establishementParent:
          currentEstablishment.establishmentType === "simple"
            ? establishments.find(
                (establishment) =>
                  establishment.establishmentType === "complex" &&
                  establishment.id === currentEstablishment.establishmentId
              )
            : undefined,
      });
    }
  }, [dispatch, currentEstablishment, establishments]);

  //--------------------------------------------------------------------------

  const { getRootProps, getInputProps } = useDropzone({
    noClick: disabled,
    accept: { "image/png": [".png"] },
    onDrop: (acceptedFiles) => {
      setLogo(URL.createObjectURL(acceptedFiles[0]));
      setLogoUrl(acceptedFiles[0]);
    },
  });
  const onAddAttachments = (files, type) => {
    const newAttachments = files.map((item) => {
      return {
        id: item.id,
        originalFile: item.file,
        file: {
          name: item.file.name,
          type: item.file.type,
          size: item.file.size,
          path: item.file.path,
        },
      };
    });

    switch (type) {
      case "tradeRegisterName":
        return setTradeRegistertName([newAttachments[0].file.name]);
      case "signedAgreementName":
        return setSignedAgreementName([...newAttachments[0].file.name]);
      case "taxPatentName":
        return setTaxPatentName([...newAttachments[0].file.name]);

      default:
        return null;
    }
  };

  const onDeleteAttachments = (type) => {
    switch (type) {
      case "tradeRegisterName":
        return setTradeRegistertName("");
      case "signedAgreementName":
        return setSignedAgreementName("");
      case "taxPatentName":
        return setTaxPatentName("");

      default:
        return null;
    }
  };

  const onSubmitClick = () => {
    let validToSubmit = true;
    let validation = {};
    Object.keys(establishmentData).map((e) => {
      if (
        e !== "establishementParent" &&
        e !== "educationType" &&
        e !== "logo" &&
        e !== "videoConferenceType" &&e!=="establishmentStatus"&&
        e !== "webSite" &&
        !establishmentData[e]
      ) {
        validation = { ...validation, [e]: requiredMessage };
        validToSubmit = false;
      }
      if (
        e === "educationType" &&
        establishmentData.establishmentType === "simple" &&
        !establishmentData[e]
      ) {
        validation = { ...validation, [e]: requiredMessage };
        validToSubmit = false;
      }
      return null;
    });
    if (!isValidEmail(establishmentData.email)) {
      validation = { ...validation, email: emailNotValid };
      validToSubmit = false;
    }
    if (!isValidEmail(establishmentData.emailContact)) {
      validation = { ...validation, emailContact: emailNotValid };
      validToSubmit = false;
    }

    if (
      establishmentData.rib.length < 14 ||
      establishmentData.rib.length > 35
    ) {
      validation = { ...validation, rib: invalidRib };
      validToSubmit = false;
    }
    if (
      establishmentData.webSite !== "" &&
      !isValidUrl(establishmentData.webSite)
    ) {
      validation = { ...validation, webSite: isInvalidFormatUrl };
      validToSubmit = false;
    }
    if (
      establishmentData.establishmentType === "simple" &&
      !establishmentData.videoConferenceType
    ) {
      validation = { ...validation, videoConferenceType: requiredMessage };
      validToSubmit = false;
    }

    setEstablishmentValidation(validation);
    if (validToSubmit) {
      onEstablishmentSave();
    }
  };

  const onEstablishmentSave = () => {
    let data =
      establishmentData.establishmentType === "simple"
        ? {
            ...establishmentData,
            establishmentId: establishmentData.establishementParent?.id,
          }
        : {
            ...establishmentData,
            establishmentId: undefined,
            videoConferenceType: undefined,
          };

    if (currentEstablishment) {
      let establishmentId = currentEstablishment.id;
      dispatch(
        updateEstablishment(
          {
            ...data,
            establishmentId: currentEstablishment.establishmentId,
          },
          establishmentId,
          logoUrl,
          () => {
            onCloseDialog();
          }
        )
      );
    } else {
      dispatch(
        addNewEstablishment(data, logoUrl, () => {
          onCloseDialog();
        })
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          onCloseDialog();
        }
      }}
      className={classes.dialogRoot}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        <Grid container justifyContent="space-between" alignItems="center">
          {currentEstablishment && disabled === true ? (
            <IntlMessages id="appModule.establishment.view" />
          ) : currentEstablishment && disabled === false ? (
            <IntlMessages id="appModule.establishment.edit" />
          ) : (
            <IntlMessages id="appModule.establishment.add" />
          )}
          <img
            onClick={onCloseDialog}
            src={close}
            alt="img"
            style={{ textAlign: "end", cursor: "pointer" }}
          />
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        {/* SECTION GENERAL INFORMATION */}

        <GeneralInformation
          logoUrl={logo}
          disabled={disabled}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          listCity={listCity}
          setListCity={setListCity}
          establishmentData={establishmentData}
          establishmentValidation={establishmentValidation}
          setEstablishmentData={setEstablishmentData}
          setEstablishmentValidation={setEstablishmentValidation}
          setListBanks={setListBanks}
          setLegalInformation={setLegalInformation}
          setCountry={setCountry}
          country={country}
        />

        {/* SECTION LEGAL INFORMATION */}

        <LegalInformation
          disabled={disabled}
          taxPatentName={taxPatentName}
          tradeRegisterName={tradeRegisterName}
          signedAgreementName={signedAgreementName}
          establishmentValidation={establishmentValidation}
          onAddAttachments={onAddAttachments}
          onDeleteAttachments={onDeleteAttachments}
          establishmentData={establishmentData}
          setEstablishmentData={setEstablishmentData}
          setEstablishmentValidation={setEstablishmentValidation}
          legalInformation={legalInformation}
        />

        {/* SECTION BANK DATA */}

        <BankInformation
          disabled={disabled}
          establishmentValidation={establishmentValidation}
          establishmentData={establishmentData}
          setEstablishmentData={setEstablishmentData}
          setEstablishmentValidation={setEstablishmentValidation}
          listBanks={listBanks}
        />

        {/* SECTION CONTACT INFORMATION*/}

        <ContactInformation
          disabled={disabled}
          establishmentValidation={establishmentValidation}
          establishmentData={establishmentData}
          setEstablishmentData={setEstablishmentData}
          setEstablishmentValidation={setEstablishmentValidation}
          country={country}
        />

        {/* SECTION CATÉGORIE INFORMATIONS */}
        <CategoryInformation
          currentEstablishment={currentEstablishment}
          disabled={disabled}
          establishmentValidation={establishmentValidation}
          establishmentData={establishmentData}
          setEstablishmentData={setEstablishmentData}
          setEstablishmentValidation={setEstablishmentValidation}
        />

        {/*--------------------------------- submit bouton  ------------------*/}
        <Box display="flex" justifyContent="flex-end" mt={6} mb={6}>
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button
              onClick={onCloseDialog}
              color="primary"
              className={classes.cancelButton}
              variant="outlined"
            >
              {" "}
              <IntlMessages id="appModule.cancel" />
            </Button>
          </Box>
          {!disabled ? (
            <Box ml={2}>
              <Button
                id="cancelbtn"
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
                className={classes.saveButton}
                // disabled={!establishmentName ? true : false}
              >
                {currentEstablishment ? (
                  <IntlMessages id="app.edit" />
                ) : (
                  <IntlMessages id="app.add" />
                )}
              </Button>
            </Box>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditEstablishment;

AddEditEstablishment.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
