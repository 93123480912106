import React, {useEffect, useState} from 'react';
import {Paper, Table, TableCell, TableContainer, TableRow} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import EstablishmentListRow from './EstablishmentListRow';
import EstablishmentTableHead from './EstablishmentTableHead';
import EstablishmentTableToolbar from './EstablishmentTableToolbar';
import {getComparator, stableSort} from '../../@jumbo/utils/tableHelper';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteEstablishment,
  getEstablishments,
  setCurrentEstablishment,
  setCurrentEstablishmentAction,
} from '../../redux/actions/Establishments';
import AddEditEstablishment from './AddEditEstablishment';
import ConfirmDialog from '../../@jumbo/components/Common/ConfirmDialog';
import {useDebounce, search} from '../../@jumbo/utils/commonHelper';
import useStyles from '../CommonComponents/Style/TableStyle';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import NoRecordFound from 'routes/CommonComponents/NoRecordFound';
 
const EstablishmentManagement = () => {
  const classes = useStyles();
  const {establishments} = useSelector(({EstablishmentsReducer}) => EstablishmentsReducer);

  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [openEstablishmentDialog, setOpenEstablishmentDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedEstablishment, setSelectedEstablishment] = useState({
    name: '',
  });
   const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [disabled, setDisabled] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getEstablishments(filterOptions, debouncedSearchTerm, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
       })
    );
  }, [dispatch, filterOptions, debouncedSearchTerm]);

  const handleCloseEstablishmentDialog = () => {
    setOpenEstablishmentDialog(false);
    dispatch(setCurrentEstablishment(null));
    setDisabled(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = establishments.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEstablishmentView = (establishment, action) => {
    dispatch(setCurrentEstablishmentAction(action));
    dispatch(setCurrentEstablishment(establishment));
    setDisabled(true);
    setOpenEstablishmentDialog(true);
  };

  const handleEstablishmentEdit = (establishment, action) => {
    dispatch(setCurrentEstablishmentAction(action));
    dispatch(setCurrentEstablishment(establishment));
    setOpenEstablishmentDialog(true);
  };

  const handleEstablishmentDelete = (establishment) => {
    setSelectedEstablishment(establishment);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteEstablishment(selectedEstablishment.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const onSearchChipDelete = () => {
    setSearchTerm('');
  };

  const establishmentsList = search(establishments, searchTerm, 'name',filterOptions,'type');
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EstablishmentTableToolbar
          selected={selected}
          setSelected={setSelected}
          onEstablishmentAdd={setOpenEstablishmentDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleSearch={handleSearch}
          onSearchChipDelete={onSearchChipDelete}
         />
        <TableContainer className={classes.container}>
          <Table stickyHeadersetOpenUserDialog aria-label="sticky enhanced table">
            <EstablishmentTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={establishmentsList.length}
            />
            <TableBody>
              {!!establishmentsList.length ? (
                stableSort(establishmentsList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <EstablishmentListRow
                      key={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onEstablishmentEdit={handleEstablishmentEdit}
                      onEstablishmentDelete={handleEstablishmentDelete}
                      onEstablishmentView={handleEstablishmentView}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                <TableRow style={{height: 53 * 6}}>
                  <TableCell colSpan={7} rowSpan={10}>
                  {isFilterApplied ? (
                      <NoRecordFound>
                        <IntlMessages id="NoRecordFound.with.filter" />
                      </NoRecordFound>
                    ) : (
                      <NoRecordFound>
                        <IntlMessages id="NoRecordFound" />
                      </NoRecordFound>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={establishmentsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage={
            <IntlMessages id="app.module.pagination.numberOfLines" />
          }
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} sur ${count}`
          }
        />
      </Paper>

      {openEstablishmentDialog && (
        <AddEditEstablishment
          disabled={disabled}
          open={openEstablishmentDialog}
          onCloseDialog={handleCloseEstablishmentDialog}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete ${selectedEstablishment.name}`}
        content={'Are you sure, you want to  delete this establishment?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default EstablishmentManagement;
