
import {
  GET_OPERATOR_PERMISSION,
  UPDATE_OPERATOR_PERMISSION,
} from "@jumbo/constants/ActionTypes"

const INIT_STATE = {  
  permission_operators: [],
  permissions: [
    {
      permission: "Créer, modifier ou supprimer les utilisateurs",
      operators: [
        {
          role: "financial",
          checked: false,
        },
        {
          role: "administrative",
          checked: true,
        },
        {
          role: "driver",
          checked: false,
        },
        {
          role: "nurse",
          checked: false,
        },
        {
          role: "cook",
          checked: false,
        },
        {
          role: "HR",
          checked: false,
        },
      ],
    },
    {
      permission: "Définir les rôles pour chaque utilisateur",
      operators: [
        {
          role: "financial",
          checked: false,
        },
        {
          role: "administrative",
          checked: false,
        },
        {
          role: "driver",
          checked: false,
        },
        {
          role: "nurse",
          checked: false,
        },
        {
          role: "cook",
          checked: false,
        },
        {
          role: "HR",
          checked: false,
        },
      ],
    },
    {
      permission: "Créer, modifier les emplois du temps",
      operators: [
        {
          role: "financial",
          checked: false,
        },
        {
          role: "administrative",
          checked: true,
        },
        {
          role: "driver",
          checked: false,
        },
        {
          role: "nurse",
          checked: false,
        },
        {
          role: "cook",
          checked: false,
        },
        {
          role: "HR",
          checked: false,
        },
      ],
    },
    {
      permission: "Accès aux taches de vie scolaire et professeurs",
      operators: [
        {
          role: "financial",
          checked: false,
        },
        {
          role: "administrative",
          checked: true,
        },
        {
          role: "driver",
          checked: false,
        },
        {
          role: "nurse",
          checked: false,
        },
        {
          role: "cook",
          checked: false,
        },
        {
          role: "HR",
          checked: true,
        },
      ],
    },
    {
      permission: "Choisir la maquette des bulletins",
      operators: [
        {
          role: "financial",
          checked: false,
        },
        {
          role: "administrative",
          checked: true,
        },
        {
          role: "driver",
          checked: false,
        },
        {
          role: "nurse",
          checked: false,
        },
        {
          role: "cook",
          checked: false,
        },
        {
          role: "HR",
          checked: false,
        },
      ],
    },
    {
      permission: "Imprimer les bulletins",
      operators: [
        {
          role: "financial",
          checked: false,
        },
        {
          role: "administrative",
          checked: true,
        },
        {
          role: "driver",
          checked: false,
        },
        {
          role: "nurse",
          checked: false,
        },
        {
          role: "cook",
          checked: false,
        },
        {
          role: "HR",
          checked: false,
        },
      ],
    },
    {
      permission: "Accès total à la base de données",
      operators: [
        {
          role: "financial",
          checked: false,
        },
        {
          role: "administrative",
          checked: true,
        },
        {
          role: "driver",
          checked: false,
        },
        {
          role: "nurse",
          checked: false,
        },
        {
          role: "cook",
          checked: false,
        },
        {
          role: "HR",
          checked: true,
        },
      ],
    },
    {
      permission: "Communiquer avec les différents utilisateurs",
      operators: [
        {
          role: "financial",
          checked: false,
        },
        {
          role: "administrative",
          checked: true,
        },
        {
          role: "driver",
          checked: false,
        },
        {
          role: "nurse",
          checked: false,
        },
        {
          role: "cook",
          checked: false,
        },
        {
          role: "HR",
          checked: true,
        },
      ],
    },
    {
      permission: "Valider les absences et les motifs d’absence",
      operators: [
        {
          role: "financial",
          checked: false,
        },
        {
          role: "administrative",
          checked: true,
        },
        {
          role: "driver",
          checked: false,
        },
        {
          role: "nurse",
          checked: false,
        },
        {
          role: "cook",
          checked: false,
        },
        {
          role: "HR",
          checked: false,
        },
      ],
    },
    {
      permission: "Créer et associer les sanctions",
      operators: [
        {
          role: "financial",
          checked: false,
        },
        {
          role: "administrative",
          checked: true,
        },
        {
          role: "driver",
          checked: false,
        },
        {
          role: "nurse",
          checked: false,
        },
        {
          role: "cook",
          checked: false,
        },
        {
          role: "HR",
          checked: false,
        },
      ],
    },
  ],
  currentPermissions: null,
};

const OperatorPermissions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OPERATOR_PERMISSION: {
      return {
        ...state,
        permission_operators: action.payload,
      };
    }
    case UPDATE_OPERATOR_PERMISSION: {
      return {
        ...state,
        permission_operators: action.payload,
      };
    }
   
    default:
      return state;
  }
};
export default OperatorPermissions 