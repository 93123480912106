import TextField from "@material-ui/core/TextField";
import { styled
 } from "@material-ui/styles";
const CustomTextField = styled(TextField)({
    minWidth :"100%",
    '& label.Mui-focused': {
      color: '#5B13B46E',
    },
    '& .MuiOutlinedInput-root': {

      '& fieldset': {
        borderColor: '#C4C4C4',
        
      },
      '&:hover fieldset': {
        borderColor: '#5B13B46E',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#5B13B46E',
      },
    },

  });
  export default CustomTextField