const {
  GET_REPORTS_RECRUITEMENT,
  GET_REPORTS_ASSIDUITY,
  GET_REPORTS_REACLAMATION,
  LOAD_REPORT_REACLAMATION,
  STOP_LOAD_REPORT_REACLAMATION,
  GET_REPORTS_HARASSEMENT,
  GET_REPORTS_SANCTIONS,
  GET_REPORTS_ASSIDUITY_PROF,
  GET_REPORTS_CLUBS,
  GET_REPORTS_NUMBER_CLASSROOMS,
  GET_REPORTS_NUMBER_CLASSES,
} = require("@jumbo/constants/ActionTypes");

const INIT_STATE = {
  ReportsRecruitement: [],
  ReportsAssiduity: [],
  ReportClaims: [],
  ReportHarrasements: [],
  ReportSanctions: [],
  loadReclamation: false,
  teacherAttendanceReports: [],
  clubsReports: [],
  ClassRoomsNumber: [],
  ClassesNumber: [],
};

const Reports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTS_RECRUITEMENT: {
      return {
        ...state,
        ReportsRecruitement: action.payload,
      };
    }
    case GET_REPORTS_ASSIDUITY: {
      return {
        ...state,
        ReportsAssiduity: action.payload,
      };
    }
    case GET_REPORTS_ASSIDUITY_PROF: {
      return {
        ...state,
        teacherAttendanceReports: action.payload,
      };
    }
    // --- claims store
    case GET_REPORTS_REACLAMATION: {
      return {
        ...state,
        ReportClaims: action.payload,
      };
    }
    case LOAD_REPORT_REACLAMATION: {
      return {
        ...state,
        loadReclamation: true,
      };
    }
    case STOP_LOAD_REPORT_REACLAMATION: {
      return {
        ...state,
        loadReclamation: false,
      };
    }
    // --- harrasements store
    case GET_REPORTS_HARASSEMENT: {
      return {
        ...state,
        ReportHarrasements: action.payload,
      };
    }
    // --- sanctions store
    case GET_REPORTS_SANCTIONS: {
      return {
        ...state,
        ReportSanctions: action.payload,
      };
    }
    case GET_REPORTS_CLUBS: {
      return {
        ...state,
        clubsReports: action.payload,
      };
    }
    // --- classrooms number store
    case GET_REPORTS_NUMBER_CLASSROOMS: {
      return {
        ...state,
        ClassRoomsNumber: action.payload,
      };
    }

    case GET_REPORTS_NUMBER_CLASSES: {
      return {
        ...state,
        ClassesNumber: action.payload,
      };
    }

    default:
      return state;
  }
};

export default Reports;
