import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { Box, Hidden, withWidth } from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { alpha, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import CmtDropdownMenu from "../../../../../../@coremat/CmtDropdownMenu";
import CmtAvatar from "../../../../../../@coremat/CmtAvatar";
import SidebarToggleHandler from "../../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler";
import LayoutContext from "../../../../../../@coremat/CmtLayouts/LayoutContext";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import { roleIdADL, roleIdOperator, roleIdSAG, roleIdSAL } from "Config";
import Logo from "../../../partials/Logo";
import HeaderNotifications from "@jumbo/components/AppLayout/partials/Header/HeaderNotifications";
import { addNotification, getNotification } from "redux/actions/Notification";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "10px 24px 10px 15px",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "column",
      padding: "16px 5px",
    },
    alignItems: "center",
    backgroundColor: "#5B13B4",
    color: theme.palette.common.white,
  },
  iconBtn: {
    position: "relative",
    color: alpha(theme.palette.common.white, 0.9),
    "&:hover, &:focus": {
      color: theme.palette.common.white,
    },
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20,
  },
}));

const actionsList = [
  {
    id: "profile",
    icon: <PersonIcon />,
    label: <IntlMessages id="popup.profile" />,
  },
  {
    id: "logout",
    icon: <ExitToAppIcon />,
    label: <IntlMessages id="popup.logout" />,
  },
];

let initSidebarWidth = 0;
const ActionSideBar = ({ width }) => {
  const classes = useStyles();
  const { socket } = useSelector(({ SocketIOReducer }) => SocketIOReducer);

  // const dispatch = useDispatch();
  const [isDrawerOpen, setDrawerStatus] = useState(false);
  const [activeOption] = useState(null);
  const { userProfile } = useSelector(({ auth }) => auth);
  const [photo, setPhoto] = useState("");
  const { isSidebarOpen, sidebarWidth, setSidebarWidth } = useContext(
    LayoutContext
  );

  useEffect(() => {
    initSidebarWidth = sidebarWidth;
    if (userProfile) {
      setPhoto(userProfile.photo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    if (isSidebarOpen && (width === "lg" || width === "xl")) {
      setSidebarWidth(0);
    } else {
      setSidebarWidth(initSidebarWidth);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarOpen, width, setSidebarWidth]);

  useEffect(() => {
    if (activeOption && !isDrawerOpen) {
      setDrawerStatus(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOption, isDrawerOpen]);

  const onItemClick = (item) => {
    if (item.id === "logout") {
      if (
        window.localStorage.salETQ12gtu47 &&
        window.location.pathname.toString().search("/sag/") === -1
      ) {
        localStorage.removeItem("salETQ12gtu47");
        window.location.href = "/app/sag/connection-as";
      } else if (
        window.localStorage.userETQsal14mp52 &&
        window.location.pathname.toString().search("/superadmin/") === -1
      ) {
        localStorage.removeItem("userETQsal14mp52");
        localStorage.removeItem("subEstabETQUser45lo54");
        window.location.href = "/app/superadmin/dashboard";
      } else {
        localStorage.removeItem("subEstabETQUser45lo54");
        localStorage.removeItem("userETQsal14mp52");
        localStorage.removeItem("salETQ12gtu47");
        localStorage.removeItem("first478loi52");
        localStorage.removeItem("subEstab45lo54");
        document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
        // window.location.href = '/signin';
      }
    } else {
      if (userProfile?.roleId === roleIdADL) {
        window.location.href = "/app/admin/profile";
      } else if (userProfile?.roleId === roleIdOperator) {
        window.location.href = "/app/operator/profile";
      } else if (userProfile?.roleId === roleIdSAG) {
        window.location.href = "/app/sag/profile";
      } else {
        window.location.href = "/app/superadmin/profile";
      }
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (userProfile.roleId === roleIdSAL || userProfile.roleId === roleIdADL) {
      socket &&
        socket.on(
          `subEstablishmentId_${userProfile.subEstablishmentId}_userId_${userProfile.id}`,
          (message) => {
            let audio = new Audio();
            audio.src =
              "https://educap-storage.s3.eu-west-3.amazonaws.com/notification.mp3";
            audio.load();
            audio.play();
            var data = {
              id: message?.data?.id,
              message: <IntlMessages id="alert.harassment" />,
              status: "Unread",
              user: {
                name:
                  message.data.user.firstName +
                  " " +
                  message.data.user.lastName,
                profile_pic: message.data.user?.photo
                  ? message.data.user.photo
                  : "",
              },
              type: "POSTING",

              createdAt: message.date,
            };
            dispatch(addNotification(data));
          }
        );
      !window.localStorage.salETQ12gtu47 && dispatch(getNotification());
    }
  }, [dispatch, userProfile, socket]);

  return (
    <div className={clsx(classes.root, "actionSidebar")}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SidebarToggleHandler className={classes.iconBtn}>
          {isSidebarOpen && (width === "lg" || width === "xl") && <CloseIcon />}
        </SidebarToggleHandler>
        <Hidden lgUp>
          <Logo color="white" ml={{ xs: 3, sm: 6 }} />
        </Hidden>
      </div>
      <Box
        display="flex"
        flexDirection={{ xs: "row", lg: "column" }}
        ml={{ xs: "auto", lg: "unset" }}
      ></Box>{" "}
      {userProfile.roleId === roleIdSAL ||
      userProfile.roleId === roleIdOperator ||
      userProfile.roleId === roleIdADL ? (
        <HeaderNotifications />
      ) : (
        ""
      )}
      <Box
        display="flex"
        flexDirection={{ xs: "row", lg: "column" }}
        mt={{ xs: "unset", lg: "auto" }}
      >
        {/* <IconButton
          className={classes.iconBtn}
          onClick={() => onIconClick("settings")}
        >
          <SettingsIcon />
        </IconButton> */}

        <CmtDropdownMenu
          onItemClick={onItemClick}
          triggerComponent={<CmtAvatar src={photo} alt="" />}
          items={
            userProfile.roleId === roleIdSAG ? [actionsList[1]] : actionsList
          }
          sidebar={true}
        />
      </Box>
    </div>
  );
};

export default withWidth()(ActionSideBar);
