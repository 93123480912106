import {
  ADD_SUBJECT,
  DELETE_SUBJECT,
  EDIT_SUBJECT,
  GET_SUBJECTS,
  SET_SUBJECT_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  subjects: [],
  currentSubject: null
};

const Subject = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBJECTS: {
      return {
        ...state,
        subjects: action.payload
      };
    }
    case SET_SUBJECT_DETAILS: {
      return {
        ...state,
        currentSubject: action.payload
      };
    }
    case ADD_SUBJECT: {
      return {
        ...state,
        subjects: [ action.payload, ...state.subjects ]
      };
    }
    case EDIT_SUBJECT: {
      return {
        ...state,
        subjects: state.subjects.map(
          SUBJECT => (SUBJECT.id === action.payload.id ? action.payload : SUBJECT)
        )
      };
    }
    case DELETE_SUBJECT: {
      return {
        ...state,
        subjects: state.subjects.filter(SUBJECT => SUBJECT.id !== action.payload)
      };
    }

    default:
      return state;
  }
};
export default Subject