import React, { useContext } from 'react';

import { makeStyles, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import CmtGridView from '../../../../../@coremat/CmtGridView';
import CmtImage from '../../../../../@coremat/CmtImage';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import AppContext from '../../../contextProvider/AppContextProvider/AppContext';
import { THEME_TYPES } from '../../../../constants/ThemeOptions';
import { THEME_TYPE_OPTIONS } from '../../../../constants/CustomizerOptions';
import IntlMessages from '@jumbo/utils/IntlMessages';
const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: '10px',
      fontWeight : 600,
    },
    '& .Cmt-card-content': {
      //padding: 16,
      display:"flex",
      justifyContent : "center"
    },
  },
  checkIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fill: theme.palette.success.main,
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    '&:hover': {
      backgroundColor: "#E8E7F6",
    },
    textTransform : "none"
  },
}))(Button);

const Theme = () => {
  const classes = useStyles();
  const { themeType, setThemeType } = useContext(AppContext);

  const onResetTheme = () => {
    setThemeType(THEME_TYPES.LIGHT);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader style={{marginTop:"0px" ,paddingBottom: 0}} title={<IntlMessages id="app.cuztomizer.themeTitle" />} >
        <ColorButton onClick={onResetTheme}><IntlMessages id="app.cuztomizer.resetButton"/></ColorButton>
      </CmtCardHeader>
      <CmtCardContent>
        <CmtGridView
          itemPadding={16}
          data={THEME_TYPE_OPTIONS}
          renderRow={(item, index) => (
            <div className="pointer" onClick={() => setThemeType(item.type)}>
              <div style={{ position: 'relative' }}>
                <CmtImage key={index} src={item.image} alt={item.title} />
                {themeType === item.type && (
                  // <h1>{themeType} | {item.type}</h1>
                  <CheckCircleIcon className={classes.checkIcon} />
                )}
              </div>
              <div style={{color : "rgba(0, 0, 0, 0.6)"}} className="pt-2">{item.title}</div>
            </div>
          )}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default Theme;
