import {
  ADD_BUS,
  GET_BUS,
  DELETE_BUS,
  EDIT_BUS,
  SET_BUS_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  buses: [],
  currentBus: null,
};

const Bus = (state = INIT_STATE, action) => {
  
  switch (action.type) {
    case GET_BUS: {
      return {
        ...state,
        buses: action.payload,
      };
    }
    case ADD_BUS: {
      return {
        ...state,
        buses: [ action.payload, ...state.buses ]
      };
    }
    case EDIT_BUS: {
      return {
        ...state,
        buses: state.buses.map(
          BUS => (BUS.id === action.payload.id ? action.payload : BUS)
        )
      };
    }

    case DELETE_BUS: {
      return {
        ...state,
        buses: state.buses.filter(BUS => BUS.id !== action.payload)
      };
    }

    case SET_BUS_DETAILS: {
      return {
        ...state,
        currentBus: action.payload
      };
    }
    default:
      return state;
  }
};
export default Bus;
