import enLang from "./entries/en-US";
import arLang from "./entries/ar_SA";
import frLang from "./entries/fr_FR";

const AppLocale = {
  en: enLang,
  ar: arLang,
  fr: frLang
};

export default AppLocale;
