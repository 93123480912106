export const Countries = [

  {
    id: "France",
    slug: "France",
    citylist: [
      { slug: "Paris" },
      { slug: "Marseille" },
      { slug: "Lyon" },
      { slug: "Toulouse" },
      { slug: "Nice" },
      { slug: "Nantes" },
      { slug: "Montpellier" },
      { slug: "Strasbourg" },
      { slug: "Bordeaux" },
      { slug: "Lille" },
      { slug: "Rennes" },
      { slug: "Reims" },
      { slug: "Toulon" },
      { slug: "Saint-Étienne" },
      { slug: "Dijon" },
      { slug: "Grenoble" },
      { slug: "Angers" },
      { slug: "Nîmes" },
      { slug: "Brest" },
    ],

    banks: [
      { title: "BNP Paribas", slug: "BNP Paribas" },
      { title: "Société Générale", slug: "Société Générale" },
      { title: "Crédit Agricole", slug: "Crédit Agricole" },
      { title: "Groupe BPCE", slug: "Groupe BPCE" },
      { title: "La Banque Postale", slug: "La Banque Postale" },
      { title: "Crédit Mutuel", slug: "Crédit Mutuel" },
      { title: "HSBC France", slug: "HSBC France" },
      { title: "CIC", slug: "CIC" },
      { title: "Natixis", slug: "Natixis" },
      { title: "Banque Populaire", slug: "Banque Populaire" },
      { title: "Autre", slug: "autre" },
    ],
    legalInformation: "SIREN / SIRET",
    country: "fr",
  },
  {
    id: "Tunisie",
    slug: "Tunisie",
    citylist: [
      { slug: "Ariana" },
      { slug: "Béja" },
      { slug: "Ben Arous" },
      { slug: "Bizerte" },
      { slug: "Gabès" },
      { slug: "Gafsa" },
      { slug: "Jendouba" },
      { slug: "Kairouan" },
      { slug: "Kasserine" },
      { slug: "Kébili" },
      { slug: "Kef" },
      { slug: "Mahdia" },
      { slug: "Manouba" },
      { slug: "Médenine" },
      { slug: "Monastir" },
      { slug: "Nabeul" },
      { slug: "Sfax" },
      { slug: "Sidi Bouzid" },
      { slug: "Siliana" },
      { slug: "Sousse" },
      { slug: "Tataouine" },
      { slug: "Tozeur" },
      { slug: "Tunis" },
      { slug: "Zaghouan" },
    ],
    banks: [
      { title: "BIAT", slug: "biat" },
      { title: "UIB", slug: "uib" },
      { title: "BH", slug: "bh" },
      { title: "UBCI", slug: "ubci" },
      { title: "BT", slug: "bt" },
      { title: "ZITOUNA", slug: "zitouna" },
      { title: "BTk", slug: "btk" },
      { title: "Autre", slug: "autre" },
    ],
    legalInformation: "Matricule fiscal",
    country: "tn",

  },

];
