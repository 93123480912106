import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CmtVertical from "../../../../../@coremat/CmtNavigation/Vertical";
import {
  sidebarNavs,
  administrationWithSection,
  administrationWithSectionExpired,
} from "../menus";
import { roleIdOperator } from "Config";

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: "100%",
    transition: "all 0.3s ease",
    ".Cmt-sidebar-fixed &, .Cmt-Drawer-container &": {
      height: "calc(100% - 167px)",
    },
    ".Cmt-modernLayout &": {
      height: "calc(100% - 130px)",
    },
    ".Cmt-miniLayout &": {
      height: "calc(100% - 91px)",
    },
    ".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
      height: "calc(100% - 167px)",
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const [initMenu, setInitMenu] = useState([]);
  const schoolYearStatus = useSelector((state) => state?.auth?.selectedYear);
  const { subEstablishement } = useSelector(
    ({ EstablishmentsReducer }) => EstablishmentsReducer
  );
  const userProfile = useSelector(({ auth }) => auth.userProfile);

  useEffect(() => {
    if (
      subEstablishement?.educationType === "Université" ||
      subEstablishement?.educationType === "Lycée"
    ) {
      let listFiltred = sidebarNavs.map((item) =>
        item.pathName === "/administration"
          ? schoolYearStatus === "Expirée"
            ? administrationWithSectionExpired
            : administrationWithSection
          : (schoolYearStatus === "Expirée" &&
              (item.pathName === "/discussion-group" ||
                item.pathName === "/canteen" ||
                item.pathName === "/financial-settings" ||
                item.pathName === "/messaging")) ||
            (userProfile.roleId === roleIdOperator &&
              item.pathName === "/discussion-group")
          ? ""
          : item
      );
      setInitMenu(listFiltred);
    } else {
      setInitMenu(sidebarNavs);
    }
  }, [subEstablishement, schoolYearStatus, userProfile.roleId]);

  return (
    <PerfectScrollbar
      className={classes.perfectScrollbarSidebar}
    
    >
      <CmtVertical menuItems={initMenu} />
    </PerfectScrollbar>
  );
};

export default SideBar;
