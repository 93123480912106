import {
    ADD_NEWS,
    DELETE_NEWS,
    EDIT_NEWS,
    GET_NEWS,
    SET_NEWS_DETAILS
  } from "../../@jumbo/constants/ActionTypes";
  
  const INIT_STATE = {
    news: [],
    currentNews: null
  };
  
const News = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_NEWS: {
        return {
          ...state,
          news: action.payload 
        };
      }
      case SET_NEWS_DETAILS: {
        return {
          ...state,
          currentNews: action.payload
        };
      }
      case ADD_NEWS: {
        return {
          ...state,
          news: [  ...state.news ,action.payload]
        };
      }
      case EDIT_NEWS: {
        return {
          ...state,
          news: state.news.map(
            item => (item.id === action.payload.id ? action.payload : item)
          )
        };
      }
      case DELETE_NEWS: {
        return {
          ...state,
          news: state.news.filter(item => item.id !== action.payload)
        };
      }
  
      default:
        return state;
    }
  };
  export default News