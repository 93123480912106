import React from 'react';
import axios from 'axios';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {crudApp} from '../../services/crudApp';
import {
  ADD_USER,
  DELETE_BULK_USERS,
  DELETE_USER,
  EDIT_USER,
  GET_USERS,
  SET_USER_DETAILS,
  HISTORY_IMPORT,
} from '../../@jumbo/constants/ActionTypes';
import IntlMessages from '@jumbo/utils/IntlMessages';
import {AuhMethods} from '../../services/auth';

export const getUsers = (filterOptions = [], searchTerm = '', callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get('sals', {params: {filterOptions, searchTerm}})
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_USERS, payload: data.data.reverse()});
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.get.serverProblem.fetchError" />));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id="app.module.get.serverProblem.fetchError" />));
      });
  };
};

export const setCurrentUser = (user) => {
  return (dispatch) => {
    dispatch({type: SET_USER_DETAILS, payload: user});
  };
};

export const addNewUser = (user, photo, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post('sals', user)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          if (photo) {
            let salId = data.data.id;
            let object = {};
            object.file = photo;
            object.fileName = photo.name;
            const newFile = new File([object.file], object.fileName, {
              type: object.file.type,
            });
            let establishementName = data.data?.establishment.name;

            let formadata = new FormData();
            formadata.append('file', newFile);
            formadata.append('userId', salId);
            formadata.append('key', `${establishementName}/Divers`);
            formadata.append('fileName', object.fileName);
            formadata.append('subEstablishmentId', null);

            crudApp
              .put(`users/photo`, formadata)
              .then((dataPhoto) => {
                if (dataPhoto.status === 200 || dataPhoto.status === 201) {
                  dispatch(fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />));
                  dispatch({type: ADD_USER, payload: {...data.data, ...dataPhoto.data}});
                  // dispatch({type: ADD_PROFESSOR, payload: {...data.data, ...dataPhoto.data}});
                  if (callbackFun) callbackFun(data.data);
                }
              })
              .catch((error) => {
                dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
              });
          } else {
            dispatch(
              fetchSuccess(<IntlMessages id="app.module.adminLocal.add.user.fetchSuccess" />)
            );
            dispatch({type: ADD_USER, payload: data.data});
            if (callbackFun) callbackFun(data.data);
          }
        } else if (data?.response?.status === 409) {
          dispatch(fetchError(<IntlMessages id="appModule.exist.email.msg.error" />));
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
        }
      })
      .catch((error) => {
        // dispatch(fetchError("Il y a eu un problème. Merci de réessayer"));
      });
  };
};

export const sentMailToUser = () => {
  return (dispatch) => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};

export const updateUser = (currentUser, user, photo, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`sals/${currentUser.id}`, user)
      .then((data) => {
        if (data.status === 200) {
          if (photo) {
            let salId = currentUser.id;
            let object = {};
            object.file = photo;
            object.fileName = photo.name;
            const newFile = new File([object.file], object.fileName, {
              type: object.file.type,
            });
            let establishementName = currentUser.establishment.name;

            let formadata = new FormData();
            formadata.append('file', newFile);
            formadata.append('userId', salId);
            formadata.append('key', `${establishementName}/Divers`);
            formadata.append('fileName', object.fileName);
            formadata.append('subEstablishmentId', null);
            crudApp
              .put(`users/photo`, formadata)
              .then((dataPhoto) => {
                if (dataPhoto.status === 200 || dataPhoto.status === 201) {
                  dispatch(fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />));
                  dispatch({
                    type: EDIT_USER,
                    payload: {...currentUser, ...user, ...dataPhoto.data},
                  });
                  if (callbackFun) callbackFun(data.data);
                }
              })
              .catch((error) => {
                dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
              });
          } else {
            dispatch(fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />));
            dispatch({type: EDIT_USER, payload: {...currentUser, ...user}});
            if (callbackFun) callbackFun({...currentUser, ...user});
          }
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
        }
      })
      .catch((err) => {
        dispatch(fetchError(<IntlMessages id="app.module.get.serverProblem.fetchError" />));
      });
  };
};

export const updateUserStatus = (data, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .put('/users/update-status', data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchSuccess('User status was updated successfully.'));
          dispatch({type: EDIT_USER, payload: response.data});
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id="app.module.get.serverProblem.fetchError" />));
      });
  };
};

export const deleteBulkUsers = (userIds, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', {userIds})
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({type: DELETE_BULK_USERS, payload: userIds});
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id="app.module.get.serverProblem.fetchError" />));
      });
  };
};

export const deleteUser = (userId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .delete('/users', {params: {id: userId}})
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({type: DELETE_USER, payload: userId});
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id="app.module.get.serverProblem.fetchError" />));
      });
  };
};

export const getImportHistory = (action, type, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`import-history?action=${action}&type=${type}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          let newData = data.data.sort(
            (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
          );
          dispatch({type: HISTORY_IMPORT, payload: newData});
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.get.serverProblem.fetchError" />));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id="app.module.get.serverProblem.fetchError" />));
      });
  };
};

export const loginAs = (userId, user) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post(`users/connect-as?userId=${userId}`)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess(<IntlMessages id="app.module.users.loginAs.fetchSuccess" />));
          if (user === 'operator' || user === 'admin') {
            localStorage.setItem('userETQsal14mp52', data.data.accessToken);
            if (user === 'admin') {
              window.open(`${window.location.origin}/app/${user}/dashboard`);
            }
          } else localStorage.setItem('salETQ12gtu47', data.data.accessToken);
          if (user === 'admin' && window.location.pathname.toString().search('/admin') > 0) {
            dispatch(AuhMethods['jwtAuth'].getDataToken(data.data.accessToken, user));
          } else if (user !== 'admin')
            dispatch(AuhMethods['jwtAuth'].getDataToken(data.data.accessToken, user));
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
        }
      })
      .catch((error) => {
        dispatch(fetchError('Il y a eu un problème. Merci de réessayer'));
      });
  };
};
