import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, LOADING } from '../../@jumbo/constants/ActionTypes';

export const fetchSuccess = message => {

  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || '',
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return dispatch => {

    dispatch({
      type: FETCH_START,
    });
  };
};

export const loading = () => {
  return dispatch => {
    dispatch({
      type: LOADING,
    });
  };
}; 
export const AddMenuSuccess = message => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || '',
    });
  };
};
 

export const MenuStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
  };
};
 
