import IntlMessages from "@jumbo/utils/IntlMessages";

export const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: <IntlMessages id="English" />,
    icon: "us",
  },

  {
    languageId: "french",
    locale: "fr",
    name: <IntlMessages id="French" />,
    icon: "fr",
  },

  {
    languageId: "saudi-arabia",
    locale: "ar",
    name: <IntlMessages id="Arabic" />,
    icon: "sa",
  },
];

export const flags = {
  en: "/images/flag/icons8-usa.png",
  zh: "/images/flag/icons8-china.png",
  es: "/images/flag/icons8-spain.png",
  fr: "/images/flag/icons8-france.png",
  it: "/images/flag/icons8-italy.png",
  ar: "/images/flag/icons8-saudi_arabia.png",
};
