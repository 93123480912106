import React, { useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
 import { Button, Chip, Menu, MenuItem } from "@material-ui/core";
import ConfirmDialog from "../../../@jumbo/components/Common/ConfirmDialog";
import CmtSearch from "../../../@coremat/CmtSearch";
import useStyles from "../../CommonComponents/Style/TableToolbarStyle";
import Checkbox from "@material-ui/core/Checkbox";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";

const filterOptionsList = [
  { label: "Complexe", value: "complex" },
  { label: "Simple", value: "simple" },
];

const EstablishmentTableToolbar = ({
  onEstablishmentAdd,
  filterOptions,
  setFilterOptions,
  searchTerm,
  setSearchTerm,
  withOutSelectedFilter,
}) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const onFilterOptionClick = (option) => {
    setFilterOptions((prevState) => {
      if (prevState.includes(option.value)) {
         return prevState.filter((item) => item !== option.value);
      } else {
        return [...prevState, option.value];
      }
    });
  };

  const onChipDelete = (option) => {
    setFilterOptions(filterOptions.filter((item) => item !== option.value));
  };

  const onSearchChipDelete = () => setSearchTerm("");

  return (
    <React.Fragment>
      <Toolbar className={clsx(classes.root)}>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          <IntlMessages id="appModule.establishments" />
          <Button
            color="primary"
            onClick={() => onEstablishmentAdd(true)}
            className={classes.spaceTitleToolbar}
          >
            <IntlMessages id="appModule.establishment.add" />
          </Button>
        </Typography>

        <React.Fragment>
          <CmtSearch
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            border={false}
            onlyIcon
          />
          <div className={classes.chipsRoot}>
            {searchTerm && (
              <Chip label={searchTerm} onDelete={onSearchChipDelete} />
            )}
            {filterOptionsList.map(
              (option, index) =>
                filterOptions.includes(option.value) && (
                  <Chip
                    key={index}
                    label={option.label}
                    onDelete={() => onChipDelete(option)}
                  />
                )
            )}
          </div>
          <Tooltip title={<IntlMessages id="sidebar.filter.list" />}>
            <IconButton aria-label="filter list" onClick={handleClick}>
              <FilterListIcon className={classes.iconActionRoot} />
            </IconButton>
          </Tooltip>

          <Menu
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {filterOptionsList.map((option, index) => (
              <MenuItem key={index} onClick={() => onFilterOptionClick(option)}>
                <Checkbox
                  checked={filterOptions.includes(option.value)}
                  inputProps={{ "aria-labelledby": option.label }}
                />
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      </Toolbar>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete users`}
        content={"Are you sure, you want to  delete selected users?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

 

export default EstablishmentTableToolbar;
