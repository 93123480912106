import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  UPDATE_AUTH_SUBESTABLIDHMENT,
  GET_SUB_MODULES,
  GET_MODULES,
  PASSWORD_CHANGED,
  SET_USER_ETQ,
  UPDATE_PHOTO,
  SELECT_SCHOOL_YEAR,
  LOGIN_ERROR_BLOCKED,
  ACCESS_DATA,
  UPDATE_PROFIL,
  IS_FIRST_CONNECTION,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  userProfile: "",
  authUser:
    window.localStorage.salETQ12gtu47 &&
    window.location.pathname.toString().search("/sag/") === -1
      ? localStorage.salETQ12gtu47
      : window.localStorage.userETQsal14mp52 &&
        window.location.pathname.toString().search("/superadmin/") === -1
      ? localStorage.userETQsal14mp52
      : localStorage.getItem("first478loi52"),
  loadUser: false,
  send_forget_password_email: false,
  password_changed: false,
  subEstablishment: "",
  modules: [],
  subModules: [],
  userEtq: "",
  selectedYear: "",
  messageBlocked: "",
  access: false,
  firstConnexion: false
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser:
          window.localStorage.salETQ12gtu47 &&
          window.location.pathname.toString().search("/sag/") === -1
            ? localStorage.salETQ12gtu47
            : window.localStorage.userETQsal14mp52 &&
              window.location.pathname.toString().search("/superadmin/") === -1
            ? localStorage.userETQsal14mp52
            : localStorage.getItem("first478loi52"),
        userProfile: action.payload,
        loadUser: true,
      };
    }

    case GET_MODULES: {
      return {
        ...state,
        modules: action.payload,
      };
    }
    case GET_SUB_MODULES: {
      return {
        ...state,
        subModules: action.payload,
      };
    }

    case UPDATE_AUTH_SUBESTABLIDHMENT: {
      return {
        ...state,
        subEstablishment: action.payload,
        loadUser: true,
      };
    }

    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload,
      };
    }
    case PASSWORD_CHANGED: {
      return {
        ...state,
        password_changed: action.payload,
      };
    }
    case SET_USER_ETQ: {
      return {
        ...state,
        userEtq: action.payload,
      };
    }
    case UPDATE_PHOTO: {
      return {
        ...state,
        userProfile: { ...state.userProfile, photo: action.payload.photo },
      };
    }

    case SELECT_SCHOOL_YEAR: {
      return {
        ...state,
        selectedYear: action?.payload,
      };
    }
    case LOGIN_ERROR_BLOCKED: {
      return {
        ...state,
        messageBlocked: action?.payload,
      };
    }
    case UPDATE_PROFIL: {
      return {
        ...state,
        userProfile: { ...state.userProfile, ...action.payload },
      };
    }
    case ACCESS_DATA: {
      return {
        ...state,
        access: action?.payload,
      };
    }
    case IS_FIRST_CONNECTION: {
       return {
        ...state,
        firstConnexion : action?.payload
      };
    }

    default:
      return state;
  }
};
export default Auth;
