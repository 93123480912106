import {
  POST_ADMINISTRATIVE_DOCUMENTS
  , GET_ADMINISTRATIVE_DOCUMENTS,
  SET_ADMINISTRATIVE_DOCUMENTS_DETAILS,
  DELETE_ADMINISTRATIVE_DOCUMENTS,
  EDIT_ADMINISTRATIVE_DOCUMENTS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  AdministrativeDocuments: [],
  currentAdministrativeDocuments: null

};

const AdministrativeDocuments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_ADMINISTRATIVE_DOCUMENTS: {
      return {
        ...state,
        AdministrativeDocuments: [...action.payload, ...state.AdministrativeDocuments]
      };
    } case SET_ADMINISTRATIVE_DOCUMENTS_DETAILS: {
      return {
        ...state,
        currentAdministrativeDocuments: action.payload
      };
    }
    case EDIT_ADMINISTRATIVE_DOCUMENTS: {
      return {
        ...state,
        AdministrativeDocuments: state.AdministrativeDocuments.map(
          el =>
            el.id === action.payload.id
              ? action.payload
              : el
        )
      };
    }
    case GET_ADMINISTRATIVE_DOCUMENTS: {
      return {
        ...state,
        AdministrativeDocuments: action.payload
      };
    }
    case DELETE_ADMINISTRATIVE_DOCUMENTS
      : {
        return {
          ...state,
          AdministrativeDocuments: state.AdministrativeDocuments.filter(el => el.id !== action.payload.id)
        };
      }
    default:
      return state;
  }
};
export default AdministrativeDocuments