import {
  GET_DISCUSSION_GROUPS,
  EDIT_DISCUSSION_GROUPS,
  SET_DISCUSSION_GROUP
} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  discussionGroups: [],
  currentDiscussionGroup : null
};
const DiscussionGroups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DISCUSSION_GROUPS: {
      return {
        ...state,
        discussionGroups: action.payload
      };
    }
    case SET_DISCUSSION_GROUP: {
      return {
        ...state,
        currencurrentDiscussionGroup: action.payload
      };
    }
    case EDIT_DISCUSSION_GROUPS: {
      return {
        ...state,
        discussionGroups: state.discussionGroups.map((DiscussionGroup) =>
          DiscussionGroup.id === action.payload.id
            ? { ...DiscussionGroup , status : action.payload.status}
            : DiscussionGroup
        ),
      };
    }
    default:
      return state;
  }
};
export default DiscussionGroups