import {
  ADD_LEVEL,
  DELETE_LEVEL,
  EDIT_LEVEL,
  GET_LEVELS,
  SET_LEVEL_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  levels: [],
  currentLevel: null
};

const Level = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEVELS: {
      return {
        ...state,
        levels: action.payload
      };
    }
    case SET_LEVEL_DETAILS: {
      return {
        ...state,
        currentLevel: action.payload
      };
    }
    case ADD_LEVEL: {
      return {
        ...state,
        levels: [ action.payload, ...state.levels ]
      };
    }
    case EDIT_LEVEL: {
      console.log("DELEEEEEEEEEEEEETE")
      return {
        ...state,
        levels: state.levels.map(
          LEVEL => (LEVEL.id === action.payload.id ? action.payload : LEVEL)
        )
      };
    }
    case DELETE_LEVEL: {
      return {
        ...state,
        levels: state.levels.filter(LEVEL => LEVEL.id !== action.payload)
      };
    }

    default:
      return state;
  }
};
export default Level