import {
  ADD_MEDICAL_HISTORY,
  EDIT_MEDICAL_HISTORY,
  GET_MEDICAL_HISTORY,
  SET_MEDICAL_HISTORY_DETAILS,
  DELETE_MEDICAL_HISTORY,
} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  medicalHistory: [],
  currentMedicalHistory: null,
};
const MedicalHistory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEDICAL_HISTORY: {
      return {
        ...state,
        medicalHistory: action.payload,
      };
    }
    case SET_MEDICAL_HISTORY_DETAILS: {
      return {
        ...state,
        currentMedicalHistory: action.payload,
      };
    }
    case ADD_MEDICAL_HISTORY: {
      return {
        ...state,
        medicalHistory: [action.payload, ...state.medicalHistory],
      };
    }
    case EDIT_MEDICAL_HISTORY: {
      return {
        ...state,
        medicalHistory: [
          action.payload,
          ...state.medicalHistory.filter(
            (item) => item.id !== action.payload.id
          ),
        ],
      };
    }
    case DELETE_MEDICAL_HISTORY: {
      return {
        ...state,
        medicalHistory: state.medicalHistory.filter(
          (item) => item.id !== action.payload
        ),
      };
    }
    default:
      return state;
  }
};
export default MedicalHistory