import {
  ADD_OPERATOR,

  DELETE_OPERATOR,
  EDIT_OPERATOR,
  GET_OPERATORS,
  SET_OPERATOR_DETAILS
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  operators: [],
  currentOperator: null,
 
};
const Operator = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_OPERATORS: {
      return {
        ...state,
        operators: action.payload,
      };
    }
    case SET_OPERATOR_DETAILS: {
      return {
        ...state,
        currentOperator: action.payload,
      };
    }
    case ADD_OPERATOR: {
      return {
        ...state,
        operators: [action.payload, ...state.operators],
      };
    }
    case EDIT_OPERATOR: {
      return {
        ...state,
        operators: state.operators.map(operator => (operator.id === action.payload.id ? action.payload : operator)),
      };
    }
    case DELETE_OPERATOR: {
      return {
        ...state,
        operators: state.operators.filter(operator => operator.id !== action.payload),
      };
    }
   
    default:
      return state;
  }
};
export default  Operator