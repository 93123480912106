import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Dialog,
  Box,
  Button,
  DialogTitle,
  DialogContent,
  Badge,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import GridContainer from "@jumbo/components/GridContainer";
import { requiredMessage, negativNubmer } from "@jumbo/constants/ErrorMessages";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import useStyles from "../../CommonComponents/Style/TypoModelStyle";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { addNewLicense, updateLicense } from "../../../redux/actions/License";
import { Paiement, LicenceStatus } from "Config/listes";
import close from "../../../assets/images/close.svg";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const AddLicence = ({
  open,
  onCloseDialog,
  schoolYear,
  module,
  establishments,
  disabled,
}) => {
  const lang = useSelector((state) => state.LangReducer);
  const classes = useStyles();
  const theme = useTheme();
  const { currentLicense } = useSelector(
    ({ LicensesReducer }) => LicensesReducer
  );
  const [licenseStatus, setLicenseStatus] = useState("");
  const [licenseStatusError, setLicenseStatusError] = useState("");
  const [studentsNumber, setStudentsNumber] = useState();
  const [studentsNumberError, setStudentsNumberError] = useState();
  const [smsNumber, setSmsNumber] = useState();
  const [smsNumberError, setSmsNumberError] = useState();
  const [paymentPeriod, setPaymentPeriod] = useState("");
  const [paymentPeriodError, setPaymentPeriodError] = useState("");
  const [schoolYearId, setSchoolYearId] = useState("");
  const [schoolYearIdError, setSchoolYearIdError] = useState("");
  const [subEstablishmentId, setSubEstablishmentId] = useState("");
  const [subEstablishmentIdError, setSubEstablishmentIdError] = useState("");
  const [moduleName, setModuleName] = useState([]);
  const [startDemo, setStartDemo] = useState(moment().format());
  const [endDemo, setEndDemo] = useState(
    moment()
      .add(2, "month")
      .format()
  );

  const [moduleNameError, setModuleNameError] = useState("");
  const establishementTab = establishments.filter(
    (establishment) => establishment.establishmentType === "simple"
  );
  function sortModules(a, b) {
    if (a.pathName === "/board-table" || a.pathName === "/administration") {
      if (b.pathName === "/board-table") {
        return 1;
      } else {
        return -1;
      }
    } else {
      return 1;
    }
  }
  function getStyles(name, moduleName, theme) {
    return {
      fontWeight:
        moduleName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const dispatch = useDispatch();
  useEffect(() => {
    let selectedDefaultModule = module.filter(
      (selectModule) =>
        selectModule.pathName === "/board-table" ||
        selectModule.pathName === "/administration"
    );
    setModuleName(selectedDefaultModule);
  }, [module]);

  useEffect(() => {
    if (currentLicense) {
      setLicenseStatus(currentLicense.licenseStatus);
      setStudentsNumber(currentLicense.studentsNumber);
      setSmsNumber(currentLicense.smsNumber);
      setPaymentPeriod(currentLicense.paymentPeriod);
      setSubEstablishmentId(currentLicense.subEstablishment?.id);
      setModuleName(currentLicense.subModules);
      setSchoolYearId(currentLicense.schoolyear?.id);
      setStartDemo(currentLicense.startDemo);
      setEndDemo(currentLicense.endDemo);
    }
  }, [currentLicense]);

  const handleChangeSubModuleStatus = (subModule) => {
    let newListSubjects = moduleName.map((objSubject, i) => {
      return {
        ...objSubject,
        subModules: objSubject.subModules.map((sub_module, i) =>
          sub_module.id === subModule.id
            ? {
                ...sub_module,
                status: !sub_module.status,
              }
            : sub_module
        ),
      };
    });

    setModuleName(newListSubjects);
  };

  const handleChange = (event) => {
    if (event.target.value.includes("select-all")) {
      setModuleName(module);
      setModuleNameError("");
    } else {
      setModuleName(event.target.value);
      setModuleNameError("");
    }
  };

  const onDeletModule = (item) => {
    if (
      item.pathName !== "/administration" &&
      item.pathName !== "/board-table"
    ) {
      setModuleName(moduleName.filter((e) => e.pathName !== item.pathName));
    }
  };

  const onSubmitClick = () => {
    if (!subEstablishmentId) {
      setSubEstablishmentIdError(requiredMessage);
    } else if (!schoolYearId) {
      setSchoolYearIdError(requiredMessage);
    } else if (!licenseStatus) {
      setLicenseStatusError(requiredMessage);
    } else if (!studentsNumber) {
      setStudentsNumberError(requiredMessage);
    } else if (studentsNumber <= 0) {
      setStudentsNumberError(negativNubmer);
    } else if (!smsNumber) {
      setSmsNumberError(requiredMessage);
    } else if (smsNumber <= 0) {
      setSmsNumberError(negativNubmer);
    } else if (!paymentPeriod) {
      setPaymentPeriodError(requiredMessage);
    } else if (moduleName.length <= 0) {
      setModuleNameError(requiredMessage);
    } else {
      onlicenseSave();
    }
  };

  const onlicenseSave = () => {
    let sub_module = moduleName.map((item, i) =>
      item.subModules.filter((e) => e.status)
    );
    let licenseDetail = {
      subEstablishmentId,
      licenseStatus,
      studentsNumber: parseInt(studentsNumber),
      smsNumber: parseInt(smsNumber),
      paymentPeriod,
      schoolYearId,
      subModules: sub_module.flat(),
    };
    if (licenseStatus === "Demo") {
      licenseDetail = { ...licenseDetail, startDemo, endDemo };
    }

    if (currentLicense) {
      let licenseId = currentLicense.id;
      dispatch(
        updateLicense(
          { ...licenseDetail },
          licenseId,

          () => {
            onCloseDialog();
          }
        )
      );
    } else {
      dispatch(
        addNewLicense(licenseDetail, () => {
          onCloseDialog();
        })
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          onCloseDialog();
        }
      }}
      className={classes.dialogRoot}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        <Grid container justifyContent="space-between" alignItems="center">
          {currentLicense && disabled === true ? (
            <IntlMessages id="appModule.Licence.view" />
          ) : currentLicense && disabled === false ? (
            <IntlMessages id="appModule.Licence.edit" />
          ) : (
            <IntlMessages id="appModule.Licence.add" />
          )}
          <img
            onClick={onCloseDialog}
            src={close}
            alt="img"
            style={{ textAlign: "end", cursor: "pointer" }}
          />
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        />
        <Box mb={{ xs: 6, md: 5 }}>
          <AppSelectBox
            id="establishmenetId"
            name="establishmenetId"
            fullWidth
            disabled={disabled}
            label={<IntlMessages id="appModule.establishment.name" />}
            data={establishementTab}
            variant="outlined"
            required
            value={subEstablishmentId}
            onChange={(e) => {
              setSubEstablishmentId(e.target.value);
              setSubEstablishmentIdError("");
            }}
            renderRow={(item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            )}
            helperText={subEstablishmentIdError}
          />
        </Box>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppSelectBox
              id="schoolyearId"
              name="schoolyearId"
              fullWidth
              disabled={disabled}
              required
              data={schoolYear}
              label={<IntlMessages id="user.schoolYear" />}
              value={schoolYearId}
              valueKey="slug"
              variant="outlined"
              labelKey="title"
              onChange={(e) => {
                setSchoolYearId(e.target.value);
                setSchoolYearIdError("");
              }}
              renderRow={(item, index) => (
                <MenuItem
                  disabled={moment().isAfter(moment(item.endDate))}
                  key={item.id}
                  value={item.id}
                  style={getStyles(item, moduleName, theme)}
                >
                  {item.year}{" "}
                  {moment().isBetween(
                    moment(item.startDate),
                    moment(item.endDate)
                  ) && <IntlMessages id="current.schoolYear" />}
                </MenuItem>
              )}
              helperText={schoolYearIdError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppSelectBox
              id="licenseStatus"
              name="licenseStatus"
              disabled={disabled}
              fullWidth
              required
              data={LicenceStatus}
              label={<IntlMessages id="Licence.status" />}
              valueKey="value"
              variant="outlined"
              value={licenseStatus}
              labelKey="label"
              onChange={(e) => {
                setLicenseStatus(e.target.value);
                setLicenseStatusError("");
              }}
              helperText={licenseStatusError}
            />
          </Grid>
        </GridContainer>
        {licenseStatus === "Demo" && (
          <GridContainer style={{ marginBottom: 12 }}>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider
                DateFnsUtils
                locale={lang}
                utils={MomentUtils}
              >
                <KeyboardDatePicker
                  required
                  autoOk
                  disabled={disabled}
                  size="small"
                  style={{ width: "100%" }}
                  variant="inline"
                  locale={lang}
                  invalidDateMessage="Format de date invalide"
                  inputVariant="outlined"
                  placeholder="dd/MM/yyyy"
                  label={<IntlMessages id="appModule.settings.startDate" />}
                  minDate={currentLicense ? undefined : moment().format()}
                  format="DD-MM-YYYY"
                  value={startDemo}
                  id="agreementEndDate"
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date) => {
                    setStartDemo(moment(date).format());
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider
                DateFnsUtils
                locale={lang}
                utils={MomentUtils}
              >
                <KeyboardDatePicker
                  required
                  autoOk
                  disabled={disabled}
                  size="small"
                  style={{ width: "100%" }}
                  variant="inline"
                  locale={lang}
                  invalidDateMessage="Format de date invalide"
                  minDateMessage={<IntlMessages id="invalid.date.end" />}
                  inputVariant="outlined"
                  placeholder="dd/MM/yyyy"
                  label={<IntlMessages id="appModule.settings.endDate" />}
                  minDate={moment(startDemo).format()}
                  format="DD-MM-YYYY"
                  value={endDemo}
                  id="agreementEndDate"
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date) => {
                    setEndDemo(moment(date).format());
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </GridContainer>
        )}

        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              id="studentsNumber"
              name="studentsNumber"
              disabled={disabled}
              fullWidth
              required
              type="number"
              variant="outlined"
              label={<IntlMessages id="appModule.student.number" />}
              value={studentsNumber}
              onChange={(e) => {
                setStudentsNumber(e.target.value);
                setStudentsNumberError("");
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              helperText={studentsNumberError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              id="smsNumber"
              name="smsNumber"
              fullWidth
              disabled={disabled}
              required
              type="number"
              variant="outlined"
              label={<IntlMessages id="appModule.sms.number" />}
              value={smsNumber}
              onChange={(e) => {
                setSmsNumber(e.target.value);
                setSmsNumberError("");
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              helperText={smsNumberError}
            />
          </Grid>
        </GridContainer>

        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppSelectBox
              id="paymentPeriod"
              name="paymentPeriod"
              fullWidth
              required
              data={Paiement}
              disabled={disabled}
              label={<IntlMessages id="Paiement.status" />}
              valueKey="slug"
              variant="outlined"
              labelKey="title"
              value={paymentPeriod}
              onChange={(e) => {
                setPaymentPeriod(e.target.value);
                setPaymentPeriodError("");
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              helperText={paymentPeriodError}
            />
          </Grid>
        </GridContainer>

        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            <AppSelectBox
              fullWidth
              disabled={disabled}
              label={<IntlMessages id="appModule.module.name" />}
              data={module.sort(sortModules)}
              variant="outlined"
              required
              multiple
              selectAll={true}
              value={_.intersectionBy(module, moduleName, "id")}
              id="module"
              name="module"
              onChange={handleChange}
              renderRow={(item, index) => (
                <MenuItem
                  disabled={
                    item.pathName === "/administration" ||
                    item.pathName === "/board-table"
                  }
                  key={item.id}
                  value={item}
                  style={getStyles(item, moduleName, theme)}
                >
                  {item.nameFr}
                </MenuItem>
              )}
              helperText={moduleNameError}
            />
          </Grid>
        </GridContainer>

        {moduleName.sort(sortModules).map((item, index) => (
          <GridContainer
            style={{
              marginBottom: 12,
              borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
            }}
          >
            <Grid item xs={4} md={4}>
              <Badge
                badgeContent={
                  !disabled ? (
                    <CancelIcon
                      style={{ fontSize: 18 }}
                      onClick={() => onDeletModule(item)}
                      key={index}
                    />
                  ) : null
                }
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                classes={{ badge: classes.addModuleRoot }}
              >
                <Button
                  id="moduleName"
                  name="moduleName"
                  size="small"
                  disabled={disabled}
                  color="primary"
                  className={classes.moduleNameButton}
                  variant="outlined"
                  key={index}
                >
                  {item.nameFr}
                </Button>
              </Badge>
            </Grid>
            <Grid item xs={8} md={8}>
              {item?.subModules?.map((subModule, index) => (
                <Button
                  id="subModuleName"
                  name="subModuleName"
                  size="small"
                  color="primary"
                  className={
                    subModule.status
                      ? `${classes.submodulButton}`
                      : `${classes.inactifSubmodulButton}`
                  }
                  variant="outlined"
                  onClick={() => handleChangeSubModuleStatus(subModule)}
                  disabled={
                    disabled ||
                    item.pathName === "/administration" ||
                    item.pathName === "/board-table"
                  }
                  key={index}
                >
                  {subModule.nameFr}
                </Button>
              ))}
            </Grid>
          </GridContainer>
        ))}

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button
            onClick={onCloseDialog}
            color="primary"
            className={classes.cancelButton}
            variant="outlined"
          >
            {" "}
            <IntlMessages id="appModule.cancel" />
          </Button>
          {!disabled ? (
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.saveButton}
                onClick={onSubmitClick}
              >
                {currentLicense ? (
                  <IntlMessages id="app.edit" />
                ) : (
                  <IntlMessages id="app.add" />
                )}
              </Button>
            </Box>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddLicence;

AddLicence.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
