import {
  ADD_SUGGESTIONCHEF,
  DELETE_SUGGESTIONCHEF,
  EDIT_SUGGESTIONCHEF,
  GET_SUGGESTIONCHEFS,
  SET_SUGGESTIONCHEF_DETAILS,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  suggestionChefs: [],
  currentSuggestionChef: null,
};

const SuggestionChef = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUGGESTIONCHEFS: {
      return {
        ...state,
        suggestionChefs: action.payload,
      };
    }
    case SET_SUGGESTIONCHEF_DETAILS: {
      return {
        ...state,
        currentSuggestionChef: action.payload,
      };
    }
    case ADD_SUGGESTIONCHEF: {
      return {
        ...state,
        suggestionChefs: [action.payload, ...state.suggestionChefs],
      };
    }
    case EDIT_SUGGESTIONCHEF: {
      return {
        ...state,
        suggestionChefs: [
          action.payload,
          ...state.suggestionChefs.filter(
            (suggestionChef) => suggestionChef.id !== action.payload.id
          ),
        ],
      };
    }
    case DELETE_SUGGESTIONCHEF: {
      return {
        ...state,
        suggestionChefs: state.suggestionChefs.filter(
          (suggestionChef) => suggestionChef.id !== action.payload
        ),
      };
    }

    default:
      return state;
  }
};
export default SuggestionChef