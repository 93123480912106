import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";
import {
  ADD_STUDENT,
  EDIT_STUDENT,
  GET_STUDENTS,
  GET_PARENTS,
} from "../../@jumbo/constants/ActionTypes";
import IntlMessages from "@jumbo/utils/IntlMessages";
export const addNewStudent = (student, photo, userProfile, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("students", student)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          if (photo !== undefined && photo !== null) {
            let studentId = data.data?.id;
            dispatch(fetchStart());
            let object = {};
            object.file = photo;
            object.fileName = photo.name;
            const newFile = new File([object.file], object.fileName, {
              type: object.file.type,
            });
            let establishementname = userProfile?.establishmentName;
            let subEstablishementName =
              userProfile?.currentSubEstablishmentName;
            let subEstablishmentId = userProfile?.currentSubEstablishmentId;
            let formadata = new FormData();
            formadata.append("file", newFile);
            formadata.append("userId", studentId);
            formadata.append(
              "key",
              `${establishementname}${subEstablishementName}/Divers`
            );
            formadata.append("fileName", object.fileName);
            formadata.append("subEstablishmentId", subEstablishmentId);
            crudApp
              .put(`users/photo`, formadata)
              .then((dataPhoto) => {
                if (dataPhoto.status === 200 || dataPhoto.status === 201) {
                  dispatch({
                    type: ADD_STUDENT,
                    payload: { ...data.data, ...dataPhoto.data },
                  });
                  if (callbackFun)
                    callbackFun({ ...data.data, ...dataPhoto.data });
                }
              })
              .catch((error) => {
                dispatch(
                  fetchError(
                    <IntlMessages id="app.module.get.problem.fetchError" />
                  )
                );
              });
          } else {
            dispatch({
              type: ADD_STUDENT,
              payload: { ...data.data },
            });
          }
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.adminLocal.add.user.fetchSuccess" />
            )
          );
          if (callbackFun) callbackFun(data.data);
        } else if (
          data?.response?.data?.message ===
          "Error when creating Student : classroom capacity is exceeded"
        ) {
          dispatch(fetchError(<IntlMessages id="appModule.capacity.error" />));
        } else if (data?.response?.data?.id === "classLeader.exist")
          dispatch(
            fetchError(
              <IntlMessages id="app.module.addUser.student.resposable.exist" />
            )
          );
        else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError("Il y a eu un problème. Merci de réessayer"));
      });
  };
};

export const getStudents = (
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`students`, {
        params: { filterOptions, searchTerm },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_STUDENTS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(
              <IntlMessages id="app.module.get.serverProblem.fetchError" />
            )
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};

export const updateStudent = (user, id, photo, userProfile, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`students/${id}`, user)
      .then((data) => {
        if (data.status === 200) {
          if (photo !== undefined && photo !== null) {
            let studentId = data.data?.id;
            dispatch(fetchStart());
            var object = {};
            object.file = photo;
            object.fileName = photo.name;
            const newFile = new File([object.file], {
              // type: object.file.type,
            });
            let establishementname = userProfile?.establishmentName;
            let subEstablishementName =
              userProfile?.currentSubEstablishmentName;
            let subEstablishmentId = userProfile?.currentSubEstablishmentId;
            let formadata = new FormData();
            formadata.append("file", newFile);
            formadata.append("userId", studentId);
            formadata.append(
              "key",
              `${establishementname}${subEstablishementName}/Divers`
            );
            formadata.append("fileName", object.fileName);
            formadata.append("subEstablishmentId", subEstablishmentId);
            crudApp
              .put(`users/photo`, formadata)
              .then((dataPhoto) => {
                dispatch({
                  type: EDIT_STUDENT,
                  payload: { ...data.data, photo: dataPhoto.data.photo },
                });
              })
              .catch((error) => {
                dispatch(
                  fetchError(
                    <IntlMessages id="app.module.get.problem.fetchError" />
                  )
                );
              });
          } else {
            dispatch({ type: EDIT_STUDENT, payload: data.data });
          }
          dispatch(
            fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />)
          );
          if (callbackFun) callbackFun(data.data);
        } else if (data?.response?.status === 409)
          data.response.data.message === "classs Leader exist in this class"
            ? dispatch(
                fetchError(
                  <IntlMessages id="app.module.addUser.student.resposable.exist" />
                )
              )
            : dispatch(
                fetchError(
                  <IntlMessages id="appModule.exist.email.msg.error" />
                )
              );
        else if (
          data?.response?.data?.message ===
          "Error when creating Father : User already exists with this cin"
        ) {
          dispatch(
            fetchError(<IntlMessages id="appModule.cin.father.msg.error" />)
          );
        } else if (
          data?.response?.data?.message ===
          "Error when creating Mother : User already exists with this cin"
        ) {
          dispatch(
            fetchError(<IntlMessages id="appModule.cin.mother.msg.error" />)
          );
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};

export const importParents = (parents, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("parents/new/import", parents)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.student.getParents.fetchSuccess" />
            )
          );

          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};

export const importStudent = (students, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("students/new/import", students)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.student.getStudents.fetchSuccess" />
            )
          );
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};

export const getParents = (
  subEstablishmentId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`parents?subEstablishmentId=${+subEstablishmentId}`, {
        params: { filterOptions, searchTerm },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_PARENTS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(
              <IntlMessages id="app.module.get.serverProblem.fetchError" />
            )
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};

export const exportParents = (callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get("parents/export")
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.student.exportParents.fetchSuccess" />
            )
          );
          if (callbackFun) callbackFun(data.data?.filePath);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};

export const exportStudents = (classId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(`students/new/export${classId}`)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.exportStudents.fetchSuccess" />
            )
          );
          if (callbackFun) callbackFun(data.data?.filePath);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};
