import {
  ADD_SANCTION,
  DELETE_SANCTION,
  EDIT_SANCTION,

  GET_SANCTIONS,
  SET_SANCTION_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  sanction: [],
  currentSanction: null
};

const Sanction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SANCTIONS: {
      return {
        ...state,
        sanction: action.payload
      };
    }
    case SET_SANCTION_DETAILS: {
      return {
        ...state,
        currentSanction: action.payload
      };
    }
    case ADD_SANCTION: {
      return {
        ...state,
        sanction: [ action.payload, ...state.sanction ]
      };
    }
    case EDIT_SANCTION: {
    
      return {
        ...state,
        sanction: state.sanction.map(
          SANCTION =>
            SANCTION.id === action.payload.id ? action.payload : SANCTION
        )
      };
      
    }
    
    case DELETE_SANCTION: {
      return {
        ...state,
        sanction: state.sanction.filter(
          SANCTION => SANCTION.id !== action.payload
        )
      };
    }

    default:
      return state;
  }
};
export default Sanction