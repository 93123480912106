import {
  GET_SCHOOLGRADES,
  ADD_SCHOOLGRADES,
  SET_SCHOOLGRADES,
  EDIT_SCHOOLGRADES,
  DELETE_SCHOOLGRADES,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  schoolGrades: [],
  currentSchoolGrades: null,
};

const SchoolGrades = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCHOOLGRADES: {
      return {
        ...state,
        schoolGrades: action.payload,
      };
    }
    case SET_SCHOOLGRADES: {
      return {
        ...state,
        currentSchoolGrades: action.payload,
      };
    }
    case ADD_SCHOOLGRADES: {
      let data = [action.payload, ...state.schoolGrades];
      return {
        ...state,
        schoolGrades: data.flat(),
      };
    }
    case EDIT_SCHOOLGRADES: {
      return {
        ...state,
        schoolGrades: state.schoolGrades.map((grade) =>
          grade.id === action?.payload[0].id
            ? action?.payload[0]
            : grade.id !== action?.payload[0].id &&
              grade.exam.id === action.payload[0].exam.id
            ? { ...grade, exam: action.payload[0].exam }
            : grade
        ),
      };
    }
    case DELETE_SCHOOLGRADES: {
      return {
        ...state,
        schoolGrades: state.schoolGrades.filter(
          (schoolGrade) => schoolGrade?.id !== action.payload
        ),
      };
    }

    default:
      return state;
  }
};
export default SchoolGrades