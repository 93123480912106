import {
  ADD_ROOM,

  DELETE_ROOM,
  EDIT_ROOM,
  GET_ROOMS,
  SET_ROOM_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  rooms: [],
  currentRoom: null,
};

const Room = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROOMS: {
      return {
        ...state,
        rooms: action.payload,
      };
    }
    case SET_ROOM_DETAILS: {
      return {
        ...state,
        currentRoom: action.payload,
      };
    }
    case ADD_ROOM: {
      return {
        ...state,
        rooms: [action.payload, ...state.rooms],
      };
    }
    case EDIT_ROOM: {
      return {
        ...state,
        rooms: [...action.payload, ...state.rooms.filter(room => room.id !== action.payload[0].id)],
      };
    }
    case DELETE_ROOM: {
      return {
        ...state,
        rooms: state.rooms.filter(room => room.id !== action.payload),
      };
    }

    default:
      return state;
  }
};
export default Room