import {
  ADD_CLASSROOM,
  DELETE_CLASSROOM,
  EDIT_CLASSROOM,

  GET_CLASSROOMS,
  SET_CLASSROOM_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  classrooms: [],
  currentClassroom: null
};

const Classroom = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_CLASSROOMS: {
 
      return {
        ...state,
        classrooms: action.payload
      };
    }
    case SET_CLASSROOM_DETAILS: {
      return {
        ...state,
        currentClassroom: action.payload
      };
    }
    case ADD_CLASSROOM: {
 
      return {
        ...state,
        classrooms: [ action.payload, ...state.classrooms ]
      };
    }
    case EDIT_CLASSROOM: {
 
      return {
        ...state,
        classrooms: state.classrooms.map(
          CLASSROOM =>
            CLASSROOM.id === action.payload.id ? action.payload : CLASSROOM
        )
      };
      
    }
    
    case DELETE_CLASSROOM: {
      return {
        ...state,
        classrooms: state.classrooms.filter(
          CLASSROOM => CLASSROOM.id !== action.payload
        )
      };
    }

    default:
      return state;
  }
};
export default Classroom 