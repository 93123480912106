import React, { useRef } from "react";
import { useIntl } from "react-intl";
import ReactJoyride, { EVENTS, STATUS } from "react-joyride";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { firstConnection } from "redux/actions/Auth";
import styled, { keyframes } from "styled-components";

export const Joyride = (props) => {
  const intl = useIntl();

  // "",
  const steps = [
    {
      title: "Bonjour",
      content:
        "Nous vous emmènerons dans un voyage rapide à travers Educap, vous permettant de découvrir ses merveilles.",
      target: "#sideNavScroll",
      placement: "center",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step1.title" }),
      content: intl.formatMessage({ id: "tutorial.step1.content" }),
      target: "#logoEducap",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step2.title" }),
      content: intl.formatMessage({ id: "tutorial.step2.content" }),
      target: "#administration",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step3.title" }),
      content: intl.formatMessage({ id: "tutorial.step3.content" }),
      target: "#schoolSettings",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step4.title" }),
      content: intl.formatMessage({ id: "tutorial.step4.content" }),
      target: "#accountManagement",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step5.title" }),
      content: intl.formatMessage({ id: "tutorial.step5.content" }),
      target: "#classesParameters",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step6.title" }),
      content: intl.formatMessage({ id: "tutorial.step6.content" }),
      target: "#classroomManagement",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step7.title" }),
      content: intl.formatMessage({ id: "tutorial.step7.content" }),
      target: "#administrativeDocuments",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step8.title" }),
      content: intl.formatMessage({ id: "tutorial.step8.content" }),
      target: "#establishmentSheet",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step9.title" }),
      content: intl.formatMessage({ id: "tutorial.step9.content" }),
      target: "#myProfile",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step10.title" }),
      content: intl.formatMessage({ id: "tutorial.step10.content" }),
      target: "#schoolLife",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step11.title" }),
      content: intl.formatMessage({ id: "tutorial.step11.content" }),
      target: "#timetable",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step12.title" }),
      content: intl.formatMessage({ id: "tutorial.step12.content" }),
      target: "#assiduity",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step13.title" }),
      content: intl.formatMessage({ id: "tutorial.step13.content" }),
      target: "#historysanctions",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step14.title" }),
      content: intl.formatMessage({ id: "tutorial.step14.content" }),
      target: "#schoolsupplies",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step15.title" }),
      content: intl.formatMessage({ id: "tutorial.step15.content" }),
      target: "#evalution",
      placement: "right",
      waitBeforeAction: 1000,
    },
    {
      title: intl.formatMessage({ id: "tutorial.step16.title" }),
      content: intl.formatMessage({ id: "tutorial.step16.content" }),
      target: "#gardetracking",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step17.title" }),
      content: intl.formatMessage({ id: "tutorial.step17.content" }),
      target: "#schoolreports",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step18.title" }),
      content: intl.formatMessage({ id: "tutorial.step18.content" }),
      target: "#eLibrary",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step19.title" }),
      content: intl.formatMessage({ id: "tutorial.step19.content" }),
      target: "#multimediasupport",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step20.title" }),
      content: intl.formatMessage({ id: "tutorial.step20.content" }),
      target: "#educationalmaterials",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step21.title" }),
      content: intl.formatMessage({ id: "tutorial.step21.content" }),
      target: "#canteen",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step22.title" }),
      content: intl.formatMessage({ id: "tutorial.step22.content" }),
      target: "#canteenmanagement",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step23.title" }),
      content: intl.formatMessage({ id: "tutorial.step23.content" }),
      target: "#weeklymenu",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step24.title" }),
      content: intl.formatMessage({ id: "tutorial.step24.content" }),
      target: "#suggestionchef",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step25.title" }),
      content: intl.formatMessage({ id: "tutorial.step25.content" }),
      target: "#monitoringhr",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step26.title" }),
      content: intl.formatMessage({ id: "tutorial.step26.content" }),
      target: "#payslips",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step27.title" }),
      content: intl.formatMessage({ id: "tutorial.step27.content" }),
      target: "#employeesmonitoring",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step28.title" }),
      content: intl.formatMessage({ id: "tutorial.step28.content" }),
      target: "#medicalmonitoring",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step29.title" }),
      content: intl.formatMessage({ id: "tutorial.step29.content" }),
      target: "#vaccinationcard",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step30.title" }),
      content: intl.formatMessage({ id: "tutorial.step30.content" }),
      target: "#medicalmonitoringchild",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step31.title" }),
      content: intl.formatMessage({ id: "tutorial.step31.content" }),
      target: "#complaints",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step32.title" }),
      content: intl.formatMessage({ id: "tutorial.step32.content" }),
      target: "#complaintslist",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step33.title" }),
      content: intl.formatMessage({ id: "tutorial.step33.content" }),
      target: "#messagingparent",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step34.title" }),
      content: intl.formatMessage({ id: "tutorial.step34.content" }),
      target: "#messagingchild",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step35.title" }),
      content: intl.formatMessage({ id: "tutorial.step35.content" }),
      target: "#discussiongroupchild",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step36.title" }),
      content: intl.formatMessage({ id: "tutorial.step36.content" }),
      target: "#discussiongroup",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step37.title" }),
      content: intl.formatMessage({ id: "tutorial.step37.content" }),
      target: "#harassmentalertparent",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step38.title" }),
      content: intl.formatMessage({ id: "tutorial.step38.content" }),
      target: "#harassmentalertchild",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step39.title" }),
      content: intl.formatMessage({ id: "tutorial.step39.content" }),
      target: "#clubsparent",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step40.title" }),
      content: intl.formatMessage({ id: "tutorial.step40.content" }),
      target: "#clubsmanagement",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step41.title" }),
      content: intl.formatMessage({ id: "tutorial.step41.content" }),
      target: "#clubsnewspaper",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step42.title" }),
      content: intl.formatMessage({ id: "tutorial.step42.content" }),
      target: "#newsparent",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step43.title" }),
      content: intl.formatMessage({ id: "tutorial.step43.content" }),
      target: "#newschild",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step44.title" }),
      content: intl.formatMessage({ id: "tutorial.step44.content" }),
      target: "#edudriveparent",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step45.title" }),
      content: intl.formatMessage({ id: "tutorial.step45.content" }),
      target: "#edudrivechild",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step46.title" }),
      content: intl.formatMessage({ id: "tutorial.step46.content" }),
      target: "#reportsparent",
      placement: "right",
    },

    {
      title: intl.formatMessage({ id: "tutorial.step47.title" }),
      content: intl.formatMessage({ id: "tutorial.step47.content" }),
      target: "#reportschild",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step48.title" }),
      content: intl.formatMessage({ id: "tutorial.step48.content" }),
      target: "#bustrackingparent",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step49.title" }),
      content: intl.formatMessage({ id: "tutorial.step49.content" }),
      target: "#parkmanagment",
      placement: "right",
    },
    {
      title: intl.formatMessage({ id: "tutorial.step50.title" }),
      content: intl.formatMessage({ id: "tutorial.step50.content" }),
      target: "#realtimetracking",
      placement: "right",
    },
  ];
  const dispatch = useDispatch();
  const { userProfile } = useSelector(({ auth }) => auth);
  const joyrideRef = useRef(null);

  const { setRun, run, stepIndexState, setStepIndexState } = props;
  // var numberToSubtract = 100;
  // --- handle actions (next/ ignore / last)
  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      userProfile?.firstConnexion && dispatch(firstConnection());
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      setStepIndexState((prev) => prev + 1);
    } else if (index === 18) {
      const element = document.getElementById("sideNavScroll");
      var currentScrollTop =
        window.pageYOffset + 600 || document.documentElement.scrollTop;

      // Subtract the number from the scrollTop value
      var newScrollTop = currentScrollTop;
      element.scrollTo(0, newScrollTop);
    } else if (index === 33) {
      const element = document.getElementById("sideNavScroll");
      var currentScrollTopSecond = element.scrollTop;

      // Subtract the number from the scrollTop value
      var newScrollTopSEcond = currentScrollTopSecond + element.scrollTop + 300;

      element.scrollTo(0, newScrollTopSEcond);
    }
  };

  const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color: #5b13b473;
    transform: scale(1.6);
  }
`;

  const Beacon = styled.span`
    animation: ${pulse} 1s ease-in-out infinite;
    background-color: #5b13b4;
    border-radius: 50%;
    display: inline-block;
    height: 1rem;
    width: 1rem;
  `;


  const nextButtonLabel = intl.formatMessage({ id: 'joyride.next' });
  const skipButtonLabel = intl.formatMessage({ id: 'joyride.skip' });
  return (
    <ReactJoyride
  
      disableScrolling={true}
      ref={joyrideRef}
      beaconComponent={Beacon}
      run={run}
      steps={steps}
      stepIndex={stepIndexState}
      debug
      continuous
      showSkipButton
      showProgress
      disableBeacon={true}
      isFixed={false}
      scrollToFirstStep={true}
      callback={handleJoyrideCallback}
      hideBackButton={true}
      locale={{ next: nextButtonLabel, skip: skipButtonLabel }}
      styles={{
        options: {
          zIndex: 9999,
          arrowColor: "#fff",
          primaryColor: "#5B13B4",
          textColor: "#000",
          width: 380,
          titleColor: "red",
        },
      }}
    />
  );
};
