import {
  DATA_LOADED_SCHOOLYEAR,
  DATA_LOADED_MODULE,
} from "../../@jumbo/constants/ActionTypes";
const INIT_STATE = {
  school_year: [],
  module: [],
};

const Countries = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DATA_LOADED_SCHOOLYEAR: {
      return {
        ...state,
        scool_year: action.payload,
      };
    }
    case DATA_LOADED_MODULE: {
      return {
        ...state,
        module: action.payload,
      };
    }

    default:
      return state;
  }
};
export default Countries;
