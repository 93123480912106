import {
  ADD_MULTIMEDIA,
  DELETE_MULTIMEDIA,
  EDIT_MULTIMEDIA,
  GET_MULTIMEDIAS,
  SET_MULTIMEDIA_DETAILS,Details_Multimedia_SUPPORT
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  multimedias: [],
  currentMultimedia: null,
  detailSupport: {}
};

const Multimedia = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MULTIMEDIAS: {
      return {
        ...state,
        multimedias: action.payload,
      };
    }
    case SET_MULTIMEDIA_DETAILS: {
      return {
        ...state,
        currentMultimedia: action.payload,
      };
    }
    case Details_Multimedia_SUPPORT: {
      return {
        ...state,
        detailSupport: action.payload,
      };
    }
    case ADD_MULTIMEDIA: {
      return {
        ...state,
        multimedias: [action.payload, ...state.multimedias],
      };
    }
    case EDIT_MULTIMEDIA: {
      return {
        ...state,

        multimedias: state.multimedias.map((MULTIMEDIA) => {
          return MULTIMEDIA.id === action.payload.id
            ? action.payload
            : MULTIMEDIA;
        }),
      };
    }

    case DELETE_MULTIMEDIA: {
      return {
        ...state,
        multimedias: state.multimedias.filter(
          (MULTIMEDIA) => MULTIMEDIA.id !== action.payload
        ),
      };
    }

    default:
      return state;
  }
};
export default Multimedia