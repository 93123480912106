import {
    ADD_VACATION,
    DELETE_VACATION,
    EDIT_VACATION,
    GET_VACATIONS,
    SET_VACATION_DETAILS
  } from "../../@jumbo/constants/ActionTypes";
  
  const INIT_STATE = {
    vacations: [],
    currentVacation: null
  };
  
  const Vacation = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_VACATIONS: {
        return {
          ...state,
          vacations: action.payload
        };
      }
      case SET_VACATION_DETAILS: {
        return {
          ...state,
          currentVacation: action.payload
        };
      }
      case ADD_VACATION: {
        return {
          ...state,
          vacations: [ action.payload, ...state.vacations ]
        };
      }
      case EDIT_VACATION: {
        return {
          ...state,
          vacations: state.vacations.map(
            Vaccination =>
              Vaccination.id === action.payload.id
                ? action.payload
                : Vaccination
          )
        };
      }
      case DELETE_VACATION: {
        return {
          ...state,
          vacations: state.vacations.filter(
            Vaccination => Vaccination.id !== action.payload
          )
        };
      }
  
      default:
        return state;
    }
  };
  export default Vacation