import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";

import {
  // ADD_ESTABLISHMENT,
  DELETE_ESTABLISHMENT,
  EDIT_ESTABLISHMENT,
  GET_ESTABLISHMENTS,
  SET_ESTABLISHMENT_DETAILS,
  UPDATE_AUTH_USER,
  GET_SUBESTABLISHMENT,
  GET_LOGO,
  GET_ACTION,
} from "../../@jumbo/constants/ActionTypes";
import IntlMessages from "@jumbo/utils/IntlMessages";
import moment from "moment";

export const getEstablishments = (
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    crudApp
      .get("establishments", { params: { filterOptions, searchTerm } })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_ESTABLISHMENTS, payload: data.data.reverse() });
          if (callbackFun) callbackFun(data.data);
        }
      });
  };
};

export const getSubEstablishment = (subEstablishmentId, callbackFun) => {
  return (dispatch) => {
    crudApp.get(`establishments/${subEstablishmentId}`).then((data) => {
      if (data?.status === 200) {
        // dispatch(fetchSuccess());
        dispatch({ type: GET_SUBESTABLISHMENT, payload: data.data });

        if (callbackFun) callbackFun(data.data);
      }
    });
  };
};

export const setCurrentEstablishment = (establishment) => {
  return (dispatch) => {
    dispatch({ type: SET_ESTABLISHMENT_DETAILS, payload: establishment });
  };
};

export const setCurrentEstablishmentAction = (action) => {
  return (dispatch) => {
    dispatch({ type: GET_ACTION, payload: action });
  };
};

export const saveSubEstablishmentId = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_AUTH_USER, payload: data });
  };
};

export const addNewEstablishment = (establishment, logo, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("establishments", establishment)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          if (logo !== "") {
            let establishmentId =
              data.data.establishmentType === "complex"
                ? data.data?.id
                : data.data.establishmentId;
            dispatch(fetchStart());
            var object = {};
            object.file = logo;
            object.fileName = establishment?.name + logo.name;
            const newFile = new File([object.file], object.fileName, {
              type: object.file.type,
            });
            let establishementname = establishment?.name;
            let subEstablishementName = establishment.establishment?.name;
            let subEstablishmentId =
              data.data.establishmentType === "complex" ? null : data.data.id;
            let formadata = new FormData();

            formadata.append("file", newFile);
            formadata.append("establishmentId", establishmentId);
            formadata.append(
              "key",
              `${establishementname}${subEstablishementName}/Divers`
            );
            formadata.append("fileName", object.fileName);
            subEstablishmentId &&
              formadata.append("subEstablishmentId", subEstablishmentId);
            crudApp
              .post(`establishments/logo`, formadata)
              .then((data) => {
                // dispatch({ type: ADD_ESTABLISHMENT, payload: data.data });
                dispatch(
                  fetchSuccess(
                    <IntlMessages id="app.module.establishment.add.fetchSuccess" />
                  )
                );
                dispatch(getEstablishments());
              })
              .catch((error) => {
                dispatch(
                  fetchError(
                    <IntlMessages id="app.module.get.problem.fetchError" />
                  )
                );
              });
          } else {
            dispatch(
              fetchSuccess(
                <IntlMessages id="app.module.establishment.add.fetchSuccess" />
              )
            );
            dispatch(getEstablishments());
          }
          if (callbackFun) callbackFun(data.data);
        } else if (data?.response?.status === 409) {
          dispatch(fetchError(`Email ${establishment.email} existe déjà `));
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        // dispatch(fetchError("Il y a eu un problème. Merci de réessayer"));
      });
  };
};
export const updateEstablishment = (
  establishment,
  establishmentId,
  logo,
  callbackFun
) => {
  //check the license of a complex before modification
  return (dispatch) => {
    if (
      (establishment?.licenses?.filter(
        (row) =>
          moment().isBetween(
            row.schoolyear.startDate,
            row.schoolyear.endDate
          ) && row?.licenseStatus === "Active"
      ).length &&
        establishment?.establishmentType !== "complex") ||
      establishment?.establishmentType === "complex"
    ) {
      dispatch(fetchStart());
      crudApp
        .put(`establishments/${establishmentId}`, establishment)
        .then((data) => {
          if (data.status === 200 || data.status === 201) {
            if (logo !== "") {
              let establishmentId =
                data.data.establishmentType === "complex"
                  ? data.data?.id
                  : data.data.establishmentId;
              dispatch(fetchStart());
              var object = {};
              object.file = logo;
              object.fileName = logo.name;
              const newFile = new File([object.file], object.fileName, {
                type: object.file.type,
              });

              let establishementname = establishment?.name;
              let subEstablishementName = establishment.establishment?.name;
              let subEstablishmentId =
                data.data.establishmentType === "complex" ? null : data.data.id;
              let keyFile =
                data.data.establishmentType === "complex"
                  ? `${establishementname}/Divers`
                  : `${establishementname}${subEstablishementName}/Divers`;
              let formadata = new FormData();
              formadata.append("file", newFile);
              formadata.append("establishmentId", establishmentId);
              formadata.append("key", keyFile);
              formadata.append("fileName", object.fileName);
              subEstablishmentId &&
                formadata.append("subEstablishmentId", subEstablishmentId);
              crudApp
                .post(`establishments/logo`, formadata)
                .then((data) => {
                  dispatch({ type: EDIT_ESTABLISHMENT, payload: data.data });
                  // dispatch({type: GET_SUBESTABLISHMENT, payload: data.data});
                })
                .catch((error) => {
                  dispatch(
                    fetchError(
                      <IntlMessages id="app.module.get.problem.fetchError" />
                    )
                  );
                });
            }
            dispatch(
              fetchSuccess(
                <IntlMessages id="app.module.establishment.update.fetchSuccess" />
              )
            );
            dispatch({ type: EDIT_ESTABLISHMENT, payload: data.data });
            // dispatch({type: GET_SUBESTABLISHMENT, payload: data.data});
            if (callbackFun) callbackFun(data.data);
          } else {
            dispatch(
              fetchError(
                <IntlMessages id="app.module.get.problem.fetchError" />
              )
            );
          }
        })
        .catch((error) => {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        });
    } else
      dispatch(
        fetchError(<IntlMessages id="appModule.expired.license" />)
      );
  };
};

export const deleteEstablishment = (establishmentId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .delete("/establishments", { params: { id: establishmentId } })
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.establishment.delete.fetchSuccess" />
            )
          );
          dispatch({ type: DELETE_ESTABLISHMENT, payload: establishmentId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};
export const updateLogo = (
  subEstablishment,
  subEstablishmentId,
  logo,
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    var object = {};
    object.file = logo;
    object.fileName = subEstablishment?.name + logo.name;
    const newFile = new File([object.file], object.fileName, {
      type: object.file.type,
    });

    let formadata = new FormData();
    let establishementname = subEstablishment?.name;
    let subEstablishementName = subEstablishment.establishment?.name;
    formadata.append("file", newFile);
    formadata.append("establishmentId", subEstablishment.establishmentId);
    formadata.append("fileName", newFile.name);
    formadata.append("subEstablishmentId", subEstablishmentId);

    formadata.append(
      "key",
      `${establishementname}${subEstablishementName}/Divers`
    );
    crudApp
      .post(`establishments/logo`, formadata)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch({ type: GET_LOGO, payload: data.data.logo });
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.establishment.update.fetchSuccess" />
            )
          );

          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })

      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};
