import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import LicenceListRow from "./LicenseListRow/index";
import TableHead from "../CommonComponents/TableComponent/TableHead";
import TableToolbar from "../CommonComponents/TableComponent/TableToolbar";
import { getComparator, stableSort } from "../../@jumbo/utils/tableHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLicense,
  getLicenses,
  setCurrentLicense,
} from "../../redux/actions/License";
import { getSchoolYear, getModule } from "../../redux/actions/Setting";
import { getEstablishments } from "../../redux/actions/Establishments";
import AddLicence from "./AddLicence";
import ConfirmDialog from "../../@jumbo/components/Common/ConfirmDialog";
import { useDebounce, filterLicense } from "../../@jumbo/utils/commonHelper";
import useStyles from "../CommonComponents/Style/TableStyle";
 import IntlMessages from "../../@jumbo/utils/IntlMessages";
import { LicenceStatus } from "Config/listes";
import NoRecordFound from "routes/CommonComponents/NoRecordFound";

const LicenseManagement = () => {
  const classes = useStyles();
  const { licenses } = useSelector(({ LicensesReducer }) => LicensesReducer);
  const school_year = useSelector(
    ({ SettingReducer }) => SettingReducer.scool_year
  );
  const module = useSelector(({ SettingReducer }) => SettingReducer.module);
  const { establishments } = useSelector(
    ({ EstablishmentsReducer }) => EstablishmentsReducer
  );

  const [orderBy, setOrderBy] = React.useState("name");
  const [order, setOrder] = React.useState("asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openLicenceDialog, setOpenLicenceDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState({ name: "" });
   const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [disabled , setDisabled] =useState(false)
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const hedearToolbar = {
    title: <IntlMessages id="appModule.Licence" />,
    action: <IntlMessages id="appModule.Licence.add" />,
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: <IntlMessages id="appModule.establishment.name" />,
    },
    {
      id: "establichementMotherName",
      numeric: false,
      disablePadding: false,
      label: <IntlMessages id="appModule.establishment.parent.name" />,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: <IntlMessages id="appModule.licence.status" />,
    },
    {
      id: "student",
      numeric: false,
      disablePadding: false,
      label: <IntlMessages id="appModule.licence.studentNumber" />,
    },
    {
      id: "year",
      numeric: false,
      disablePadding: false,
      label: <IntlMessages id="user.schoolYear" />
    }
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getLicenses(filterOptions, debouncedSearchTerm, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
       })
    );
    dispatch(getSchoolYear());
    dispatch(getModule());
    dispatch(getEstablishments());
  }, [dispatch, filterOptions, debouncedSearchTerm]);



  const handleCloseLicenceDialog = () => {
    setOpenLicenceDialog(false);
    dispatch(setCurrentLicense(null));
    setDisabled(false)
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLicenseView = (license) => {
    dispatch(setCurrentLicense(license));
    setOpenLicenceDialog(true);
    setDisabled(true);
  };

  const handleLicenseEdit = (license) => {
    dispatch(setCurrentLicense(license));
    setOpenLicenceDialog(true);
  };

  const handleLicenseDelete = (license) => {
    setSelectedLicense(license);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteLicense(selectedLicense.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const onSearchChipDelete = () => {
    setSearchTerm("");
  };

  const licensesList = filterLicense(licenses, searchTerm,filterOptions, "name");

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          selected={selected}
          setSelected={setSelected}
          onAdd={setOpenLicenceDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleSearch={handleSearch}
          onSearchChipDelete={onSearchChipDelete}
          hedearToolbar={hedearToolbar}
          optionsList={LicenceStatus}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
          >
            <TableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {!!licensesList.length ? (
                stableSort(licensesList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <LicenceListRow
                      key={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onLicenseEdit={handleLicenseEdit}
                      onLicenseDelete={handleLicenseDelete}
                      onLicenseView={handleLicenseView}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                  {isFilterApplied ? (
                      <NoRecordFound>
                        <IntlMessages id="NoRecordFound.with.filter" />
                      </NoRecordFound>
                    ) : (
                      <NoRecordFound>
                        <IntlMessages id="NoRecordFound" />
                      </NoRecordFound>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={licensesList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage={
            <IntlMessages id="app.module.pagination.numberOfLines" />
          }
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} sur ${count}`
          }
        />
      </Paper>

      {openLicenceDialog && (
        <AddLicence
          open={openLicenceDialog}
          onCloseDialog={handleCloseLicenceDialog}
          schoolYear={school_year}
          module={module}
          establishments={establishments}
          disabled={disabled}
        />
      )}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete ${selectedLicense.name}`}
        content={"Are you sure, you want to  delete this license?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default LicenseManagement;
