import { Fonts } from "@jumbo/constants/ThemeOptions";
import { lighten, makeStyles } from "@material-ui/core/styles";

const TableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: 700,
  },
  table: {
    minWidth: 750,
  },
  headerContent: {
    position: "relative",
    zIndex: 3,
    display: "flex",
    justifyContent: "center",
  },
  titleRoot: {
    color: "#666666",
    marginBottom: 4,
  },
  subTitleRoot: {
    color: "#9C9C9C",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  spaceTitleToolbar: {
    fontSize: 15,
    fontWeight: 300,
    textTransform: "uppercase",
    color: "#5B13B4",
    fontFamily: Fonts.MODLETITLE,
  },
  documentTitleStyle: {
    color: "#666666",
  },
  socialLink: {
    display: "flex",
    alignItems: "center",
    marginLeft: -8,
    marginRight: -8,
  },
  socialLinkCol: {
    paddingLeft: 8,
    paddingRight: 8,
    "& .btn": {
      backgroundColor: "#E8E7F6",
      color: theme.palette.primary.main,
      padding: 6,
    },
  },
  gridContainer: {
    marginBottom: 12,
    padding: 20,
  },

  cellParamNote: {
    color: "#4D4B4B",
    opacity: "10",
    fontSize: "15px",
    fontFamily: "Futura LT,bold",
    fontWeight: "700",
    letterSpacing: "1px",
    backgroundColor: "#E8E7F6",
    textAlign: "center",

  },
  TableCellRow: {
    fontSize: 14,
    fontFamily: Fonts.PRIMARY,
    color: "#020202",
    fontweight: 200,
    letterspacing: "0.25px",
    textAlign: "center",

 },
}));

export default TableStyle;
