/* eslint-disable array-callback-return */
import {
  ADD_ESTABLISHMENT,
  DELETE_ESTABLISHMENT,
  EDIT_ESTABLISHMENT,
  GET_ESTABLISHMENTS,
  SET_ESTABLISHMENT_DETAILS,
  GET_SUBESTABLISHMENT,
  GET_LOGO,
  GET_ACTION,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  establishments: [],
  currentEstablishment: null,
  subEstablishement: [],
  currentEstablishmentAction: "",
};

const Establishments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ESTABLISHMENTS: {
      return {
        ...state,
        establishments: action.payload,
      };
    }

    case GET_SUBESTABLISHMENT: {
      return {
        ...state,
        subEstablishement: action.payload,
      };
    }
    case SET_ESTABLISHMENT_DETAILS: {
      return {
        ...state,
        currentEstablishment: action.payload,
      };
    }
    case GET_ACTION: {
      return {
        ...state,
        currentEstablishmentAction: action.payload,
      };
    }

    case ADD_ESTABLISHMENT: {
      return {
        ...state,
        establishments: [action.payload, ...state.establishments],
      };
    }
    case EDIT_ESTABLISHMENT: {
      let data1 = [];
      state.establishments.map((establishment) => {
        if (
          establishment.id === action.payload.id &&
          establishment.establishmentType === action.payload.establishmentType
        ) {
          data1.push(action.payload);
        } else if (
          establishment.establishmentId === action.payload.id &&
          establishment.establishmentType === "simple"
        ) {
          data1.push({
            ...establishment,
            parentEstablishment: action.payload.name,
          });
        } else {
          data1.push(establishment);
        }
      });

      return {
        ...state,
        establishments: data1,
      };
    }

    case DELETE_ESTABLISHMENT: {
      return {
        ...state,
        establishments: state.establishments.filter(
          (establishment) => establishment.id !== action.payload
        ),
      };
    }
    case GET_LOGO: {
      return {
        ...state,
        subEstablishement: { ...state.subEstablishement, logo: action.payload },
      };
    }
    default:
      return state;
  }
};
export default Establishments;
