import { makeStyles } from "@material-ui/core/styles";
import { Fonts } from "../../../@jumbo/constants/ThemeOptions";

const AddStyle = makeStyles((theme) => ({
  dialogRoot: {
    position: "relative",
  },

  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      textTransform: "uppercase",
      color: "#5B13B4",
    },
  },
  saveButton: {
    color: "#FFFFFF",
    backgroundColor: "#5B13B4",
    fontSize: 16,
    font: "normal normal normal 16px/21px Futura LT",
    "&:hover": {
      backgroundColor: "#6F13DA",
    },
  },
  cancelButton: {
    color: "#B8B1F3",
    borderColor: "#B8B1F3",
    "&:hover": {
      borderColor: "#6F13DA",
      color: "#6F13DA",
    },
  },

  inputPhone: {
    height: 5,
  },
  titleAddInput: {
    color: "#5B13B4",
    fontFamily: Fonts.MODLETITLE,
    marginBottom: 12,
    fontSize: 12,
    fontWeight: 500,
  },

  addIcon: {
    marginLeft: "-15%",
  },
  addPhoto: {
    textTransform: "none",
    marginRight: "3%",
    letterSpacing: "0px",
    textAlign: "left",
    font: "normal normal normal 16px/22px Futura LT",
  },
  button: {
    marginLeft: "31px",
    marginTop: "-5%",
    color: "#6C5CE7",
  },
  addPhotoNews: {
    color: "#B8B1F3",
  },
  textPhotoNews: {
    font: Fonts.MODLETITLE,
    letterSpacing: "0px",
    textTransform: "capitalize",
    color: "#6C5CE7",
    fontSize: 14,
    padding: "20px 0px",
    fontWeight: "200",
  },
  buttonImport: {
    font: Fonts.MODLETITLE,
    letterSpacing: "0px",
    textTransform: "capitalize",
    color: "#6C5CE7",
    fontSize: 14,
    padding: "10px 15px 10px 15px",
    fontWeight: "200",
  },
  hasNoPopupIcon: {
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },
  },
  hasPopupIcon: {
    "& .MuiIconButton-label": {
      color: "#5b13b4",
    },
  },
  tr: {
    "&:hover,&:focus": {
      backgroundColor: "#E8E7F6",
    },
  },
}));
export default AddStyle;
