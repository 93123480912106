import { GET_FILES, GET_STORAGE, GET_TRACKING_STORAGE } from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  files: [],
  currentfile: null,
  storageList:[]
};

const EduDrive = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FILES: {
      return {
        ...state,
        files: action.payload,
      };
    }
    case GET_STORAGE: {
      return {
        ...state,
        storage: action.payload,
      };
    }
    case GET_TRACKING_STORAGE:{
      return {
        ...state,
        storageList: action.payload,
      };
    }
    default:
      return state;
  }
};

export default EduDrive;
