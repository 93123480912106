import {
  ADD_SUBJECTMODULE,
  DELETE_SUBJECTMODULE,
  EDIT_SUBJECTMODULE,
  GET_SUBJECTMODULES,
  SET_SUBJECTMODULE_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  subjectModules: [],
  currentSubjectModule: null
};
const SubjectModule = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBJECTMODULES: {
      return {
        ...state,
        subjectModules: action.payload
      };
    }
    case SET_SUBJECTMODULE_DETAILS: {
     
      return {
        ...state,
        currentSubjectModule: action.payload
      };
    }
    case ADD_SUBJECTMODULE: {
      return {
        ...state,
        subjectModules: [ action.payload, ...state.subjectModules ]
      };
    }
    case EDIT_SUBJECTMODULE: {
      return {
        ...state,
        subjectModules: state.subjectModules.map(
          SUBJECTMODULE =>
            SUBJECTMODULE.id === action.payload.id
              ? action.payload
              : SUBJECTMODULE
        )
      };
    }
    case DELETE_SUBJECTMODULE: {
      return {
        ...state,
        subjectModules: state.subjectModules.filter(
          SUBJECTMODULE => SUBJECTMODULE.id !== action.payload
        )
      };
    }

    default:
      return state;
  }
};
export default  SubjectModule