import React, { useContext } from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles,withStyles } from '@material-ui/core';

import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import AppRadioButton from '../../../Common/formElements/AppRadioButton';
import { SIDEBAR_WIDTH } from '../../../../constants/ThemeOptions';
import LayoutContext from '../../../../../@coremat/CmtLayouts/LayoutContext';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(() => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: '10px',
      fontWeight : 600,
    },
    '& .Cmt-card-content': {
      '& .MuiFormControl-root':{
        '& .MuiFormControlLabel-root':{
          '& .MuiTypography-root': {
            fontSize : "0.875rem",
            color : "rgba(0, 0, 0, 0.6)"
          }
        }
      },
    },
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    '&:hover': {
      backgroundColor: "#E8E7F6",
    },
    textTransform : "none"
  },
}))(Button);
const sizes = [
  { label: <IntlMessages id="app.cuztomizer.sideBar.size.small"/>, value: SIDEBAR_WIDTH.SMALL },
  { label: <IntlMessages id="app.cuztomizer.sideBar.size.default"/>, value: SIDEBAR_WIDTH.DEFAULT },
  { label: <IntlMessages id="app.cuztomizer.sideBar.size.large"/>, value: SIDEBAR_WIDTH.WIDE },
];

const SidebarSize = () => {
  const classes = useStyles();
  const { sidebarWidth, setSidebarWidth } = useContext(LayoutContext);

  const onResetSize = () => {
    setSidebarWidth(SIDEBAR_WIDTH.DEFAULT);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader style={{marginTop:"0px" ,paddingBottom: 0}}  title={<IntlMessages id="app.cuztomizer.sideBar.size" />}>
        <ColorButton onClick={onResetSize}><IntlMessages id="app.cuztomizer.resetButton"/></ColorButton>
      </CmtCardHeader>
      <CmtCardContent >
        {sizes.map((item, index) => (
          <AppRadioButton
            style={{}}
            key={index}
            name="size-style"
            label={item.label}
            value={item.value}
            checked={sidebarWidth === item.value}
            onChange={() => setSidebarWidth(item.value)}
          />
        ))}
      </CmtCardContent>
    </CmtCard>
  );
};

export default SidebarSize;
