import {
  ADD_NOTIFICATION,
  EDIT_NOTIFICATION,
  GET_NOTIFICATION,
} from "@jumbo/constants/ActionTypes.js";
import IntlMessages from "@jumbo/utils/IntlMessages";
import React from "react";
import { crudApp } from "services/crudApp.js";
import { fetchError, fetchSuccess } from "./Common";

export const getNotification = (
  status,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    crudApp
      .get(`notifications?${status ? "&status=" + status : ""}`, {
        params: { filterOptions, searchTerm },
      })

      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          var list = [];
          res.data.forEach((row) => {
            var obj = {
              id: row.id,
              status: row.status,
              message: <IntlMessages id="alert.harassment" />,
              user: {
                name:
                  row.content?.user?.firstName +
                  " " +
                  row?.content?.user?.lastName,
                profile_pic: row.content.user?.photo
                  ? row.content.user.photo
                  : "",
              },
              type: "POSTING",

              createdAt: row.date,
            };
            list.push(obj);
          });
          list.sort(function(rowOne, rowTwo) {
            return new Date(rowTwo.createdAt) - new Date(rowOne.createdAt);
          });
          dispatch({ type: GET_NOTIFICATION, payload: list });
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.fetch.error.problem" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.fetch.error.problem" />)
        );
      });
  };
};

export const updateNotification = (id) => {
  return (dispatch) => {
    crudApp
      .put(`notifications/`, {
        notificationListId: [id],
      })
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());

          var notification = {};
          switch (data.data[0].content.action) {
            case "add.harassment":
              notification = {
                id: data.data[0].id,
                status: data.data[0].status,
                message: <IntlMessages id="alert.harassment" />,
                user: {
                  name:
                    data.data[0].content.user?.firstName +
                    " " +
                    data.data[0].content.user.lastName,
                  profile_pic: data.data[0].content.user?.photo
                    ? data.data[0].content.user.photo
                    : "",
                },
                type: "POSTING",

                createdAt: data.data[0].date,
              };
              break;

            default:
              return {};
          }
          dispatch({ type: EDIT_NOTIFICATION, payload: notification });
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.fetch.error.problem" />)
        );
      });
  };
};
export const addNotification = (payload) => (dispatch) => {
  dispatch({
    type: ADD_NOTIFICATION,
    payload,
  });
};
