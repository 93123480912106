import {
  ADD_SECTION,
  DELETE_SECTION,
  EDIT_SECTION,
  GET_SECTIONS,
  SET_SECTION_DETAILS
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  sections: [],
  currentSection: null
};

const Section = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SECTIONS: {
      return {
        ...state,
        sections: action.payload
      };
    }
    case SET_SECTION_DETAILS: {
      return {
        ...state,
        currentSection: action.payload
      };
    }
    case ADD_SECTION: {
      return {
        ...state,
        sections: [action.payload, ...state.sections]
      };
    }
    case EDIT_SECTION: {
      return {
        ...state,
        sections: state.sections.map(
          section =>
            section.id === action.payload.id
              ? action.payload
              : section
        )
      };
    }
    case DELETE_SECTION: {
      return {
        ...state,
        sections: state.sections.filter(
          section => section.id !== action.payload
        )
      };
    }

    default:
      return state;
  }
};
export default Section