import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";
import "moment/locale/fr";
import moment from "moment";
import {
  ADD_SESSION,
  EDIT_SESSION,
  GET_SESSIONS,
  SET_SESSION_DETAILS,
} from "@jumbo/constants/ActionTypes";
import IntlMessages from "@jumbo/utils/IntlMessages";
export const getSchoolSession = (
  subEstablishmentId,
  schoolYearId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(
        `school-sessions?schoolYearId=${schoolYearId}&subEstablishmentId=${subEstablishmentId}`,
        { params: { filterOptions, searchTerm } }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchSuccess());
          res.data.sort(function(rowOne, rowTwo) {
            return new Date(rowOne.startDate) - new Date(rowTwo.endDate);
          });
          dispatch({ type: GET_SESSIONS, payload: res.data});
          if (callbackFun) callbackFun(res.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};

export const setCurrentSchoolSession = (session) => {
  return (dispatch) => {
    dispatch({ type: SET_SESSION_DETAILS, payload: session });
  };
};

export const addNewSchoolSession = (session, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("school-sessions", session)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.session.add.fetchSuccess" />
            )
          );
          dispatch({ type: ADD_SESSION, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else if (data?.response?.status === 400) {
          dispatch(
            fetchError(
              `il existe une session scolaire entre le  ${moment(
                session.startDate
              ).format("DD MMM YYYY") +
                " et le" +
                moment(session.endDate).format("DD MMM YYYY")}  `
            )
          );
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {});
  };
};

export const updateSchoolSession = (session, sessionId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`school-sessions/${sessionId}`, session)
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />)
          );
          dispatch({ type: EDIT_SESSION, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else if (data?.response?.status === 400) {
          dispatch(
            fetchError(
              `il existe une session scolaire entre le  ${moment(
                session.startDate
              ).format("DD MMM YYYY") +
                " et le" +
                moment(session.endDate).format("DD MMM YYYY")}  `
            )
          );
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })

      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};
