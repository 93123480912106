import {
  CONNECT_TO_CANAL,
  DISCONNECT_FROM_CANAL,
  EMIT_MESSAGE,
  LISTEN_FOR_MESSAGE,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  socket: null,
};

export default function SocketIOReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case CONNECT_TO_CANAL: {
      return {
        ...state,
        socket: action.payload,
      };
    }

    case DISCONNECT_FROM_CANAL: {
      return {
        ...state,
        socket: action.payload,
      };
    }

    case EMIT_MESSAGE: {
      return {
        ...state,
        socket: action.payload,
      };
    }

    case LISTEN_FOR_MESSAGE: {
      return {
        ...state,
        socket: action.payload,
      };
    }

    default:
      return state;
  }
}
