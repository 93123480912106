import { InputBase } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const SelectWitoutBorders = withStyles((theme) => ({
  input: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: "#5B13B4",
    letterSpacing: " 0.45px",
    fontSize: "16px",
    padding: "10px 26px 10px 12px",
    width: "140px",
    paddingLeft: "10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "none",
      boxShadow: "none",
      textAlign: "left",

      backgroundColor: theme.palette.background.paper,
    },
  },
}))(InputBase);
export default SelectWitoutBorders;
