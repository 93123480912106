import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Edit, MoreHoriz, Visibility } from "@material-ui/icons";
import CmtDropdownMenu from "../../../@coremat/CmtDropdownMenu";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import IntlMessages from "../../..//@jumbo/utils/IntlMessages";
import useStyles1 from "../../CommonComponents/Style/TypoModelStyle";
// import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
    fontWeight: 200,
  },
  dataList: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: 200,
    color: theme.palette.common.dark,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.purple,
    },
  },
}))(TableRow);

const LicenseListRow = ({ row, isSelected, onLicenseEdit, onLicenseView }) => {
  const classes = useStyles();
  const classes1 = useStyles1();
  const actions = [
    {
      action: "view",
      label: <IntlMessages id={"action.view"} />,
      icon: <Visibility className={classes1.iconActionRoot} />,
    },
    {
      action: "edit",
      label: <IntlMessages id={"action.edit"} />,
      icon: <Edit className={classes1.iconActionRoot} />,
    },
    // {
    //   action: "suspendre",
    //   label: <IntlMessages id={"action.suspend"} />,
    //   icon: <BlockIcon className={classes1.iconActionRoot} />
    // }
  ];
  const onLicenseMenuClick = (menu) => {
    if (menu.action === "view") {
      onLicenseView(row);
    } else if (menu.action === "edit") {
      onLicenseEdit(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);

  return (
    <StyledTableRow
      hover
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <StyledTableCell />
      <StyledTableCell
        className={classes.dataList}
        component="th"
        id={labelId}
        scope="row"
      >
        {row.subEstablishment?.name}
      </StyledTableCell>
      <StyledTableCell className={classes.dataList}>
        {row.parentEstablishment?.name}
      </StyledTableCell>
      <StyledTableCell className={classes.dataList}>
        {row.licenseStatus === "Demo" ? "Essai gratuit" : row.licenseStatus}
      </StyledTableCell>

      <StyledTableCell className={classes.dataList}>
        {row.studentsNumber}
      </StyledTableCell>
      <StyledTableCell className={classes.dataList}>
        {row?.schoolyear?.year}
      </StyledTableCell>

      <StyledTableCell
        className={classes1.iconActionRoot}
        align="center"
        onClick={(event) => event.stopPropagation()}
      >
        <CmtDropdownMenu
          items={actions}
          onItemClick={onLicenseMenuClick}
          triggerComponent={<MoreHoriz />}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default React.memo(LicenseListRow);
