import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";

import {
  ADD_COUNT,
  ADD_NEWS,
  COUNT_DATA,
  EDIT_NEWS,
  GET_NEWS,
  SET_NEWS_DETAILS,
} from "../../@jumbo/constants/ActionTypes";
import IntlMessages from "@jumbo/utils/IntlMessages";

export const addNews = (actuality, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("actuality", actuality)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(<IntlMessages id="app.module.news.add.fetchSuccess" />)
          );
          dispatch({ type: ADD_NEWS, payload: data.data });
          dispatch({ type: ADD_COUNT, payload: 1 });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};

export const getNews = (
  subEstablishmentId,
  take,
  page,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .get(
        `actuality?subEstablishmentId=${subEstablishmentId}${
          take > 0 ? "&take=" + take : ""
        }${page > 0 ? "&page=" + page : ""}`,
        {
          params: { filterOptions, searchTerm },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: COUNT_DATA,
            payload: res.data.meta,
          });
          dispatch({
            type: GET_NEWS,
            payload: res.data.data
              .sort(function(rowOne, rowTwo) {
                return (
                  new Date(rowOne.publicationDate) -
                  new Date(rowTwo.publicationDate)
                );
              })
              .reverse(),
          });
          if (callbackFun) callbackFun(res.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};

export const setCurrentNews = (news) => {
  return (dispatch) => {
    dispatch({ type: SET_NEWS_DETAILS, payload: news });
  };
};

export const updateNews = (actuality, actualityId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp.put(`actuality/${actualityId}`, actuality).then((data) => {
      if (data.status === 200) {
        dispatch(
          fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />)
        );
        dispatch({ type: EDIT_NEWS, payload: data.data });
        if (callbackFun) callbackFun(data.data);
      } else {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      }
    });
  };
};

export const updateNewsStatus = (data, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put("actuality/update-status", data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.newsStatus.update.fetchSuccess" />
            )
          );
          dispatch({ type: EDIT_NEWS, payload: response.data });
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(
            <IntlMessages id="app.module.get.serverProblem.fetchError" />
          )
        );
      });
  };
};
