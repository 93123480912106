import {
  DELETE_DISH,
  EDIT_DISH,
  ADD_DISHE,
  GET_DISHES,
  SET_DISH_DETAILS,
  CLEAR_DISH,
  GET_MENUS,
  ADD_MENU,
  EDIT_MENU,
  SET_MENU_DETAILS,
  DELETE_MENU,
  GET_PHOTOS_DISHE,
} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  dishes: [],
  dish: [],
  menus: [],
  menu: null,
  photos: [],
};

const Canteen = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DISHES: {
      return {
        ...state,
        dishes: action.payload,
      };
    }

    case SET_DISH_DETAILS: {
      return {
        ...state,
        dish: [action.payload],
      };
    }

    case CLEAR_DISH: {
      return {
        ...state,
        dish: [],
      };
    }

    case DELETE_DISH: {
      const newDishes = state.dishes.filter(
        (dish) => dish.id !== action.payload
      );

      return {
        ...state,
        dishes: newDishes,
      };
    }

    case GET_PHOTOS_DISHE: {
      return {
        ...state,
        photos: action.payload,
      };
    }

    case ADD_DISHE: {
      return {
        ...state,
        dishes: [...state.dishes, action.payload],
      };
    }

    case EDIT_DISH: {
      return {
        ...state,
        dishes: state.dishes.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    }

    case SET_MENU_DETAILS: {
      return {
        ...state,
        menu: action.payload,
      };
    }
    case GET_MENUS: {
      return {
        ...state,
        menus: action.payload,
      };
    }

    case ADD_MENU: {
      return {
        ...state,
        menus: [...state.menus, action.payload],
      };
    }

    case EDIT_MENU: {
      return {
        ...state,
        menus: state.menus.map((menu) =>
          menu.id === action.payload.id ? action.payload : menu
        ),
      };
    }
    case DELETE_MENU: {
      const newMenus = state.menus.filter((dish) => dish.id !== action.payload);

      return {
        ...state,
        menus: newMenus,
      };
    }

    default:
      return state;
  }
};
export default Canteen;
