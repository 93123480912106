import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  Grid,
  Button,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import GridContainer from "@jumbo/components/GridContainer";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import CmtAvatar from "@coremat/CmtAvatar";
import AppSelectBox from "@jumbo/components/Common/formElements/AppSelectBox";
import IntlMessages from "@jumbo/utils/IntlMessages";
import useStyles from "../../CommonComponents/Style/AddStyle";
import MuiPhoneNumber from "material-ui-phone-number";
import close from "../../../assets/images/close.svg";
import AppAutocomplete from "@jumbo/components/Common/formElements/AppAutocomplete";
import { bloodGroup, civilityList } from "Config/listes";
import _ from "lodash";
const userInitialState = {
  firstName: "",
  lastName: "",
  email: "",
  address: "",
  phone: "",
  whatsappPhone: "",
  civility: "",
  communicationChannel: "",
  photo: "",
  establishmentId: "",
  bloodGroup: null,
};
const DetailUser = ({ open, onCloseDialog, establishmentsList }) => {
  const classes = useStyles();

  const currentUser = useSelector(
    ({ usersReducer }) => usersReducer.currentUser
  );

  const [userData, setUserData] = useState(userInitialState);
   const [photo, setPhoto] = useState();

  const [bloodGroupItem, setBloodGroup] = useState("");

  useEffect(() => {
    if (currentUser) {
       setPhoto(currentUser.photo);

      let userDataForEdit = {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        address: "",
        phone: currentUser.phone,
        whatsappPhone: "",
        civility: currentUser.civility,
        communicationChannel: "",
        photo: "",
        establishmentId: currentUser.establishment.id,
        bloodGroup: currentUser.bloodGroup,
      };
      setUserData(userDataForEdit);
      let bloodGroupSelected = bloodGroup.filter(
        (item) => item.slug === currentUser.bloodGroup
      );
      setBloodGroup(_.head(bloodGroupSelected));
    }
  }, [currentUser]);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          onCloseDialog();
        }
      }}
      className={classes.dialogRoot}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        <Grid container justifyContent="space-between" alignItems="center">
          <IntlMessages id="details.user" />

          <img
            onClick={onCloseDialog}
            src={close}
            alt="img"
            className={classes.img}
          />
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        {/* 1er ligne */}
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <Box mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className="pointer">
            <CmtAvatar
              size={70}
              src={photo}
              style={{ color: "white", backgroundColor: "#b8b1f3" }}
            />
          </Box>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <AppSelectBox
                fullWidth
                required
                disabled
                id="establishmentId"
                name="establishmentId"
                data={establishmentsList}
                label={<IntlMessages id="parent.establisment" />}
                value={userData.establishmentId}
                valueKey="id"
                variant="outlined"
                labelKey="name"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AppSelectBox
                fullWidth
                required
                disabled
                id="civility"
                name="civility"
                data={civilityList}
                label={<IntlMessages id="user.civility" />}
                valueKey="slug"
                variant="outlined"
                labelKey="title"
                value={userData.civility}
              />
            </Grid>
          </GridContainer>
        </Box>

        {/* 2eme ligne */}
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              required
              disabled
              id="lastName"
              name="lastName"
              variant="outlined"
              label={<IntlMessages id="appModule.LastName" />}
              value={userData.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              required
              disabled
              id="firstName"
              name="firstName"
              variant="outlined"
              label={<IntlMessages id="appModule.FirstName" />}
              value={userData.firstName}
            />
          </Grid>
        </GridContainer>

        {/* 3eme ligne */}
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              fullWidth
              required
              disabled
              id="email"
              name="email"
              variant="outlined"
              label={<IntlMessages id="appModule.information.email" />}
              value={userData.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPhoneNumber
              size="small"
              required
              disabled
              variant="outlined"
              id="phone"
              name="phone"
              value={userData.phone || ""}
              fullWidth={true}
              placeholder="(+XXX) XXX XXX XXX"
              label={<IntlMessages id="appModule.information.phoneNumber" />}
              defaultCountry="fr"
            />
          </Grid>
        </GridContainer>

        {/* 4ème ligne */}
        <Box mb={{ xs: 6, md: 5 }}>
          <AppAutocomplete
            id="bloodGroup"
            name="bloodGroup"
            fullWidth
            disabled
            label={<IntlMessages id="user.bloodGroup" />}
            variant="outlined"
            options={bloodGroup}
            value={bloodGroupItem}
            getOptionLabel={(option) => !_.isEmpty(option) && option?.title}
          />
        </Box>
        {/* Actions */}
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={onCloseDialog}
          >
            {" "}
            {<IntlMessages id="appModule.cancel" />}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DetailUser;

DetailUser.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
