import {
  ADD_PROFESSOR,

  DELETE_PROFESSOR,
  EDIT_PROFESSOR,
  GET_PROFESSORS,
  SET_PROFESSOR_DETAILS,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  professors: [],
  currentProfessor: null,
};

const Professor = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFESSORS: {
      return {
        ...state,
        professors: action.payload,
      };
    }
    case SET_PROFESSOR_DETAILS: {
      return {
        ...state,
        currentProfessor: action.payload,
      };
    }
    case ADD_PROFESSOR: {
      return {
        ...state,
        professors: [action.payload, ...state.professors],
      };
    }
    case EDIT_PROFESSOR: {
      return {
        ...state,
        professors: state.professors.map(professor => (professor.id === action.payload.id ? action.payload : professor)),
      };
    }
    case DELETE_PROFESSOR: {
      return {
        ...state,
        professors: state.professors.filter(professor => professor.id !== action.payload),
      };
    }
   
    default:
      return state;
  }
};
export default  Professor