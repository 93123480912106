import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Edit, MoreHoriz, Visibility } from "@material-ui/icons";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import { withStyles } from "@material-ui/core/styles";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import useStyles1 from "../../../CommonComponents/Style/TypoModelStyle";
import { Box } from "@material-ui/core";
import CmtAvatar from "../../../../@coremat/CmtAvatar";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.purple,
    },
  },
}))(TableRow);

const UserListRow = ({ row, isSelected, onUserEdit, onUserView }) => {
  const classes1 = useStyles1();
  const actions = [
    {
      action: "view",
      label: <IntlMessages id={"action.view"} />,
      icon: <Visibility className={classes1.iconActionRoot} />,
    },
    {
      action: "edit",
      label: <IntlMessages id={"action.edit"} />,
      icon: <Edit className={classes1.iconActionRoot} />,
    },
  ];
  const onUserMenuClick = (menu) => {
    if (menu.action === "view") {
      onUserView(row);
    } else if (menu.action === "edit") {
      onUserEdit(row);
    }
  };
  const labelId = `enhanced-table-checkbox-${row.id}`;

  const isItemSelected = isSelected(row.id);
  return (
    <StyledTableRow
      hover
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <StyledTableCell component="th" id={labelId} scope="row">
        <Box>
          <CmtAvatar size={40} src={row.photo} alt={row.firstName} />
        </Box>
      </StyledTableCell>
      <StyledTableCell className={classes1.dataList}>
        {row.lastName?.toUpperCase()}
      </StyledTableCell>
      <StyledTableCell className={classes1.dataList}>
        {row.firstName.charAt(0)?.toUpperCase() + row.firstName.substring(1)}
      </StyledTableCell>
      <StyledTableCell className={classes1.dataList}>
        {row.userName}
      </StyledTableCell>
      <StyledTableCell className={classes1.dataList}>
        {row.establishment.name}
      </StyledTableCell>
      <StyledTableCell className={classes1.dataList}>
        {row.email}
      </StyledTableCell>
      <StyledTableCell className={classes1.dataList}>
        {row.phone}
      </StyledTableCell>

      <StyledTableCell
        className={classes1.iconActionRoot}
        align="center"
        onClick={(event) => event.stopPropagation()}
      >
        <CmtDropdownMenu
          items={actions}
          onItemClick={onUserMenuClick}
          triggerComponent={<MoreHoriz />}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default React.memo(UserListRow);
