import React from 'react';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {crudApp} from '../../services/crudApp';
import {
  ADD_CLASSROOM,
  EDIT_CLASSROOM,
  GET_CLASSROOMS,
  SET_CLASSROOM_DETAILS,
} from '@jumbo/constants/ActionTypes';
import {isOrderByAscClass} from '@jumbo/utils/commonHelper';
import IntlMessages from '@jumbo/utils/IntlMessages';

export const getClassrooms = (
  subEstablishmentId,
  schoolYearId,
  callbackFun
) => {
  return (dispatch) => {
    crudApp
      .get(`classrooms?schoolYearId=${schoolYearId}&subEstablishmentId=${subEstablishmentId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({
            type: GET_CLASSROOMS,
            payload: isOrderByAscClass(
              data.data.map((ele) => ({
                ...ele,
                className: ele.className + ' (' + ele.level.levelName + ')',
                originName: ele.className,
              }))
            ),
          });
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
        }
        if (callbackFun) callbackFun(data.data);
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id="app.module.get.serverProblem.fetchError" />));
      });
  };
};

export const setCurrentClassroom = (classroom) => {
  return (dispatch) => {
    dispatch({type: SET_CLASSROOM_DETAILS, payload: classroom});
  };
};

export const addNewClassroom = (classroom, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post('classrooms', classroom)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess(<IntlMessages id="app.module.classroom.add.fetchSuccess" />));
          dispatch({
            type: ADD_CLASSROOM,
            payload: {
              ...data.data,
              className: data.data.className + ' (' + data.data.level.levelName + ')',
              originName: data.data.className,
            },
          });
          if (callbackFun) callbackFun(data.data);
        } else if (data?.response?.status === 409) {
          dispatch(fetchError(<IntlMessages id="existing.class" />));
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.add.fetchError" />));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id="app.module.add.fetchError" />));
      });
  };
};

export const addNewClassSettings = (
  classSettings,
  classroomId,
  subEstablishmentId,
  schoolYearId,
  callbackFun
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`classrooms/${classroomId}/params`, classSettings)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess(<IntlMessages id="app.module.classroomSetting.fetchSuccess" />));
          dispatch(getClassrooms(subEstablishmentId, schoolYearId));

          if (callbackFun) callbackFun(data.data);
        } else {
          // dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
          data?.response
            ? dispatch(fetchError(<IntlMessages id={data?.response.data.id} />))
            : dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id="app.module.get.problem.fetchError" />));
      });
  };
};

export const updateClassroom = (classroom, classroomId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`classrooms/${classroomId}`, classroom)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />));
          dispatch({
            type: EDIT_CLASSROOM,
            payload: {
              ...data.data,
              className: data.data.className + ' (' + data.data.level.levelName + ')',
              originName: data.data.className,
            },
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id="app.module.update.fetchError" />));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id="app.module.update.fetchError" />));
      });
  };
};
