import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  GET_MODULES,
  GET_SUB_MODULES,
  PASSWORD_CHANGED,
  SET_USER_ETQ,
  SELECT_SCHOOL_YEAR,
  LOGIN_ERROR_BLOCKED,
  IS_FIRST_CONNECTION,
} from "../../@jumbo/constants/ActionTypes";

import _ from "lodash";
import { crudApp } from "services/crudApp";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import IntlMessages from "@jumbo/utils/IntlMessages";

export const setAuthUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const setSelectedSchoolYear = (selectedYear) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_SCHOOL_YEAR,
      payload: selectedYear,
    });
  };
};

export const getModules = (modules) => {
  return (dispatch) => {
    let subModules = _.map(modules, "subModules").flat();
    dispatch({
      type: GET_MODULES,
      payload: modules,
    });
    dispatch({
      type: GET_SUB_MODULES,
      payload: subModules,
    });
  };
};

export const updateLoadUser = (loading) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = (status) => {
  return (dispatch) => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};

export const setPassWordChanged = (status) => {
  return (dispatch) => {
    dispatch({
      type: PASSWORD_CHANGED,
      payload: status,
    });
  };
};

export const setErrorMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_ERROR_BLOCKED,
      payload: message,
    });
  };
};

export const setUserEtq = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_ETQ,
      payload: user,
    });
  };
};

export const firstConnection = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .patch(`users/first-connexion`)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          dispatch(fetchSuccess());
          dispatch({
            type: IS_FIRST_CONNECTION,
            payload: false,
          });
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};
