import React from "react";
import { Grid, Chip } from "@material-ui/core";
import useStyles from "../../CommonComponents/Style/TypoModelStyle";
import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FilePicker from "../../CommonComponents/Style/FilePicker";
import MomentUtils from "@date-io/moment";
import InformationsJuridiques from "../../../assets/images/InformationsJuridiques.svg";
import * as moment from "moment";
import { useSelector } from "react-redux";

export default function LegalInformation({
  disabled,
  onDeleteAttachments,
  onAddAttachments,
  establishmentData,
  establishmentValidation,
  setEstablishmentData,
  setEstablishmentValidation,
  taxPatentName,
  tradeRegisterName,
  signedAgreementName,
  legalInformation,
}) {
  const classes = useStyles();
  const lang = useSelector((state) => state.LangReducer);

  return (
    <>
      <h5 className={classes.sectionTitle}>
        <img
          src={InformationsJuridiques}
          className={classes.iconSectionTitle}
          alt="img"
        />
        <IntlMessages id="appModule.information.legal" />
      </h5>
      <GridContainer>
        <Grid item xs={12} md={12}>
          <AppTextInput
            className={classes.inputBackground}
            fullWidth
            variant="outlined"
            required
            disabled={disabled}
            label={legalInformation}
            id="taxMember"
            value={establishmentData.taxMember}
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                taxMember:
                  e.target.value.charAt(0).toUpperCase() +
                  e.target.value.substring(1),
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                taxMember: undefined,
              });
            }}
            helperText={establishmentValidation.taxMember}
          />
        </Grid>

        <Grid item xs={12} sm={12} style={{ marginBottom: 12, marginLeft: 2 }}>
          {taxPatentName ? (
            <Chip
              label={taxPatentName}
              key="1"
              id="taxPatent"
              onDelete={
                !disabled ? () => onDeleteAttachments("taxPatentName") : null
              }
              variant="outlined"
              required
              disabled={disabled}
            />
          ) : (
            <FilePicker
              onAddAttachments={(e) => onAddAttachments(e, "taxPatentName")}
              buttonTitle={
                <IntlMessages id="appModule.information.legal.addTaxlicense" />
              }
              disabled={disabled}
            />
          )}

          {tradeRegisterName ? (
            <Chip
              label={tradeRegisterName}
              key="2"
              id="tradeRegister"
              onDelete={
                !disabled
                  ? () => onDeleteAttachments("tradeRegisterName")
                  : null
              }
              variant="outlined"
              required
              disabled={disabled}
            />
          ) : (
            <FilePicker
              onAddAttachments={(e) => onAddAttachments(e, "tradeRegisterName")}
              buttonTitle={
                <IntlMessages id="appModule.information.legal.trade.register" />
              }
              disabled={disabled}
            />
          )}

          {signedAgreementName ? (
            <Chip
              label={signedAgreementName}
              key="3"
              id="signedAgreement"
              onDelete={
                !disabled
                  ? () => onDeleteAttachments("signedAgreementName")
                  : null
              }
              variant="outlined"
              required
              disabled={disabled}
            />
          ) : (
            <FilePicker
              onAddAttachments={(e) =>
                onAddAttachments(e, "signedAgreementName")
              }
              buttonTitle={
                <IntlMessages id="appModule.information.legal.agreement" />
              }
              disabled={disabled}
            />
          )}
        </Grid>

        <GridContainer
          style={{ marginBottom: 1, marginLeft: 1, marginRight: -2 }}
        >
          <Grid item md={6}>
            <MuiPickersUtilsProvider
              DateFnsUtils
              locale={lang}
              utils={MomentUtils}
            >
              <KeyboardDatePicker
                required
                autoOk
                disabled={disabled}
                size="small"
                style={{ width: "100%" }}
                locale={lang}
                invalidDateMessage="Format de date invalide"
                variant="inline"
                inputVariant="outlined"
                label={
                  <IntlMessages id="appModule.information.agreement.signing.date" />
                }
                format="DD-MM-YYYY"
                value={establishmentData.agreementSignatureDate}
                id="agreementSignatureDate"
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => {
                  setEstablishmentData({
                    ...establishmentData,
                    agreementSignatureDate: moment(date).format(),
                  });
                  setEstablishmentValidation({
                    ...establishmentValidation,
                    agreementSignatureDate: undefined,
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={6}>
            <MuiPickersUtilsProvider
              DateFnsUtils
              locale={lang}
              utils={MomentUtils}
            >
              <KeyboardDatePicker
                required
                autoOk
                disabled={disabled}
                size="small"
                style={{ width: "100%" }}
                variant="inline"
                locale={lang}
                invalidDateMessage="Format de date invalide"
                minDateMessage="La date ne doit pas être antérieure à la date minimale"
                inputVariant="outlined"
                placeholder="dd/MM/yyyy"
                label={
                  <IntlMessages id="appModule.information.agreement.end.date" />
                }
                minDate={moment(
                  establishmentData.agreementSignatureDate
                ).format()}
                format="DD-MM-YYYY"
                value={establishmentData.agreementEndDate}
                id="agreementEndDate"
                InputAdornmentProps={{ position: "start" }}
                onChange={(date) => {
                  setEstablishmentData({
                    ...establishmentData,
                    agreementEndDate: moment(date).format(),
                  });
                  setEstablishmentValidation({
                    ...establishmentValidation,
                    agreementEndDate: undefined,
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </GridContainer>
      </GridContainer>{" "}
    </>
  );
}
