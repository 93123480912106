import {
  ADD_EVENT,
  DELETE_EVENT,
  GET_EVENTS,
  SET_EMPTY_LIST,
  SET_EVENT_DETAILS,
  GET_VACATION_EVENTS,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  events: [],
  currentEvent: undefined,
  vacationEvents: [],
};

const Event = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VACATION_EVENTS: {
      return {
        ...state,
        vacationEvents: action.payload,
      };
    }
    case GET_EVENTS: {
      return {
        ...state,
        events: action.payload,
      };
    }

    case SET_EMPTY_LIST: {
      return {
        ...state,
        events: [],
      };
    }

    case DELETE_EVENT: {
      return {
        ...state,
        events: state.events.filter((e) => {
          if (e.id === action.payload.id) {
            return action.payload.updateType === "OnlyOne"
              ? e.index !== action.payload.index
              : e.index < action.payload.index;
          } else {
            return true;
          }
        }),
      };
    }

    case ADD_EVENT: {
      return {
        ...state,
        events: [...action.payload, ...state.events],
      };
    }

    case SET_EVENT_DETAILS: {
      return {
        ...state,
        currentEvent: action.payload,
      };
    }

    default:
      return state;
  }
};
export default Event;
