import React from "react";
import useStyles from "../../CommonComponents/Style/TypoModelStyle";
import GridContainer from "@jumbo/components/GridContainer";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import { Grid } from "@mui/material";
import { videoConferenceType } from "Config/listes";

export default function VisioConference({
  disabled,
  establishmentData,
  establishmentValidation,
  setEstablishmentData,
  setEstablishmentValidation,
  currentEstablishment,
}) {
  const classes = useStyles();

  return (
    <>
      <GridContainer>
        <Grid item xs={12} sm={12} style={{ paddingRight: 0 }}>
          <span style={{ padding: "0 15px" }}>Choisir une conférence:</span>
          {videoConferenceType.map((row) => (
            <>
              <FormControlLabel
                disabled={disabled}
                required
                className={classes.formcontrolLabelRoot}
                control={<Checkbox name={row.id} />}
                label={row.label}
                value={establishmentData.videoConferenceType}
                labelKey={row.label}
                name={"videoConferenceType"}
                checked={establishmentData.videoConferenceType === row.id}
                onChange={(e) => {
                  setEstablishmentData({
                    ...establishmentData,
                    videoConferenceType: e.target.name,
                  });
                  setEstablishmentValidation({
                    ...establishmentValidation,
                    videoConferenceType: undefined,
                  });
                }}
              />
            </>
          ))}
          <Grid item xs={12} sm={12}>
            <span style={{ color: "red" ,padding: "0 25px"}}>
              <small>{establishmentValidation.videoConferenceType}</small>
            </span>
          </Grid>{" "}
        </Grid>
      </GridContainer>
    </>
  );
}
