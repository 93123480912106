import axios from "axios";
import config from "../Config/index";
import { fetchError } from "../redux/actions/Common";
import configureStore from "../redux/store";

export const crudApp = {
  get,
  post,
  deleteEndpoint,
  put,
  patch,
  remove,
  appDelete,
};
 axios.defaults.withCredentials = true;

const store = configureStore();
function get(apiEndpoint, type) {
  return axios
    .get(config.baseUrl + apiEndpoint, {
      headers: {
        Authorization: `Bearer ${
          (window.localStorage.userETQsal14mp52 &&
            window.location.pathname.toString().search("/superadmin/") ===
              -1) ||
          (window.localStorage.userETQsal14mp52 && type === "simple")
            ? localStorage.userETQsal14mp52
            : (window.localStorage.salETQ12gtu47 && type === "simple") ||
              (window.localStorage.salETQ12gtu47 &&
                window.location.pathname.toString().search("/sag/") === -1)
            ? localStorage.salETQ12gtu47
            : localStorage.first478loi52
        }`,
        "Access-Control-Allow-Origin": "*",
        "The-Timezone-IANA": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    .then((response) => {

      return response;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("subEstab45lo54");
        localStorage.removeItem("first478loi52");
        document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
      }
      return err;
    });
}

function post(apiEndpoint, payload, accessHarrassement = false) {
  return axios
    .post(config.baseUrl + apiEndpoint, payload, {
      headers: {
        Authorization: `Bearer ${
          window.localStorage.salETQ12gtu47 &&
          window.location.pathname.toString().search("/sag/") === -1
            ? window.localStorage.salETQ12gtu47
            : window.localStorage.userETQsal14mp52 &&
              window.location.pathname.toString().search("/superadmin/") === -1
            ? localStorage.userETQsal14mp52
            : localStorage.first478loi52
        }`,
        "The-Timezone-IANA": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.statusCode === 409) {
        store.dispatch(fetchError("Niveau existe déjà."));
      } else if (err?.response?.status === 401) {
        if (!accessHarrassement) {
          localStorage.removeItem("subEstab45lo54");
          localStorage.removeItem("first478loi52");
          document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
        }
      } else {
        return err;
      }
    });
}

function deleteEndpoint(apiEndpoint) {
  return axios
    .delete(config.baseUrl + apiEndpoint, {
      headers: {
        Authorization: `Bearer ${
          window.localStorage.salETQ12gtu47 &&
          window.location.pathname.toString().search("/sag/") === -1
            ? window.localStorage.salETQ12gtu47
            : window.localStorage.userETQsal14mp52 &&
              window.location.pathname.toString().search("/superadmin/") === -1
            ? localStorage.userETQsal14mp52
            : localStorage.first478loi52
        }`,
        "The-Timezone-IANA": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("subEstab45lo54");
        localStorage.removeItem("first478loi52");
        document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
      }
    });
}

function appDelete(apiEndpoint, payload) {
  return axios
    .delete(config.baseUrl + apiEndpoint, {
      headers: {
        Authorization: `Bearer ${
          window.localStorage.salETQ12gtu47 &&
          window.location.pathname.toString().search("/sag/") === -1
            ? window.localStorage.salETQ12gtu47
            : window.localStorage.userETQsal14mp52 &&
              window.location.pathname.toString().search("/superadmin/") === -1
            ? localStorage.userETQsal14mp52
            : localStorage.first478loi52
        }`,
        "The-Timezone-IANA": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      data: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // if (err?.response?.status === 401) {
      //   localStorage.removeItem("subEstab45lo54");
      //   localStorage.removeItem("first478loi52");
      //   document.location.href = `${process.env.REACT_APP_PARENT_APP}/signin`
      // }
    });
}

function put(apiEndpoint, payload) {
  return axios
    .put(config.baseUrl + apiEndpoint, payload, {
      headers: {
        Authorization: `Bearer ${
          window.localStorage.salETQ12gtu47 &&
          window.location.pathname.toString().search("/sag/") === -1
            ? window.localStorage.salETQ12gtu47
            : window.localStorage.userETQsal14mp52 &&
              window.location.pathname.toString().search("/superadmin/") === -1
            ? localStorage.userETQsal14mp52
            : localStorage.first478loi52
        }`,
        "The-Timezone-IANA": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("subEstab45lo54");
        localStorage.removeItem("first478loi52");
        document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
      } else {
        return err;
      }
    });
}

function patch(apiEndpoint, payload) {
  return axios
    .patch(config.baseUrl + apiEndpoint, payload, {
      headers: {
        Authorization: `Bearer ${
          window.localStorage.salETQ12gtu47 &&
          window.location.pathname.toString().search("/sag/") === -1
            ? window.localStorage.salETQ12gtu47
            : window.localStorage.userETQsal14mp52 &&
              window.location.pathname.toString().search("/superadmin/") === -1
            ? localStorage.userETQsal14mp52
            : localStorage.first478loi52
        }`,
        "The-Timezone-IANA": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("subEstab45lo54");
        localStorage.removeItem("first478loi52");
        document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
      }
      //   if (err?.response?.data?.error?.statusCode === 401) {
      //     store.dispatch(userLogOut());
      //   } else {
      //     let errorMsg =
      //       err.response === undefined
      //         ? 'Please try again later, an error has occurred '
      //         : err.response.data.error.message;
      //     store.dispatch({
      //       type: SHOW_ERROR_MESSAGE,
      //       payload: errorMsg,
      //     });
      //     setTimeout(() => {
      //       store.dispatch({type: HIDE_ERROR_MESSAGE});
      //     }, 4000);
      //   }
    });
}

function remove(apiEndpoint, payload = undefined) {
  return axios
    .delete(config.baseUrl + apiEndpoint, {
      headers: {
        Authorization: `Bearer ${
          window.localStorage.salETQ12gtu47 &&
          window.location.pathname.toString().search("/sag/") === -1
            ? window.localStorage.salETQ12gtu47
            : window.localStorage.userETQsal14mp52 &&
              window.location.pathname.toString().search("/superadmin/") === -1
            ? localStorage.userETQsal14mp52
            : localStorage.first478loi52
        }`,
        "The-Timezone-IANA": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      data: payload,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem("subEstab45lo54");
        localStorage.removeItem("first478loi52");
        document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
      } else {
        return err.response;
      }
    });
}
