import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Select, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { alpha } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CmtImage from "../../../../@coremat/CmtImage";
import IntlMessages from "../../../utils/IntlMessages";
import ContentLoader from "../../ContentLoader";
import AuthWrapper from "./AuthWrapper";
import useStyles1 from "../../../../routes/CommonComponents/Style/TypoModelStyle";
import { requiredMessage } from "../../../../@jumbo/constants/ErrorMessages";
import clsx from "clsx";
import { AuhMethods } from "../../../../services/auth";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up("md")]: {
      order: 1,
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50,
    },
  },
  tr: {
    "&:hover,&:focus": {
      backgroundColor: "#E8E7F6",
    },
  },
  redirection: {
    "&:hover,&:focus": {
      backgroundColor: "#E8E7F6",
    },
    color: "#5b13b4",
    cursor: "pointer",
  },
}));

const ListSubEstablishment = ({
  variant = "default",
  wrapperVariant = "default",
}) => {
  const { userProfile, authUser } = useSelector(({ auth }) => auth);
  const { userEtq } = useSelector(({ auth }) => auth);

  const [subEstablishmentList, setSubEstablishmentList] = useState([]);
  const [subEstablishmentId, setSubEstablishmentId] = useState();
  const [subEstablishmentIdError, setSubEstablishmentIdError] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const classes1 = useStyles1();
  let decodedNewToken = jwt_decode(authUser);
  useEffect(() => {
    if (userProfile !== "") {
      const data = userProfile?.establishment?.subEstablishments;
      setSubEstablishmentList(data);
    } else
      setSubEstablishmentList(
        decodedNewToken?.establishment?.subEstablishments
      );
  }, [userProfile, decodedNewToken, dispatch]);

  const onSubmit = () => {
    if (!subEstablishmentId) {
      setSubEstablishmentIdError(requiredMessage);
    } else if (window.localStorage.userETQsal14mp52) {
      localStorage.setItem("subEstabETQUser45lo54", subEstablishmentId);
      dispatch(
        AuhMethods["jwtAuth"].getScopeUser(subEstablishmentId, "", userEtq)
      );
    } else {
      localStorage.setItem("subEstab45lo54", subEstablishmentId);

      if (localStorage.first478loi52 === undefined) {
        localStorage.setItem("first478loi52", userProfile?.bnh12p7);
        dispatch(AuhMethods["jwtAuth"].getScopeUser(subEstablishmentId));
      } else {
        dispatch(AuhMethods["jwtAuth"].getScopeUser(subEstablishmentId));
      }
    }
  };

  const redirection = () => {
    if (window.localStorage.salETQ12gtu47)
      window.location.href = "/app/sag/connection-as";
    else if (window.localStorage.userETQsal14mp52)
      window.location.href = "/app/superadmin/connection-as";
  };
  const redirectionSignin = () => {
    localStorage.removeItem("first478loi52");
    document.location.href = `${process.env.REACT_APP_PARENT_APP}/app/signin`;
  };
  return (
    <AuthWrapper variant={wrapperVariant}>
      <div className={classes.authThumb}>
        <CmtImage src={"/images/auth/woman 3.png"} />
      </div>

      <div className={classes.authContent}>
        {window.localStorage.salETQ12gtu47 ||
        window.localStorage.userETQsal14mp52 ? (
          <CmtImage
            className={clsx(classes.redirection)}
            style={{ width: "20px", marginBottom: "20px" }}
            onClick={redirection}
            src={"/images/redirect.jpg"}
          />
        ) : (
          <CmtImage
            className={clsx(classes.redirection)}
            style={{ width: "20px", marginBottom: "20px" }}
            onClick={redirectionSignin}
            src={"/images/redirect.jpg"}
          />
        )}
        <div className={"mb-5"}>
          <CmtImage style={{ width: "150px" }} src={"/images/logo.png"} />
        </div>
        {subEstablishmentList?.length ? (
          <>
            <Typography
              component="div"
              variant="h1"
              className={classes1.titleWelcome}
            >
              {<IntlMessages id="user.welcome" />} {userProfile?.firstName} !
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
                <Typography className={classes1.subtitle}>
                  <IntlMessages id="select.establishment" />
                </Typography>
              </Box>
            </Box>
            <form>
              <div className={"mb-5"}>
                <Select
                  fullWidth
                  name="listSubEstablishment"
                  id="listSubEstablishment"
                  variant="outlined"
                  value={subEstablishmentId || ""}
                  onChange={(e) => {
                    setSubEstablishmentId(e.target.value);
                    setSubEstablishmentIdError("");
                  }}
                  label={<IntlMessages id="examen.classroom" />}
                  error={subEstablishmentIdError}
                  helperText={subEstablishmentIdError}
                  className={classes.select}
                >
                  {subEstablishmentList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      className={clsx(classes.tr)}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={"mb-5"}>
                <Button
                  className={classes1.saveButton}
                  onClick={onSubmit}
                  variant="contained"
                  color="primary"
                >
                  <IntlMessages id="appModule.save" />
                </Button>
              </div>
            </form>
          </>
        ) : (
          <div style={{ fontSize: "25px", color: "red", paddingTop: 40 }}>
            <IntlMessages id="login.noSubEstablishment" />
          </div>
        )}
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ListSubEstablishment;
