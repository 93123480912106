import React from "react";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";
import {
  //ADD_LICENSE,
  DELETE_LICENSE,
  // EDIT_LICENSE,
  GET_LICENSES,
  SET_LICENSE_DETAILS,
} from "../../@jumbo/constants/ActionTypes";
export const getLicenses = (
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    //dispatch(fetchStart());
    crudApp
      .get("licenses", { params: { filterOptions, searchTerm } })
      .then((data) => {
        if (data.status === 200) {
          // dispatch(fetchSuccess());
          dispatch({ type: GET_LICENSES, payload: data.data.reverse() });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
      });
  };
};

export const setCurrentLicense = (license) => {
  return (dispatch) => {
    dispatch({ type: SET_LICENSE_DETAILS, payload: license });
  };
};

export const addNewLicense = (license, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("licenses", license)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess(<IntlMessages id = "app.module.license.add.fetchSuccess"/>));
          dispatch(getLicenses());
          if (callbackFun) callbackFun(data.data);
        } else if (
          data?.response?.status === 409 &&
          data?.response?.data?.message ===
            "Licence already affected to this establishment in this year"
        ) {
          dispatch(
            fetchError(<IntlMessages id="appModule.exist.license.error" />)
          );
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="appModule.exist.license.error" />)
        );
      });
  };
};

export const updateLicense = (license, licenseId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`licenses/${licenseId}`, license)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess(<IntlMessages id = "app.module.license.update.fetchSucces"/>));
          dispatch(getLicenses());
          //  dispatch({type: EDIT_LICENSE, payload: {...license,id:licenseId}});
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="appModule.exist.license.error" />)
        );
      });
  };
};

export const deleteLicense = (licenseId, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .delete("/licenses", { params: { id: licenseId } })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess(<IntlMessages id = "app.module.license.delete.fetchSuccess"/>));
          dispatch({ type: DELETE_LICENSE, payload: licenseId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError(<IntlMessages id = "app.module.get.problem.fetchError"/>));
        }
      })
      .catch((error) => {
        dispatch(fetchError(<IntlMessages id = "app.module.get.serverProblem.fetchError"/>));
      });
  };
};
