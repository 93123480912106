import {
  ADD_PAYSLIPS,
  GET_PAYSLIPS,
  SET_PAYSLIPS,
  EDIT_PAYSLIPS,
  DELETE_PAYSLIPS,
  Details_PAYSLIP,
} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  payslips: [],
  currentPayslips: null,
  detailpayslips: {},
};

const Payslips = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYSLIPS: {
      return {
        ...state,
        payslips: action.payload,
      };
    }
    case ADD_PAYSLIPS: {
      return {
        ...state,
        payslips: [action.payload, ...state.payslips],
      };
    }
    case EDIT_PAYSLIPS: {
      return {
        ...state,
        payslips: state.payslips.map((payslip) =>
          payslip.id === action.payload.id ? action.payload : payslip
        ),
      };
    }
    case Details_PAYSLIP: {
      return {
        ...state,
        detailpayslips: action.payload,
      };
    }
    case SET_PAYSLIPS: {
      return {
        ...state,
        currentPayslips: action.payload,
      };
    }

    case DELETE_PAYSLIPS: {
      return {
        ...state,
        payslips: state.payslips.filter(
          (payslips) => payslips.id !== action.payload
        ),
      };
    }

    default:
      return state;
  }
};
export default Payslips