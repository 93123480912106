import React from "react";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { crudApp } from "../../services/crudApp";
import {
  ADD_GROUP_CLASS,
  EDIT_GROUP_CLASS,
  GET_GROUP_CLASS,
  SET_GROUP_CLASS_DETAILS,
} from "@jumbo/constants/ActionTypes";
import { isOrderByDesc } from "@jumbo/utils/commonHelper";
import IntlMessages from "@jumbo/utils/IntlMessages";
export const getGroups = (
  schoolYearId,
  subEstablishmentId,
  filterOptions = [],
  searchTerm = "",
  callbackFun
) => {
  return (dispatch) => {
    //dispatch(fetchStart());
    crudApp
      .get(
        `groups?schoolYearId=${schoolYearId}&subEstablishmentId=${subEstablishmentId}`,
        { params: { filterOptions, searchTerm } }
      )
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch({
            type: GET_GROUP_CLASS,
            payload: isOrderByDesc(data.data, "id"),
          });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};

export const setCurrentGroup = (group) => {
  return (dispatch) => {
    dispatch({ type: SET_GROUP_CLASS_DETAILS, payload: group });
  };
};

export const addNewGroup = (group, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .post("groups", group)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="app.module.groupClass.add.fetchSuccess" />
            )
          );
          dispatch({ type: ADD_GROUP_CLASS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(
            fetchError(
              <IntlMessages id="app.module.SalComponents.classroomManagement.addGroup.fetchError" />
            )
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};

export const updateGroup = (group, callbackFun) => {
  return (dispatch) => {
    dispatch(fetchStart());
    crudApp
      .put(`groups/${group.id}`, {
        name: group.name,
        classId: group.classId,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(
            fetchSuccess(<IntlMessages id="appModule.edit.msg.success" />)
          );
          dispatch({ type: EDIT_GROUP_CLASS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else if (
          data?.response?.status === 400 &&
          data.response.data.message[0] ===
            "All groupsList's elements must be unique"
        ) {
          dispatch(
            fetchError(
              <IntlMessages id="app.module.SalComponents.classroomManagement.updateGroup.duplication.fetchError" />
            )
          );
        } else {
          dispatch(
            fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
          );
        }
      })
      .catch((error) => {
        dispatch(
          fetchError(<IntlMessages id="app.module.get.problem.fetchError" />)
        );
      });
  };
};
