import {
  ADD_STUDENT,
  EDIT_STUDENT,
  GET_STUDENTS,
  GET_PARENTS,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  students: [],
  parents: [],
};

const Student = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_STUDENT: {
      return {
        ...state,
        students: [action.payload, ...state.students],
      };
    }
    case EDIT_STUDENT: {
      return {
        ...state,
        students: state.students.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    }
    case GET_STUDENTS: {
      return {
        ...state,
        students: action.payload,
      };
    }
    case GET_PARENTS: {
      return {
        ...state,
        parents: action.payload,
      };
    }

    default:
      return state;
  }
};
export default Student;
