import {
  GET_EDUCATIONALSUPPORT,
  ADD_EDUCATIONALSUPPORT,
  SET_EDUCATIONALSUPPORT,
  EDIT_EDUCATIONALSUPPORT,
  DELETE_EDUCATIONALSUPPORT,DETAILS_EDUCATIONALSUPPORT
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  EducationalSupport: [],
  currentEducationalSupport: null,
  detailSupport:{}
};
const EducationalSupport = (state = INIT_STATE, action) => {
 
  switch (action.type) {
    case GET_EDUCATIONALSUPPORT: {
      return {
        ...state,
        EducationalSupport: action.payload,
      };
    }
    case SET_EDUCATIONALSUPPORT: {
      return {
        ...state,
        currentEducationalSupport: action.payload,
      };
    }
    case DETAILS_EDUCATIONALSUPPORT: {
      return {
        ...state,
        detailSupport: action.payload,
      };
    }
    case ADD_EDUCATIONALSUPPORT: {
      return {
        ...state,
        EducationalSupport: [action.payload, ...state.EducationalSupport],
      };
    }
    case EDIT_EDUCATIONALSUPPORT: {
      return {
        ...state,
        EducationalSupport: state.EducationalSupport.map((support) =>
          support.id === action.payload.id ? action.payload : support
        ),
      };
    }

    case DELETE_EDUCATIONALSUPPORT: {
      return {
        ...state,
        EducationalSupport: state.EducationalSupport.filter(
          (Support) => Support.id !== action.payload
        ),
      };
    }

    default:
      return state;
  }
};
export default EducationalSupport