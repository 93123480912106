import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import { List, ListItem, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import AppSwitch from '../../../Common/formElements/AppSwitch';
import AppContext from '../../../contextProvider/AppContextProvider/AppContext';
import PropTypes from 'prop-types';
import LayoutContext from '../../../../../@coremat/CmtLayouts/LayoutContext';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: '10px',
      fontWeight : 600,
    },
    '& .Cmt-card-content': {
     
    },
  },
}));

const OtherSettings = () => {
  const classes = useStyles();
  const { direction, setDirection, showTourGuide, setTourGuideStatus } = useContext(AppContext);
  const { showFooter, setShowFooter, showTourOpt, showFooterOpt } = useContext(LayoutContext);

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader style={{marginTop:"0px" ,paddingBottom: 0}} title={<IntlMessages id="app.cuztomizer.orientation"/>} />

      <CmtCardContent>
        <List disablePadding>
          <ListItem disableGutters divider>
            <AppSwitch
              style={{fontSize : "0.875rem"}} //Used this to style only the label in the common component AppSwith ^^
              label={<IntlMessages id="app.cuztomizer.orientationSwitch" />}
              checked={direction === 'rtl'}
              onChange={event => setDirection(event.target.checked ? 'rtl' : 'ltr')}
            />
          </ListItem>
          {showFooterOpt && (
            <ListItem disableGutters divider>
              <AppSwitch label="Show Footer" checked={showFooter} onChange={event => setShowFooter(event.target.checked)} />
            </ListItem>
          )}
          {showTourOpt && (
            <ListItem disableGutters divider>
              <AppSwitch
                label="Show Tour Guide"
                checked={showTourGuide}
                onChange={event => setTourGuideStatus(event.target.checked)}
              />
            </ListItem>
          )}
        </List>
      </CmtCardContent>
    </CmtCard>
  );
};

OtherSettings.prototype = {
  showTourOpt: PropTypes.boolean,
  showFooterOpt: PropTypes.boolean,
};

OtherSettings.defaultProps = {
  showTourOpt: false,
  showFooterOpt: true,
};

export default OtherSettings;
