import React, { useEffect, useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import PropTypes from "prop-types";
import { Button, Chip, Menu, MenuItem, Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { deleteBulkUsers } from "../../../../redux/actions/Users";
import ConfirmDialog from "../../../../@jumbo/components/Common/ConfirmDialog";
import CmtSearch from "../../../../@coremat/CmtSearch";
import useStyles from "./index.style";
import Checkbox from "@material-ui/core/Checkbox";
import useStyles1 from "../../../CommonComponents/Style/TypoModelStyle";
import ExitToApp from "@material-ui/icons/ExitToApp";
import PrintIcon from "@material-ui/icons/Print";
import FormControl from "@mui/material/FormControl";
import export_Icon from "../../../../assets/images/export.png";
import SettingsIcon from "@mui/icons-material/Settings";
import SelectWitoutBorders from "routes/CommonComponents/SelectWitoutBorders";
let filterOptionsList = [];

const UserTableToolbar = ({
  selected,
  setSelected,
  onAdd,
  filterOptions,
  setFilterOptions,
  searchTerm,
  exportClick,
  hedearToolbar,
  printIcon,
  exitToApp,
  exportIcon,
  settingIcon,
  handleSearch,
  onSearchChipDelete,
  optionsList,
  withOutSelectedFilter,
  withOutSelectedSearch,
  onPrint,
  importSalle,
  claims
}) => {
  const classes = useStyles();
  const classes1 = useStyles1();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (optionsList === undefined) {
      filterOptionsList = [
        { label: "Actif", value: "active" },
        { label: "Suspendu", value: "suspended" },
      ];
    } else {
      filterOptionsList = optionsList;
    }
  }, [optionsList]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteBulkUsers(selected, () => setSelected([])));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const onFilterOptionClick = (option) => {
    setFilterOptions((prevState) => {
      if (prevState.includes(option.value)) {
        return prevState.filter((item) => item !== option.value);
      } else {
        return [...prevState, option.value];
      }
    });
  };

  const onChipDelete = (option) => {
    setFilterOptions(filterOptions.filter((item) => item !== option.value));
  };

  const selectdYearStatus = useSelector((state) => state?.auth?.selectedYear);

  return (
    <React.Fragment>
      <Toolbar className={clsx(classes.root)}>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          <div className={classes.alignHedear}>
            {hedearToolbar.title}
            {selectdYearStatus !== "Expirée" && onAdd && (
              <Button
                className={classes.spaceTitleToolbar}
                onClick={() => onAdd("import")}
              >
                {hedearToolbar.action}
              </Button>
            )}

            {hedearToolbar.selectListTitle && (
              <div className={classes.alignHedear}>
                {hedearToolbar.selectListTitle}
                <FormControl fullWidth>
                  <Select
                    value={hedearToolbar.selectList[0].value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    fullWidth
                    className={classes.select}
                    variant="outlined"
                    input={<SelectWitoutBorders />}
                  >
                    {hedearToolbar.selectList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {selectdYearStatus !== "Expirée" && importSalle}
          </div>
        </Typography>

        <React.Fragment>
          {printIcon === true && (
            <Tooltip title="Imprimer liste">
              <IconButton aria-label="imprimer list" onClick={onPrint}>
                <PrintIcon className={classes1.iconActionRoot} />
              </IconButton>
            </Tooltip>
          )}
          {exportIcon === true && (
            <Tooltip title="Exporter liste">
              <IconButton aria-label="export list" onClick={exportClick}>
                <img
                  src={export_Icon}
                  alt="export"
                  className={classes1.iconActionRoot}
                />
              </IconButton>
            </Tooltip>
          )}
          {settingIcon === true && (
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={handleClick}>
                <SettingsIcon className={classes1.iconActionRoot} />
              </IconButton>
            </Tooltip>
          )}
          {exitToApp === true && (
            <Tooltip>
              <IconButton aria-label="filter list" onClick={handleClick}>
                <ExitToApp className={classes1.iconActionRoot} />
              </IconButton>
            </Tooltip>
          )}

          {!withOutSelectedSearch && !claims && (
            <CmtSearch
              onChange={handleSearch}
              value={searchTerm}
              border={false}
              onlyIcon
            />
          )}
          <div className={classes.chipsRoot}>
            {searchTerm && (
              <Chip label={searchTerm} onDelete={onSearchChipDelete} />
            )}
            {filterOptionsList.map(
              (option, index) =>
                filterOptions.includes(option.value) && (
                  <Chip
                    key={index}
                    label={option.label}
                    onDelete={() => onChipDelete(option)}
                  />
                )
            )}
          </div>
          {!withOutSelectedFilter && !claims && (
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={handleClick}>
                <FilterListIcon className={classes1.iconActionRoot} />
              </IconButton>
            </Tooltip>
          )}
          {!withOutSelectedFilter && (
            <Menu
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {filterOptionsList.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => onFilterOptionClick(option)}
                >
                  <Checkbox
                    checked={filterOptions.includes(option.value)}
                    inputProps={{ "aria-labelledby": option.label }}
                  />
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          )}
        </React.Fragment>
      </Toolbar>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete users`}
        content={"Are you sure, you want to  delete selected users?"}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

UserTableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  onAdd: PropTypes.func,
};

export default React.memo(UserTableToolbar);
