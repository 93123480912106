import React, { useState } from "react";
import useStyles from "../../CommonComponents/Style/TypoModelStyle";
import PersonIcon from "@material-ui/icons/Person";
import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import { Grid } from "@material-ui/core";
import AppTextInput from "@jumbo/components/Common/formElements/AppTextInput";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidphoneNumber } from "@jumbo/utils/commonHelper";
import { phoneInvalid } from "@jumbo/constants/ErrorMessages";

export default function ContactInformation({
  disabled,
  establishmentData,
  establishmentValidation,
  setEstablishmentData,
  setEstablishmentValidation,country
}) {
  const classes = useStyles();
  const [whatsappValidation, setWhatsappValidation] = useState(true);

  return (
    <>
      {" "}
      <h5 className={classes.sectionTitle}>
        <PersonIcon className={classes.iconSectionTitle} />
        <IntlMessages id="sidebar.appModule.contact" />
      </h5>
      <GridContainer style={{ marginBottom: 12 }}>
        <Grid item xs={12} sm={6}>
          <AppTextInput
            fullWidth
            disabled={disabled}
            variant="outlined"
            required
            label={<IntlMessages id="appModule.information.contact.surname" />}
            value={establishmentData.firstNameContact}
            id="firstNameContact"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                firstNameContact:
                  e.target.value
                    .trim()
                    .charAt(0)
                    ?.toUpperCase() + e.target.value.substring(1),
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                firstNameContact: undefined,
              });
            }}
            helperText={establishmentValidation.firstNameContact}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppTextInput
            fullWidth
            variant="outlined"
            required
            disabled={disabled}
            label={<IntlMessages id="appModule.FirstName" />}
            value={establishmentData.lastNameContact}
            id="lastNameContact"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                lastNameContact:
                  e.target.value
                    .trim()
                    .charAt(0)
                    ?.toUpperCase() + e.target.value.substring(1),
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                lastNameContact: undefined,
              });
            }}
            helperText={establishmentValidation.lastNameContact}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppTextInput
            fullWidth
            variant="outlined"
            required
            disabled={disabled}
            label={<IntlMessages id="appModule.information.email" />}
            value={establishmentData.emailContact}
            id="emailContact"
            onChange={(e) => {
              setEstablishmentData({
                ...establishmentData,
                emailContact: e.target.value,
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                emailContact: undefined,
              });
            }}
            helperText={establishmentValidation.emailContact}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MuiPhoneNumber
            size="small"
            variant="outlined"
            id="phoneContact"
            name="phoneContact"
            required
            disabled={disabled}
            value={establishmentData.phoneContact}
            onChange={(number, country) => {
              if (number.substring(1).length < country.dialCode.length + 1) {
                setWhatsappValidation(true);
              } else {
                setWhatsappValidation(isValidphoneNumber(number.toString()));
              }
              setEstablishmentData({
                ...establishmentData,
                phoneContact: number.length > 5 ? number : null,
              });
              setEstablishmentValidation({
                ...establishmentValidation,
                phoneContact: null,
              });
            }}
            onFocus={() => {
              setEstablishmentValidation({
                ...establishmentValidation,
                phoneContact: null,
              });
            }}
            autoFormat={false}
            fullWidth={true}
            placeholder="(+XXX) XXX XXX XXX"
            label={<IntlMessages id="user.phone" />}
            defaultCountry={country}
            helperText={
              <div style={{ color: "red" }}>
                {establishmentValidation.phoneContact}
                {!whatsappValidation ? phoneInvalid : ""}
              </div>
            }
          />
        </Grid>
      </GridContainer>
    </>
  );
}
