import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../contextProvider/AppContextProvider/AppContext";
import globalStyles from "../../../theme/GlobalCss";
import "../../../services/api/index";
import { LAYOUT_TYPES } from "../../constants/ThemeOptions";
import VerticalDefault from "./VerticalLayouts/VerticalDefault";
import ModernSideBar from "./VerticalLayouts/ModernSidebar";
import HorizontalDefault from "./HorizontalLayouts/HorizontalDefault";
import HorizontalDark from "./HorizontalLayouts/HorizontalDark";
import HorizontalMinimal from "./HorizontalLayouts/HorizontalMinimal";
import HorizontalTopMenu from "./HorizontalLayouts/HorizontalTopMenu";

const AppLayout = ({ children }) => {
  const { layout } = useContext(AppContext);

  const location = useLocation();
  globalStyles();

  if (
    ["/signup", "/subEstablishment", "/callback"].includes(location.pathname)
  ) {
    return (
      <div style={{ minHeight: "100vh", width: "100%", display: "flex" }}>
        {children}
      </div>
    );
  }

  switch (layout) {
    case LAYOUT_TYPES.VERTICAL_DEFAULT: {
      return <VerticalDefault children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MODERN_SIDEBAR: {
      return <ModernSideBar children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_DEFAULT: {
      return <HorizontalDefault children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_DARK: {
      return <HorizontalDark children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_MINIMAL: {
      return <HorizontalMinimal children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_TOP_MENU: {
      return <HorizontalTopMenu children={children} />;
    }
    default:
      return <VerticalDefault children={children} />;
  }
};

export default AppLayout;
